import { generateLabels } from '../app/helpers'

console.log("env : ", process.env.REACT_APP_ENV);

const serverPath = {
  // development: "http://localhost:3000",
  development: "https://stagingapi.jointlyapp.com",  
  dev: "https://stagingapi.jointlyapp.com",
  staging: "https://stagingapi.jointlyapp.com",
  production: "https://prodapi.jointlyapp.com",
}
const stripeKey = {
  development: "pk_test_x13E6VUAY96EXtya84MW8sR6",
  dev: "pk_test_x13E6VUAY96EXtya84MW8sR6",
  staging: "pk_test_x13E6VUAY96EXtya84MW8sR6",
  production: "pk_live_TulEO5svXFXdxnxQOuLgQYRK",
}
const webSocket = {
  // development: "http://localhost:4003",
  development: "https://prodapi.jointlyapp.com",
  dev: "https://prodapi.jointlyapp.com",
  staging: "https://prodapi.jointlyapp.com",
  production: "https://prodapi.jointlyapp.com"
}

const ReCAPTCHAKey = {
  development: "6Ldso20bAAAAAK7dvSs0n5jDSnhkEwe4q7ztCRUR",
  dev: "6Ldso20bAAAAAK7dvSs0n5jDSnhkEwe4q7ztCRUR",
  staging: "6Ldd_G0bAAAAAAqEhNrdCp9MadEAd7Uo-mpZ4OmF",
  production: "6Ldd_G0bAAAAAAqEhNrdCp9MadEAd7Uo-mpZ4OmF"
}

const Stripe = window.Stripe;

if (Stripe)
  var stripe = Stripe(stripeKey[process.env.REACT_APP_ENV || "development"]);
const server = serverPath[process.env.REACT_APP_ENV || "development"]
console.log("server : ", server);

let all = {
  env: {
    forgotPassword: "sorry, requested email is not registered in Jointly!",
    s3DataURL: "https://jointly-assets.s3.eu-west-2.amazonaws.com/user-activity/",
    s3DownloadReqURL: "https://jointly-download-requests.s3.eu-west-2.amazonaws.com/"
  },
  routes: {
    login: '/login',
    alexalink: '/alexalink',
    signup: '/sign-up',
    createServiceAdmin: '/create-service-admin',
    magicLogin: '/magic-login',
    resendMagicLogin: '/resend-magic-login',
    forgotPassword: 'forgot-password',
    updateProfile: '/update-profile',
    me: '/me',
    resetPassword: '/reset-password',
    changePassword: '/change-password',
    setting: '/default/settings',
    search: '/searches',
    notification: '/notifications',
    readNotification: '/notification',
    readAllNotification: '/all-notification',
    deleteAllNotification: '/delete-all-notification',
    notificationCount: 'notification-count',
    user: '/user',
    users: '/users',
    whatsNew: '/update-whatsNew',
    langUsers: '/list-lang-users',
    circleUsersId: '/circle-list-user',
    subscription: '/subscription',
    createCircle: '/circle',
    invite: '/circle/invite',
    listCircles: '/circles',
    getCircle: '/circle',
    circleDetails: '/circle-details',
    updateCircle: '/circle',
    promoCode: '/promo-code',
    createNote: '/note',
    listNotes: '/notes',
    deleteNote: '/note',
    getNote: '/note',
    updateNote: '/note',
    createTasks: '/task',
    listTasks: '/tasks',
    deleteTask: '/task',
    getTask: '/task',
    updateTask: '/task',
    listMembers: '/members',
    getMember: '/member',
    createMembers: '/members',
    createInvitationNote: '/invitation-note',
    messages: '/messages',
    getMessage: '/message',
    deleteMessage: '/message',
    comments: '/comments',
    digests: '/digests',
    userDigests: '/userdigests',
    descriptions: '/descriptions',
    events: '/events',
    allEvents: '/all/events',
    event: '/event',
    updateAllEvents: '/event/update-all',
    createEvent: '/event',
    updateEvent: '/event',
    listContacts: '/contacts',
    getContact: '/contact',
    addContact: '/contact',
    editContact: '/contact',
    deleteContact: '/contact',
    getCategories: '/categories',
    addCategory: '/categories',
    editCategory: '/categories',
    deleteCategory: '/category',
    listCategories: '/categories-in-circles',
    listActivities: '/activities',
    getTaskList: '/task-lists',
    circleCustom: '/circle-custom',
    addTaskList: '/task-list',
    invities: '/circle/invite',
    deleteinvitee: '/invitee',
    deleteAllinvitee: '/allInvitee',
    inviteRemainder: '/invite/remainder',
    payment: '/payment',
    userUnavailabilty: "/userunavailabilty",
    getUserUnavailability: '/user-unavailabilty',
    editUserUnavailability: '/userunavailabilty',
    getUnavailabilityById: 'userunavailabilty',
    deleteUserUnavailablity: 'userunavailabilty',
    listTodo: '/todo',
    redeemCodes: '/redeemcodes',
    getRedeemList: '/circle',
    redeemCodeCheck: '/redeemcode',
    allServices: '/device/service/all',
    resendRedeemCode: '/resend-signupcode',
    partnerInvite: '/partner-invite',
    serviceAdmin: '/service-admin',
    listMainpermissions: 'list-mainpermissions',
    userPermission: '/userpermission',
    circleAdminPermission: '/member-role',
    circlePermissions: '/permission',
    siteSettings: '/settings',
    createServiceAndAdmin: '/service/admin',
    joinService: '/join/service',
    promoCodes: '/promo-codes',
    generateMagicLogin: '/generate-magic-login',

    listMedications: '/medications',
    getMedication: '/medication',
    getMedicationByTime: 'medications-time',
    addMedications: '/medication',
    editMedications: '/medication',
    listMedicationLocations: "/list-circle-locations",
    location: "/location",
    listDevices: '/devices',
    deviceService: '/device/service',
    circleService: '/circle/service',
    deleteDevice: '/devices',
    service: '/service',
    exportData: '/export-module',
    exportAll: '/export-modules',
    userById: '/userdata',
    deleteMedia: '/s3/image/delete',
    draftEvent: '/copy-event',
    checkEventDraft: 'check-draft',
    updateEventDraft: '/update-draft',
    clearEventDraft: '/clear-draft',
    checkUser: '/check-user',
    logout: '/logout',
    exportReport:'/export-report',

    listUser:'/users-list',
    searchUsers:'search-users',



  },
  // placesAPI: googlePlacesKey[process.env.REACT_APP_ENV || "development"],
  webSocketAPI: webSocket[process.env.REACT_APP_ENV || "development"],
  Stripe: stripe,
  defaultLimit: 10,
  cloudUrl: 'https://d3i0od2js9wzpp.cloudfront.net/',
  s3Url: 'https://jointly-assets.s3.eu-west-2.amazonaws.com/',
  api: `${server}/v1`,
  media: `${server}/v1/s3/image/upload`,
  mediaFile: `${server}/v1/upload-file`,
  memberCount: 3,
  maxLimit: 100,
  staticData: {
    circleAdminRoleName: "Circle Admin", //role name of circle admins
  },
  dateFormat: "D MMM YYYY",
  dateFormat2: "DD/MM/YY",
  dateDayFormat: "ddd D MMM YYYY",
  timeFormat: "h:mma",
  relationships: ["Brother",
    "Brother in law",
    "Daughter",
    "Daughter in Law",
    "Father",
    "Father in law",
    "Friend",
    "Grand Daughter",
    "Grand Father",
    "Grand Son",
    "Husband",
    "Mother",
    "Mother in Law",
    "Nephew",
    "Niece",
    "Other",
    "Relative Living Together",
    "Self",
    "Sister",
    "Sister in law",
    "Son",
    "Son in law",
    "Spouse",
    "Uncle",
    "Wife"
  ],
  searchTagList: [
    {
      label: generateLabels("OTHERS_EVERYTHING"),
      name: '',
      key: '',
      icon: 'icon-dashboard'
    },
    // {
    //   label: "Search Circles",
    //   name: 'circle',
    //   icon: "icon-circle"
    // },
    {
      label: generateLabels("OTHERS_SEARCH_MESG"),
      name: generateLabels("SIDE_MENU_MESSAGES"),
      key: 'message',
      icon: "icon-mail"
    },
    {
      label: generateLabels("OTHERS_SEARCH_MED"),
      name: generateLabels("MEDICATIONS_TITLE"),
      key: 'medication',
      icon: "icon-medications"
    },
    {
      label: generateLabels("OTHERS_SEARCH_TASK"),
      name: generateLabels("TASK_TITLE"),
      key: 'tasks',
      icon: "icon-task"
    },
    {
      label: generateLabels("OTHERS_SEARCH_EVT"),
      name: generateLabels("CALENDAR_TITLE"),
      key: 'event',
      icon: "icon-calendar-minimal"
    },
    {
      label: generateLabels("OTHERS_SEARCH_NOTES"),
      name: generateLabels("NOTES_TITLE"),
      key: 'note',
      icon: "icon-notes"
    },
    {
      label: generateLabels("OTHERS_SEARCH_DEVS"),
      name: generateLabels("SIDE_MENU_DEVICES"),
      key: 'devices',
      icon: "icon-connect"
    },
    {
      label: generateLabels("OTHERS_SEARCH_CONT"),
      name: generateLabels("SIDE_MENU_CONTACTS"),
      key: 'contacts',
      icon: 'icon-user-fill'
    },
  ],
  reminderTags: [
    {
      value: 15,
      label: generateLabels("CALENDAR_EVENT_REMINDER_ONE")
    },
    {
      value: 60,
      label: generateLabels("CALENDAR_EVENT_REMINDER_TWO")
    },
    {
      value: 6 * 60,
      label: generateLabels("CALENDAR_EVENT_REMINDER_THREE")
    },
    {
      value: 24 * 60,
      label: generateLabels("CALENDAR_EVENT_REMINDER_FOUR")
    },
    {
      value: 24 * 60 * 7,
      label: generateLabels("CALENDAR_EVENT_REMINDER_FIVE")
    },
  ],
  eventReminderTags: [
    {
      value: 0,
      label: generateLabels("TASK_CREATE_REMINDER_ONE")
    },
    {
      value: 24 * 60,
      label: generateLabels("TASK_CREATE_REMINDER_TWO")
    },
    {
      value: 2 * 24 * 60,
      label: generateLabels("TASK_CREATE_REMINDER_THREE")
    },
    {
      value: 3 * 24 * 60,
      label: generateLabels("TASK_CREATE_REMINDER_FOUR")
    },
    {
      value: 24 * 60 * 7,
      label: generateLabels("TASK_CREATE_REMINDER_FIVE")
    },
    {
      value: 24 * 60 * 7 * 2,
      label: generateLabels("TASK_CREATE_REMINDER_SIX")
    }
  ],
  repeatEveryTags: [{
    value: 'mon',
    label: 'MON',
    name: "Monday"
  }, {
    value: 'tue',
    label: 'TUE',
    name: "Tuesday"
  }, {
    value: 'wed',
    label: 'WED',
    name: "Wednessday"
  }, {
    value: 'thu',
    label: 'THU',
    name: "Thursday"
  }, {
    value: 'fri',
    label: 'FRI',
    name: "Friday"
  }, {
    value: 'sat',
    label: 'SAT',
    name: "Saturday"
  }, {
    value: 'sun',
    label: 'SUN',
    name: "Sunday"
  },],

  quillModules: {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link'],
    ],
  },

  userRoles: [
    { id: 1, name: 'Admin' },
    { id: 2, name: 'User' },
    { id: 3, name: 'Trial' },
    { id: 4, name: 'Service Admin' },
    { id: 5, name: 'Circle Admin' },
    { id: 6, name: 'Partner' },
  ],
  captchaCount : 3,
  googleReCAPTCHAKey : ReCAPTCHAKey[process.env.REACT_APP_ENV || "development"]
};

let env = {
  development: {
    frontEndURL: "http://localhost:4000",
    apiUrl:"http://localhost:3000/",
    cookies: {
      name: 'jointly',
      circle: 'jointly-circle',
      expiry: 7,
      domain: ""
    }
  },
  dev: {
    frontEndURL: "http://localhost:4000",
    cookies: {
      name: 'jointly',
      circle: 'jointly-circle',
      expiry: 7,
      domain: ""
    }
  },
  staging: {
    frontEndURL: "https://staging.jointlyapp.com",
    apiUrl:"https://uatapi.jointlyapp.com/",
    cookies: {
      name: 'jointly',
      circle: 'jointly-circle',
      expiry: 7,
      domain: ""
    }
  },
  production: {
    frontEndURL: "http://jointlyapp.com",
    apiUrl: "https://prodapi.jointlyapp.com/",
    cookies: {
      name: 'jointly',
      circle: 'jointly-circle',
      expiry: 7,
      domain: ""
    }
  }
};

export default {
  ...all,
  ...env[process.env.REACT_APP_ENV || "development"],
  stripeKey: stripeKey[process.env.REACT_APP_ENV || "development"]
};
