export const en = {
    LOGIN: "Login",
    SIGNUP: "Sign Up",
    LOGO_TEXT_ONE: "brought to you by",
    HOME_HEADER: "Home",
    CIRCLES_HEADER: "Circles",
    NOTIFICATIONS_HEADER: "Notifications",
    SEARCH_PLACEHOLDER: "Search Medications, Notes, Etc...",
    QUICK_ADD: "Quick add",
    MESSAGE_QUICKADD: "New message",
    NOTE_QUICKADD: "New note",
    TASK_QUICKADD: "New task",
    EVENT_QUICKADD: "New event",
    MEDICATION_QUICKADD: "New medication",
    CONTACT_QUICKADD: "New contact",
    SIGNUP_TITLE: "Sign up to Jointly",
    SIGNUP_SUBTITLE: "Sign up to create your Jointly account. You can then use your username and password to access Jointly on any device.",
    FIRST_NAME_SIGNUP_PLACEHOLDER: "First name",
    SUR_NAME_SIGNUP_PLACEHOLDER: "Surname",
    EMAIL_SIGNUP_PLACEHOLDER: "Your email address",
    PASSWORD_SIGNUP_PLACEHOLDER: "Choose a password",
    TERMS_SIGNUP_TEXT_ONE: "I have read and agreed to the Jointly",
    TERMS_SIGNUP_TEXT_TWO: "Terms of Use",
    AND: "and",
    TERMS_SIGNUP_TEXT_THREE: "Privacy Policy",
    UPDATES_NEWS_SIGNUP_TEXT: "Click here if you would like to receive news and updates",
    SIGNUP_BUTTON: "Sign up",
    LOGIN_LINK_SIGNUP: "Already have an account?",
    FIRST_NAME_BLANK_ERROR_SIGNUP: "Please enter first name",
    LAST_NAME_BLANK_ERROR_SIGNUP: "Please enter last name",
    EMAIL_BLANK_ERROR: "Please enter email",
    PASSWORD_BLANK_ERROR: "Please enter password",
    TERMS_BLANK_ERROR_SIGNUP: "Please accept the Terms of Service",
    EMAIL_INVALID_ERROR: "Please enter a valid email",
    EMAIL_DUPLICATE_ERROR_SIGNUP: "Sorry, email has already been used.",
    PASSWORD_INVALID_LENGTH_ERROR:"Password must be at least 8 characters long",
    PASSWORD_INVALID_UPPER_ERROR:"Password must include at least one uppercase letter",
    PASSWORD_INVALID_LOWER_ERROR:"Password must include at least one lowercase letter",
    PASSWORD_INVALID_NUM_ERROR:"Password must include at least one number",
    PASSWORD_INVALID_ALPHANUM_ERROR:"Password must include at least one non-alphanumeric character (! @ # $ % ^ & * ( ) _ + - = [ ] { } | ')",
    PASSWORD_WEEK:"Weak Password",

    LOGIN_TITLE: "Login to Jointly",
    LOGIN_SUBTITLE: "Please note - if this is the first time you are logging in to the new version of Jointly you will need to reset your password first.",
    EMAIL_LOGIN_PLACEHOLDER: "Email address",
    PASSWORD_LOGIN_PLACEHOLDER: "Password",
    FORGOT_PASSWORD_LINK_LOGIN: "Forgot Password?",
    LOGIN_BUTTON: "Login",
    SIGNUP_LINK_LOGIN_ONE: "Don't have an account yet?",
    SIGNUP_LINK_LOGIN_TWO: "Sign up",

    FORGOT_PASSWORD_TITLE: "Forgot password",
    FORGOT_PASSWORD_SUBTITLE: "Please enter your email address to reset your password.",
    FORGOT_PASSWORD_BUTTON: "FORGOT PASSWORD",
    LOGIN_LINK_FORGOT_PASSWORD: "Return to login screen",
    SUCCESS_FORGOT_PASSWORD: `If you have an account registered with this email address we have sent you an email containing a link to reset your password.\n
    If the email does not arrive within a few minutes please check your spam or junk mail folders and check that you entered the same email that you used to register your Jointly account.`,


    LANDING_TITLE: "Create new Circle",
    LANDING_SUBTITLE_ONE: "Create a new Jointly circle",
    LANDING_SUBTITLE_TWO: "Purchase a circle",
    LANDING_SUBTITLE_THREE: "What happens when you purchase a circle?",
    LANDING_SUBTITLE_FOUR: "Create a demo circle",
    LANDING_SUBTITLE_ONE_TEXT: "Create a circle of care for the person you are looking after ",
    LANDING_SUBTITLE_ONE_TEXT_SUB: "and you'll be able to invite as many people as you want to or use Jointly on your own to organise care.",
    LANDING_SUBTITLE_TWO_TEXT_ONE: "To create your circle you need to make a one off payment of £#FIELD# or input a Free Access Code if you have one.",
    LANDING_SUBTITLE_TWO_TEXT_ONEA: "If someone has invited you to join their Jointly circle then this will appear in your Notifications (top right of this screen), please accept the invitation to join their circle.",
    LANDING_SUBTITLE_TWO_TEXT_TWO: "There are ",
    LANDING_SUBTITLE_TWO_TEXT_TWO_SUB: "no additional charges",
    LANDING_SUBTITLE_TWO_TEXT_TWO_SUB2: "for you or any other member of the circle beyond that.",
    LANDING_SUBTITLE_TWO_TEXT_THREE: "You can purchase more than one circle if you wish (for example if you are caring for more than one person).",
    LANDING_SUBTITLE_THREE_TEXT_ONE: "You can communicate and collaborate with others in the circle",
    LANDING_SUBTITLE_THREE_TEXT_TWO: "You can organize care better by accessing all the features of Jointly including medication and task lists, calendar, etc.",
    LANDING_SUBTITLE_THREE_TEXT_THREE: "Your data is backed up regularly on a secure server",
    LANDING_SUBTITLE_FOUR_TEXT: "Alternatively, you can create a demo circle which will allow you to try Jointly for 14 days, and purchase it later for £#FIELD#. Your information will still be accessible but you will need to purchase a license in order to enter any new information after the demo period expires.",
    LANDING_PAYABLE_BUTTON: "Purchase £#FIELD#",
    LANDING_ACCESS_CODE_BUTTON: "Use Free Access Code",
    LANDING_CREATE_DEMO_CIRCLE_BUTTON: "Create a demo circle",

    DASHBOARD_TITLE: "Welcome to Jointly",
    DASHBOARD_SUBTITLE: "Your circles",
    DASHBOARD_SUB_ONE: "Upcoming tasks and events",
    DASHBOARD_NO_TASKS_MESSAGE: "No upcoming tasks or events.",
    DASHBOARD_SUB_TWO: "Circle activity",
    DASHBOARD_END: "Yay! You have seen it all",
    DASHBOARD_CREATED: "Created on",

    SIDE_MENU_MEMBERS: "Members",
    SIDE_MENU_CIRCLE_SETTINGS: "Circle settings",
    SIDE_MENU_OVERVIEW: "Overview",
    SIDE_MENU_PROFILE: "Profile",
    SIDE_MENU_MESSAGES: "Messages",
    SIDE_MENU_CALENDAR: "Calendar",
    SIDE_MENU_NOTES: "Notes",
    SIDE_MENU_TASKS: "Tasks",
    SIDE_MENU_CONTACTS: "Contacts",
    SIDE_MENU_MEDICATIONS: "Medications",
    SIDE_MENU_DEVICES: "Devices",
    SIDE_MENU_YOUR_CIRCLES: "Your circles",
    SIDE_MENU_CREATE_CIRCLE: "Create new circle",
    SIDE_MENU_DASHBOARD: "Dashboard",
    SIDE_MENU_HOME: "Home",
    SIDE_MENU_MY_ACCOUNT: "My Account",
    SIDE_MENU_NOTIFICATION_SETTINGS: "Notification settings",
    SIDE_MENU_LOGOUT: "Logout",

    FOOTER_ONE: "Your trial ends in #FIELD# days, Upgrade your circle to continue using Jointly.",
    FOOTER_BUTTON_UPGRADE_NOW: "Upgrade now",

    MY_ACCOUNT_TITLE: "My account",
    MY_ACCOUNT_LANG: "Language",
    MY_ACCOUNT_FIRSTNAME: "Your name",
    MY_ACCOUNT_FIRSTNAME_PLACEHOLDER: "First name",
    MY_ACCOUNT_SURNAME: "Your surname",
    MY_ACCOUNT_SURNAME_PLACEHOLDER: "Last name",
    MY_ACCOUNT_PROFILE_PICTURE: "Your profile picture",
    MY_ACCOUNT_EDIT_IMAGE_BUTTON: "Edit image",
    MY_ACCOUNT_CONTACT_TITLE: "Contact details",
    MY_ACCOUNT_CONTACT_SUBTITLE: "This contact information can be viewed by other members of the circle",
    MY_ACCOUNT_EMAIL: "Your email",
    MY_ACCOUNT_ADDRESS: "Your address",
    MY_ACCOUNT_ADDRESS_PLACEHOLDER: "Address",
    MY_ACCOUNT_CITY: "Your city",
    MY_ACCOUNT_CITY_PLACEHOLDER: "City",
    MY_ACCOUNT_POSTCODE: "Your postcode",
    MY_ACCOUNT_TELEPHONE: "Your telephone",
    MY_ACCOUNT_TELEPHONE_PLACEHOLDER: "Telephone",
    MY_ACCOUNT_ALT_PHONE: "Alternate telephone",
    MY_ACCOUNT_ALT_PHONE_PLACEHOLDER: "Alternate phone",
    MY_ACCOUNT_RELATIONSHIP: "Relationship",
    MY_ACCOUNT_NOTE: "Note",
    MY_ACCOUNT_SAVE_BUTTON: "Save",
    MY_ACCOUNT_CANCEL_BUTTON: "Cancel",
    MY_ACCOUNT_FIRST_NAME_BLANK: "Please input your first name!",
    MY_ACCOUNT_LAST_NAME_BLANK: "Please input your last name!",
    MY_ACCOUNT_SUCCESS_MESSAGE: "User profile updated",
    MY_ACCOUNT_SUCCESS_MESSAGE2: "You have successfully changed your email address. Please remember to log in using this new address.",


    MY_ACCOUNT_CHANGE_PW_TITLE: "Change your password",
    MY_ACCOUNT_CHANGE_PW_NEW_PASSWORD: "New password",
    MY_ACCOUNT_CHANGE_PW_CONFIRM_PASSWORD: "Confirm password",
    MY_ACCOUNT_CHANGE_PW_SAVE_BUTTON: "Save",
    MY_ACCOUNT_CHANGE_PW_CANCEL_BUTTON: "Cancel",
    MY_ACCOUNT_CHANGE_PW_NEW_PASSWORD_BLANK: "Please input your new password!",
    MY_ACCOUNT_CHANGE_PW_CONFIRM_PASSWORD_BLANK: "Please confirm your new password!",
    MY_ACCOUNT_CHANGE_PW_DIFF_PWDS: "Your new passwords do not match",
    MY_ACCOUNT_CHANGE_PW_SUCCESS: "Password changed sucessfully",

    MY_ACCOUNT_DLT_ACCOUNT_INFO_ONE: "You must leave and/or delete any circles before you can delete your account.",
    MY_ACCOUNT_DLT_ACCOUNT_INFO_TWO: "Most information within Jointly is stored in your circles, if you no longer wish to use Jointly then please exit this screen, go to Circle settings and leave or delete any circles. You will then be able to delete your account here.",
    MY_ACCOUNT_DLT_BUTTON: "Delete",

    ACC_SETTINGS_TITLE: "Account settings",
    ACC_SETTINGS_MY_CONTACT_INFO_LINK: "My contact information",
    ACC_SETTINGS_CHANGE_PW_LINK: "Change your password",
    ACC_SETTINGS_DELETE_ACCOUNT_LINK: "Delete your account and data",
    JOINTLY_AT_A_GLANCE_LINK: "Jointly at a glance",
    USER_GUIDE_LINK: "User guide",
    ABOUT_CONTACT_LINK: "About/Contact",

    NOTIFICATION_SETTINGS_TITLE: "Settings",
    NOTIFICATION_SETTINGS_CREATE_CIRCLE_BUTTON: "Create new circle",
    NOTIFICATION_SETTINGS_OPT_ONE: "Circle activity email digest",
    NOTIFICATION_SETTINGS_OPT_ONE_TEXT: "Jointly will email you a digest of recent circle activity",
    NOTIFICATION_SETTINGS_OPT_ONE_SUB_ONE: "Every day",
    NOTIFICATION_SETTINGS_OPT_ONE_SUB_TWO: "Every week",
    NOTIFICATION_SETTINGS_OPT_ONE_SUB_THREE: "Get jointly email in my digest",
    NOTIFICATION_SETTINGS_OPT_ONE_SUB_FOUR: "Include device messages",
    NOTIFICATION_SETTINGS_OPT_ONE_SAVE_BUTTON: "SAVE",
    NOTIFICATION_SETTINGS_OPT_ONE_CANCEL_BUTTON: "Cancel",

    NOTIFICATION_SETTINGS_OPT_TWO: "Single notifications",
    NOTIFICATION_SETTINGS_OPT_TWO_TEXT: "Jointly will send you an email and/or app notification",
    NOTIFICATION_SETTINGS_OPT_TWO_SELECT_ALL: "Select all",
    NOTIFICATION_SETTINGS_OPT_TWO_SUB_ONE: "A circle profile is edited",
    NOTIFICATION_SETTINGS_OPT_TWO_SUB_TWO: "A new message is posted",
    NOTIFICATION_SETTINGS_OPT_TWO_SUB_THREE: "A task is assigned to you",
    NOTIFICATION_SETTINGS_OPT_TWO_SUB_FOUR: "A task reminder for you is due",
    NOTIFICATION_SETTINGS_OPT_TWO_SUB_FIVE: "A task assigned to you is edited or deleted",
    NOTIFICATION_SETTINGS_OPT_TWO_SUB_SIX: "A task is set as unassigned",
    NOTIFICATION_SETTINGS_OPT_TWO_SUB_SEVEN: "An unassigned task is edited or deleted",
    NOTIFICATION_SETTINGS_OPT_TWO_SUB_EIGHT: "A note is created",
    NOTIFICATION_SETTINGS_OPT_TWO_SUB_NINE: "A note is edited or deleted",
    NOTIFICATION_SETTINGS_OPT_TWO_SUB_TEN: "A calendar event is created",
    NOTIFICATION_SETTINGS_OPT_TWO_SUB_ELEVEN: "A calendar reminder for you is due",
    NOTIFICATION_SETTINGS_OPT_TWO_SUB_TWELVE: "A calendar event is edited or deleted",
    NOTIFICATION_SETTINGS_OPT_TWO_SUB_THIRTEEN: "A medication is created",
    NOTIFICATION_SETTINGS_OPT_TWO_SUB_FOURTEEN: "A medication is edited or deleted",
    NOTIFICATION_SETTINGS_OPT_TWO_SUB_FIFTEEN: "Someone is invited to a circle",
    NOTIFICATION_SETTINGS_OPT_TWO_SUB_SIXTEEN: "Someone joins a circle",
    NOTIFICATION_SETTINGS_OPT_TWO_SUB_SEVENTEEN: "Someone leaves a circle",
    NOTIFICATION_SETTINGS_OPT_TWO_SUB_EIGHTEEN: "Get Jointly email in in notifications",
    NOTIFICATION_SETTINGS_OPT_TWO_SUB_NINETEEN: "Device message received",
    NOTIFICATION_SETTINGS_OPT_TWO_SAVE_BUTTON: "SAVE",
    NOTIFICATION_SETTINGS_OPT_TWO_CANCEL_BUTTON: "Cancel",
    LOGOUT: "Logout",
    NOTIFICATIONS_MESSAGE: "Notifications",
    NOTIFICATIONS_READ_ALL: "Read all",
    NOTIFICATIONS_ACCEPT_BUTTON: "Accept",
    NOTIFICATIONS_REJECT_BUTTON: "Reject",

    CREATE_CIRCLE_DEMO_TITLE: "Create a demo circle",
    CREATE_CIRCLE_FIRST_NAME: "First name",
    CREATE_CIRCLE_LAST_NAME: "Last name",
    CREATE_CIRCLE_NAME_PLACEHOLDER: "of the person you are looking after",
    CREATE_CIRCLE_IMAGE: "Edit image",
    CREATE_CIRCLE_DOB: "Date of Birth",
    CREATE_CIRCLE_NEEDS: "Condition, illness, disability / caring needs",
    CREATE_CIRCLE_NEEDS_PLACEHOLDER: "e.g. Frail, older age, Heart condition, Alzheimer's",
    CREATE_CIRCLE_ALLERGIES: "Dietary requirements / allergies / intolerances",
    CREATE_CIRCLE_ALLERGIES_PLACEHOLDER: "e.g. Allergic to penicillin and peanuts",
    CREATE_CIRCLE_LIKES: "Likes / dislikes / everyday preferences",
    CREATE_CIRCLE_LIKES_PLACEHOLDER: "e.g. Likes tea, TV shows about food/cooking, dislikes loud noises",
    CREATE_CIRCLE_NOTES: "Further information / notes",
    CREATE_CIRCLE_NOTES_PLACEHOLDER: "Further information / notes",
    CREATE_CIRCLE_ALLERGIES_HELP: "Include any specific dietary requirements or food preferences, allergies or intolerances",
    CREATE_CIRCLE_LIKES_HELP: "List preferences such as drinks, favourite TV shows, music or reading. Include dislikes such as coffee or intense smells and daily routines like mealtimes, bedtimes, etc.",
    CREATE_CIRCLE_NOTES_HELP: "Use this to record other information about the person you are caring for.",
    CREATE_CIRCLE_COMMON_MESSAGE: "All the information you provide in this or any other page of Jointly can only be accessed by yourself and the members of your circle. Only circle administrators can edit this circle profile or invite other people to join the circle.",
    CREATE_CIRCLE_FIRST_NAME_BLANK_MESSAGE: "Please input the first name!",
    CREATE_CIRCLE_LAST_NAME_BLANK_MESSAGE: "Please input the last name!",
    CREATE_CIRCLE_NEEDS_BLANK_MESSAGE: "Please enter information about caring needs",

    PURCHASE_CIRCLE_TITLE: "Purchase a circle",
    PURCHASE_PAYMENT_TITLE: "Make payment",
    PURCHASE_CARD_NUMBER_PLACEHOLDER: "Card number",
    PURCHASE_TEXT_ONE: "Your payment is handled by Stripe using the strictest PCI requirements.",
    PURCHASE_TEXT_TWO: "Neither Stripe nor Carers UK store your debit or credit card details.",
    PURCHASE_TEXT_THREE: "Your data is transmitted via an encrypted connection to a secure server.",
    PURCHASE_PROCEED_BUTTON: "Proceed to pay £#FIELD#",
    PURCHASE_CANCEL_BUTTON: "Cancel",
    PURCHASE_FREE_ACCESSCODE_LINK: "Hang on, do you have a ",
    PURCHASE_FREE_ACCESSCODE_LINK_TWO: "free access code?",
    PURCHASE_FREE_ACCESS_CODE_TEXT: "If yes, you can get a free circle.",

    ACCESS_CODE_TITLE: "Redeem your free access code",
    ACCESS_CODE_EMAIL: "Your email",
    ACCESS_CODE_CODE: "Your Free Access Code",
    ACCESS_CODE_CODE_BLANK_ERROR: "Please input your access code!",
    ACCESS_CODE_SUCCESS: "Circle upgraded using free access code",

    MY_CIRCLE: "My circles",
    CIRCLE_MESSAGE_TITLE: "Send a message",
    CIRCLE_MESSAGE_PLACEHOLDER: "Write down your thoughts, post updates",
    CIRCLE_MESSAGE_IMAGE: "Attach images",
    CIRCLE_MESSAGE_FILE: "Attach File",
    CIRCLE_MESSAGE_POST_BUTTON: "POST",
    CIRCLE_MESSAGE_NO_MESSAGE_WARNING: "No message found! Add your first message.",
    CIRCLE_OR: "OR",
    CIRCLE_MEMBER_NOT_AVAILABLE:"Member(s) not available in this date",

    MEMBERS_TABNAME: "Members",
    PENDING_TABNAME: "Pending invitations",
    PENDING_NO_PENDING_MESSAGE: "No pending invitations",

    INVITE_MEMBERS_ICON_TEXT: "Invite members",
    INVITE_MEMBERS_TITLE: "Invite people to the Jointly circle for #FIELD#",
    INVITE_EMAIL: "Email",
    INVITE_NAME: "Full name",
    SET_ADMIN: "Circle administrator",
    SET_EDIT_MEDICATION: "Can edit medications",
    SET_VIEW_NOTES: "Can view private category notes",
    INVITE_ADD_ANOTHER_BUTTON: "Add another",
    INVITE_MANY_BUTTON: "Add many",
    INVITE_EMAIL_BLANK: "Please input email!",
    INVITE_EMAIL_INVALID: "Please input a valid email!",
    INVITE_TEXT_ONE: "By clicking 'Invite' you are giving this person access to the information stored in this Jointly circle. For more information please see ",
    INVITE_TEXT_TWO: "Invite people to join your circle ",
    INVITE_TEXT_THREE: "in the user guide.",
    INVITE_TEXT_FOUR: "Circle administrators can change settings for any member of the circle, including their own, and invite new members to join the circle.",
    INVITE_BUTTON: "Invite",
    INVITE_CANCEL: "Cancel",
    INVITE_INVITED_BY: "Invited by",
    INVITE_RESEND_OPTION_ONE: "Resend invitation?",
    INVITE_RESEND_BUTTON: "Resend",
    INVITE_CANCEL_BUTTON: "Cancel",
    INVITE_DELETE_INVITATION: "Delete invitation?",
    INVITE_DELETE_BUTTON_CONFIRM: "Delete",
    INVITE_VIEW_MORE: "View more",
    INVITE_MULTIPLE: "Enter multiple email addresses",
    INVITE_NOTE: "Note: Copy and paste a list of emails from your contacts. Please separate multiple addresses with commas",
    DONE: "Done",


    CALENDAR_TITLE: "Events",
    CALENDAR_CREATE_EVENT_BUTTON: "Create new event",
    CALENDAR_EVENT_DETAILS_TITLE: "EVENT DETAILS",
    CALENDAR_EVENT_TITLE: "Event title",
    CALENDAR_EVENT_TITLE_PLACEHOLDER: "e.g. Doctors Appointments",
    CALENDAR_EVENT_TITLE_BLANK_ERROR: "Please input event title",
    CALENDAR_EVENT_LOCATION: "Please enter location",
    CALENDAR_EVENT_LOCATION_PLACEHOLDER: "Where?",
    CALENDAR_EVENT_WHO: "Who?",
    CALENDAR_EVENT_WHO_PLACEHOLDER: "Select member or type email",
    CALENDAR_EVENT_DESCRIPTION: "Description",
    CALENDAR_EVENT_IMAGE_BUTTON: "Upload image",
    CALENDAR_EVENT_DATE_TITLE: "EVENT DATE",
    CALENDAR_EVENT_START: "Starts",
    CALENDAR_EVENT_START_TIME: "Start time",
    CALENDAR_EVENT_ALL_DAY_CHECK_BOX: "All day",
    CALENDAR_EVENT_END: "Ends",
    CALENDAR_EVENT_END_TIME: "End time",
    CALENDAR_EVENT_END_DATE_BLANK_ERROR: "Please select end date",
    CALENDAR_EVENT_RECURRING: "Recurring on",
    CALENDAR_EVENT_RECURRING_ONE: "Does not repeat",
    CALENDAR_EVENT_RECURRING_TWO: "Daily",
    CALENDAR_EVENT_RECURRING_THREE: "Weekly",
    CALENDAR_EVENT_RECURRING_FOUR: "Monthly",
    CALENDAR_EVENT_RECURRING_FIVE: "Custom",
    CALENDAR_EVENT_REMINDER: "Reminders",
    CALENDAR_EVENT_REMINDER_ONE: "15 minutes before the event",
    CALENDAR_EVENT_REMINDER_TWO: "1 hour before the event",
    CALENDAR_EVENT_REMINDER_THREE: "6 hours before the event",
    CALENDAR_EVENT_REMINDER_FOUR: "1 day before the event",
    CALENDAR_EVENT_REMINDER_FIVE: "1 week before the event",
    CALENDAR_EVENT_SAVE_BUTTON: "Save",
    CALENDAR_EVENT_CANCEL_BUTTON: "Cancel",
    EVENT_TODAY: "today",
    EVENT_MONTH: "Month",
    EVENT_WEEK: "Week",
    EVENT_DAY: "Day",

    EVENT_DETAIL_TITLE: "Events",
    EVENT_DETAIL_BACK_BUTTON: "Back",
    EVENT_DETAIL_EDIT_BUTTON: "Edit",
    EVENT_DETAIL_COPY_BUTTON: "Copy",
    EVENT_DETAIL_DELETE_BUTTON: "Delete",
    EVENT_DETAIL_DELETE_ALL_BUTTON: "Delete all",
    EVENT_DETAIL_WHO: "Who?",
    EVENT_DETAILS_COMMENTS: "Comments",
    EVENT_DETAILS_COMMENTS_PLACEHOLDER: "Post your comment here",
    EVENT_DETAILS_COMMENTS_TEXT: "Add Your First Comment",
    EVENT_EDIT_TITLE: "Edit event",
    EVENT_COPY_TITLE: "(drafted)",
    EVENT_COPY_RESET: "Reset",

    EVENT_DELETE_CONFIRM_MESSAGE: "Are you sure you want to delete this event?",
    EVENT_DELETE_CONFIRM_YES: "Yes",
    EVENT_DELETE_CONFIRM_NO: "No",

    UNAVAILABILITY_BUTTON: "Unavailability",
    UNAVAILABILITY_TITLE_BUTTON: "Unavailable dates",
    UNAVAILABILITY_NODATE_MESSAGE: "No unavailable dates found",
    UNAVAILABILITY_ADD_DATE_BUTTON: "Add unavailable dates",
    UNAVAILABILITY_TITLE: "Unavailable dates",
    UNAVAILABILITY_START_DATE: "Start date",
    UNAVAILABILITY_END_DATE: "End date",
    UNAVAILABILITY_SELECT_MEMBERS: "Select members",
    UNAVAILABILITY_SELECT_MEMBERS_PLACEHOLDER: "Search email address",
    UNAVAILABILITY_REASON: "Reason",
    UNAVAILABILITY_START_DATE_BLANK_ERROR: "Please select start date",
    UNAVAILABILITY_END_DATE_BLANK_ERROR: "Please select end date",
    UNAVAILABILITY_MEMBERS_BLANK_ERROR: "Please select members!",
    UNAVAILABILITY_REASON_BLANK_ERROR: "Please input reason!",
    UNAVAILABILITY_EDIT: "Edit",
    UNAVAILABILITY_EDIT_TITLE: "Edit unavailable dates",
    UNAVAILABILITY_DELETE_CONFIRM: "Are you sure？",
    UNAVAILABILITY_DELETE_CONFIRM_YES: "Yes",
    UNAVAILABILITY_DELETE_CONFIRM_NO: "No",

    NOTES_TITLE: "Notes",
    NOTES_NOTFOUND_MESSAGE_ONE: "No notes found",
    NOTES_NOTFOUND_MESSAGE_TWO: "Add your first note",
    NOTE_CREATE_BUTTON: "Create new note",
    NOTE_LIST_FILTER_BY_CATEGORY: "Filter by category",
    NOTE_LIST_CLEAR_FILTER: "Clear filter",

    NOTE_CREATE_TITLE: "Create new note",
    NOTE_CREATE_NAME: "Note title",
    NOTE_CREATE_NAME_PLACEHOLDER: "What is your note about?",
    NOTE_CREATE_NAME_BLANK_ERROR: "Please input the note title!",
    NOTE_CREATE_NOTES: "Notes",
    NOTE_CREATE_NOTE_BLANK_ERROR: "Please input the note description!",
    NOTE_CREATE_CATEGORY: "Category",
    NOTE_CREATE_CATEGORY_NOTFOUND: "No categories found",
    NOTE_CREATE_ADD_CATEGORY_BUTTON: "Add new category",
    NOTE_CREATE_IMAGE_BUTTON: "Upload image",
    NOTE_CREATE_SAVE_BUTTON: "Save",
    NOTE_CREATE_CANCEL_BUTTON: "Cancel",

    NOTE_CATEGORY_MANAGE_BUTTON: "Categories",
    NOTE_CATEGORY_MANAGE_TITLE: 'Categories',
    NOTE_CATEGORY_MANAGE_NO_CATEGORY_MESSAGE: "No categories found! Add your first category",
    NOTE_CATEGORY_MANAGE_CREATE_BUTTON: "CREATE CATEGORY",
    NOTE_CATEGORY_MANAGE_CREATE_CATEGORY_BACK_BUTTON: "Back",
    NOTE_CATEGORY_MANAGE_CATEGORY_ADD_TITLE: "Add new category",
    NOTE_CATEGORY_MANAGE_CATEGORY_NAME: "Category name",
    NOTE_CATEGORY_MANAGE_CATEGORY_NAME_DUPLICATE_ERROR: "There is already category with name 'category name', please assign this item to that category or choose a different name",
    NOTE_CATEGORY_MANAGE_CATEGORY_DESCRIPTION: "Description",
    NOTE_CATEGORY_MANAGE_CATEGORY_PRIVATE_CHECKBOX: "Is this private",
    NOTE_CATEGORY_MANAGE_CATEGORY_NAME_BLANK_ERROR: "Please input category name",
    NOTE_CATEGORY_MANAGE_CATEGORY_SAVE_BUTTON: "Save",
    NOTE_CATEGORY_MANAGE_CATEGORY_CANCEL_BUTTON: "Cancel",
    NOTE_CATEGORY_LIST_NAME: "NAME",
    NOTE_CATEGORY_LIST_DESCRIPTION: "DESCRIPTION",
    NOTE_CATEGORY_LIST_PRIVATE: "PRIVATE",
    NOTE_CATEGORY_LIST_ACTION: "ACTION",
    NOTE_CATEGORY_LIST_EDIT_ICON: "Edit",
    NOTE_CATEGORY_LIST_DELETE_ICON: "Delete",

    TASK_TITLE: "Tasks",
    TASK_ACTIVE_TASKS: "Active tasks",
    TASK_MY_TASKS: "My tasks",
    TASK_UNASSIGNED: "Unassigned",
    TASK_ASSIGNED_TO_OTHERS: "Assigned to others",
    TASK_TASK_LISTS: "Task lists",
    TASK_COMPLETED: "Completed",
    TASK_NO_TASK_MESSAGE_ONE: "No task ",
    TASK_NO_TASK_MESSAGE_TWO: " found!",
    TASK_NO_TASK_MESSAGE_FOUR: "list",
    TASK_NO_TASK_MESSAGE_THREE: "Add your first task",
    TASK_EDIT_TASK_BUTTON: "Edit task",
    TASK_CREATE_TASK_BUTTON: "Create new task",
    TASK_CREATE_TITLE: "Task title",
    TASK_CREATE_TITLE_PLACEHOLDER: "e.g. Pick up prescription",
    TASK_CREATE_RESPONSIBLE_MEMBER: "Who’s responsible?",
    TASK_CREATE_RESPONSIBLE_MEMBER_PLACEHOLDER: "Select member",
    TASK_CREATE_ENDDATE: "When is this due?",
    TASK_CREATE_ENDDATE_PLACEHOLDER: "Select date",
    TASK_CREATE_LIST: "Add this to a list?",
    TASK_CREATE_LIST_PLACEHOLDER: "Please select a list",
    TASK_CREATE_NEW_TASK_LIST: "or create a new task list",
    TASK_CREATE_REMINDER_TEXT: "Should Jointly remind you of this?",
    TASK_CREATE_REMINDER_ONE: "The day this is due",
    TASK_CREATE_REMINDER_TWO: "1 day before the task",
    TASK_CREATE_REMINDER_THREE: "2 days before the task",
    TASK_CREATE_REMINDER_FOUR: "3 days before the task",
    TASK_CREATE_REMINDER_FIVE: "1 week before the task",
    TASK_CREATE_REMINDER_SIX: "2 weeks before the task",
    TASK_CREATE_TASK_NOTES: "Task notes",
    TASK_CREATE_SAVE_BUTTON: "Save",
    TASK_CREATE_CANCEL_BUTTON: "Cancel",
    TASK_CREATE_TITLE_BLANK_ERROR: "Please input the task title!",
    TASK_LIST_COMPLETE: "Complete this task",
    TASK_EDIT: "Edit",
    TASK_DELETE: "Delete",
    TASK_DELETE_CONFIRMATION: "Are you sure you want to delete this task？",
    TASK_DELETE_CONFIRMATION_YES: "Yes",
    TASK_DELETE_CONFIRMATION_CANCEL: "Cancel",
    TASK_SETTINGS_ICON: "Task Settings",
    TASK_SETTINGS_CHECKBOX_TEXT: "Show unassigned tasks on Overview",
    TASK_SETTINGS_CANCEL_BUTTON: "Cancel",
    TASK_SETTINGS_OK: "OK",
    TASK_NEW_LIST_TITLE: "Add new list",
    TASK_NEW_LIST_NAME: "List name",
    TASK_NEW_LIST_NAME_BLANK_ERROR: "Please input list name",
    TASK_NEW_LIST_SAVE: "SAVE",
    TASK_NEW_LIST_CANCEL: "Cancel",

    CONTACTS_TITLE: "Contacts",
    CONTACTS_NO_CONTACTS_MESSAGE: "No contacts found! Add your first contact",
    CONTACTS_ADD_NEW_CONTACT_BUTTON: "Add new contact",
    CONTACTS_MEMBERS_TITLE: "Circle members",
    CONTACTS_NAME: "NAME",
    CONTACTS_EMAIL: "EMAIL",
    CONTACTS_PHONE: "PHONE",
    CONTACTS_SORT: "Sort",
    CONTACTS_ADD_TITLE: "Add contact",
    CONTACTS_ADD_NAME: "Full name",
    CONTACTS_ADD_EMAIL: "Email",
    CONTACTS_ADD_IMAGE: "Edit image",
    CONTACTS_ADD_ADDRESS: "Address",
    CONTACTS_ADD_CITY: "City",
    CONTACTS_ADD_POSTCODE: "Postcode",
    CONTACTS_ADD_PHONE: "Contact number",
    CONTACTS_ADD_ALT_PHONE: "Alternate number",
    CONTACTS_ADD_ALT_PHONE2: "ALTERNATE NUMBER",
    CONTACTS_ADD_RELATIONSHIP2: "RELATIONSHIP",
    CONTACTS_ADD_RELATIONSHIP: "Relationship",
    CONTACTS_ADD_ACTIONS: "ACTIONS",
    CONTACTS_ADD_NOTES: "Notes",
    CONTACTS_ADD_NAME_BLANK_ERROR: "Please input full name!",
    CONTACTS_ADD_EMAIL_INVALID_ERROR: "Please input a valid email!",
    CONTACTS_ADD_SAVE_BUTTON: "Save",
    CONTACTS_ADD_CANCEL_BUTTON: "Cancel",
    CONTACTS_ADD_BACK_BUTTON: "Back",
    CONTACT_EDIT_ICON: "Edit",
    CONTACT_EDIT_TITLE: "Edit contact",
    CONTACT_DELETE_ICON: "Delete",
    CONTACT_DELETE_CONFIRMATION: "Delete this contact?",
    CONTACT_DELETE_CONFIRMATION_YES: "Yes",
    CONTACT_DELETE_CONFIRMATION_CANCEL: "Cancel",
    CONTACT_UPDATED: "Contact updated",
    CONTACT_DELETED: "Contact deleted",



    MEDICATIONS_TITLE: "Medications",
    MEDICATIONS_NO_MEDICATION_ONE: "No medications found! ",
    MEDICATIONS_NO_MEDICATION_TWO: "Add your first medication",
    MEDICATION_ADD_BUTTON: "Add new medication",
    MEDICATIONS_TODAY: "Today's medications",
    MEDICATIONS_ALL: "All medications",
    MEDICATIONS_PURPOSE: "Purpose",
    MEDICATIONS_DOSE: "Dose",
    MEDICATIONS_EDIT: "Edit",
    MEDICATIONS_DELETE: "Delete",
    MEDICATIONS_ALL_CURRENT_TITLE: "Current",
    MEDICATIONS_ALL_CURRENT_STARTS: "Starts",
    MEDICATIONS_ALL_CURRENT_ENDS: "Ends",
    MEDICATIONS_ALL_PAST_TITLE: "Past",
    MEDICATION_ADD_TITLE: "Create medication",
    MEDICATION_ADD_NAME: "Medications name",
    MEDICATION_ADD_NAME_PLACEHOLDER: "e.g Aspirin",
    MEDICATION_ADD_PURPOSE: "Purpose",
    MEDICATION_ADD_PURPOSE_PLACEHOLDER: "e.g Heart condition, asthma etc",
    MEDICATION_ADD_IMAGE: "Upload image",
    MEDICATION_ADD_FREQUENCY: "How often?",
    MEDICATION_ADD_FREQUENCY_PLACEHOLDER: "Select frequency of medication",
    MEDICATION_ADD_FREQUENCY_ONE: "As required",
    MEDICATION_ADD_FREQUENCY_TWO: "Every day",
    MEDICATION_ADD_FREQUENCY_THREE: "Every two days",
    MEDICATION_ADD_FREQUENCY_FOUR: "Every three days",
    MEDICATION_ADD_FREQUENCY_FIVE: "Every four days",
    MEDICATION_ADD_FREQUENCY_SIX: "Every five days",
    MEDICATION_ADD_FREQUENCY_SEVEN: "Every six days",
    MEDICATION_ADD_FREQUENCY_EIGHT: "Every week",
    MEDICATION_ADD_FREQUENCY_NINE: "Every two weeks",
    MEDICATION_ADD_FREQUENCY_TEN: "Every month",
    MEDICATION_ADD_CURRENT_CHECKBOX: "Is this medication current?",
    MEDICATION_ADD_DOSE: "Dose",
    MEDICATION_ADD_DOSE_PLACEHOLDER: "e.g. 1 tablet or 10 drops",
    MEDICATION_ADD_TIME: "Time",
    MEDICATION_ADD_ADD_MORE: "Add dose",
    MEDICATION_ADD_START_DATE: "Start date",
    MEDICATION_ADD_END_DATE: "End date (leave empty if there is no end date)",
    MEDICATION_ADD_ADD_NOTES: "Add medication notes (take before meals, taken orally, etc.)",
    MEDICATION_ADD_ADD_NOTES_PLACEHOLDER: "Add medication notes",
    MEDICATION_ADD_NAME_BLANK_ERROR: "Please input the title!",
    MEDICATION_ADD_START_DATE_BLANK_ERROR: "Please select start date",
    MEDICATION_ADD_SAVE: "Save",
    MEDICATION_ADD_CANCEL: "Cancel",
    MEDICATION_EDIT: "Edit medication",
    MEDICATION_DELETE_CONFIRMATION: "Are you sure to delete this medication？",
    MEDICATION_DELETE_CONFIRMATION_YES: "Yes",
    MEDICATION_DELETE_CONFIRMATION_NO: "No",
    MEDICATION_UPDATED: "Medication updated",
    MEDICATION_DELETED: "Medication deleted",





    PROMOCODE_TITLE: "Promo Codes",
    PROMOCODE_CREATE_BUTTON: "Create promo-code",
    PROMOCODE_NAMES: "PROMO CODES",
    PROMOCODE_NO_OF_USERS: "No. of users",
    PROMOCODE_USED: "USED",
    PROMOCODE_DESCRIPTION: "DESCRIPTION",
    PROMOCODE_CREATED_DATE: "CREATED DATE",
    PROMOCODE_LAST_USED: "LAST USED",
    PROMOCODE_EXPIRES_ON: "EXPIRES ON",
    PROMOCODE_EDIT: "EDIT",
    PROMOCODE_DELETE: "DELETE",
    PROMOCODE_CREATE_TITLE: "Create Promo Code",
    PROMOCODE_CREATE_NAME: "Promo code",
    PROMOCODE_CREATE_NAME_PLACEHOLDER: "Free Access Code",
    PROMOCODE_CREATE_NO_OF_USERS: "No. of users",
    PROMOCODE_CREATE_DESCRIPTION: "Description",
    PROMOCODE_CREATE_EXPIRY_DATE: "Expires on",
    PROMOCODE_CREATE_NAME_BLANK_ERROR: "Please enter a Promo code!",
    PROMOCODE_CREATE_SAVE_BUTTON: "SAVE",
    PROMOCODE_CREATE_CANCEL_BUTTON: "Cancel",
    PROMOCODE_EDIT_TITLE: "Edit Promo Code",
    PROMOCODE_DELETE_CONFIRMATION: "Are you sure to delete this promocode?",
    PROMOCODE_DELETE_CONFIRMATION_YES: "YES",
    PROMOCODE_DELETE_CONFIRMATION_CANCEL: "Cancel",
    PROMOCODE_MAX_USER_LIMIT_ERROR:"Please enter the limit equal or greater than used, currently used is",

    RECOVER_ACCOUNT_TITLE: "Recover Account",
    RECOVER_ACCOUNT_EMAIL: "Email",
    RECOVER_ACCOUNT_EMAIL_PLACEHOLDER: "Select a user",
    RECOVER_ACCOUNT_BUTTON: "Recover Account",
    RECOVER_ACCOUNT_COPY: "Copied to clipboard",


    ANALYTICS_TITLE: "User Analytics",

    ADMIN_HEADER_SEARCH_PLACEHOLDER: "Search Medications, Notes, Etc...",
    ADMIN_HEADER_SEARCH_CATEGORY_PLACEHOLDER: "All sections",
    ADMIN_HEADER_SEARCH_CIRCLE_PLACEHOLDER: "All circles",
    ADMIN_MENU_HOME: "Home",
    ADMIN_MENU_SETTINGS: "Settings",
    ADMIN_MENU_SETTINGS_ONE: "Site Settings",
    ADMIN_MENU_SETTINGS_TWO: "Services",
    ADMIN_MENU_SETTINGS_THREE: "Promo Code",
    ADMIN_MENU_SETTINGS_FOUR: "Recover Account",
    ADMIN_MENU_SETTINGS_FIVE: "User Analytics",
    ADMIN_MENU_SETTINGS_SIX: "Circles connected",
    ADMIN_SITE_SETTINGS_TITLE: "Site Settings",
    ADMIN_SITE_SETTINGS_MAX_MEMBERS: "Maximum members per circle",
    ADMIN_SITE_SETTINGS_MAX_MEMBERS_HELP: "Maximum allowed users to join to a Circle. Keep empty to allows infinite members",
    ADMIN_SITE_SETTINGS_LIST_ITEMS: "List items per page",
    ADMIN_SITE_SETTINGS_LIST_ITEMS_HELP: "Default list count per page",
    ADMIN_SITE_SETTINGS_DEFAULT_ROLE: "Default role",
    ADMIN_SITE_SETTINGS_DEFAULT_ROLE_HELP: "Default role of a new user",
    ADMIN_SITE_SETTINGS_DEMO_VALIDITY: "Demo circle validity",
    ADMIN_SITE_SETTINGS_DEMO_VALIDITY_HELP: "If you change this, demo circle validy will change",
    ADMIN_SITE_SETTINGS_SENDER_NAME: "Sender name",
    ADMIN_SITE_SETTINGS_SENDER_EMAIL: "Sender email",
    ADMIN_SITE_SETTINGS_CAN_REGISTER_TOGGLE: "Can register",
    ADMIN_SITE_SETTINGS_CAN_REGISTER_TOGGLE_HELP: "Enable public Signup",
    ADMIN_SITE_SETTINGS_MAX_MEMBERS_BLANK_ERROR: "Please input max users allowed !",
    ADMIN_SITE_SETTINGS_LIST_ITEMS_BLANK_ERROR: "Please input list limit !",
    ADMIN_SITE_SETTINGS_DEMO_VALIDITY_BLANK_ERROR: "Please enter your demo circle validity!",
    ADMIN_SITE_SETTINGS_SENDER_NAME_BLANK_ERROR: "Please enter your sender name!",
    ADMIN_SITE_SETTINGS_SENDER_EMAIL_BLANK_ERROR: "Please enter your sender email!",
    ADMIN_SITE_SETTINGS_SAVE_BUTTON: "Save",
    ADMIN_SITE_SETTINGS_CANCEL_BUTTON: "Cancel",

    SERVICES_TITLE: "Services",
    SERVICES_CREATE_BUTTON: "Create Services",
    SERVICE_TITLE: "TITLE",
    SERVICES_SERVICE_ID: "SERVICE ID",
    SERVICES_SERVICE_KEY: "SERVICE KEY",
    SERVICES_STATUS: "STATUS",
    SERVICES_RESEND_LINK: "RESEND LINK",
    SERVICES_EDIT: "EDIT",
    SERVICES_DELETE: "DELETE",
    SERVICE_CREATE_TITLE: "Create Service Admin",
    SERVICE_CREATE_FIRST_NAME: "First Name",
    SERVICE_CREATE_FIRST_NAME_BLANK_ERROR: "Please input First Name !",
    SERVICE_CREATE_LAST_NAME: "Last Name",
    SERVICE_CREATE_LAST_NAME_BLANK_ERROR: "Please input Last Name!",
    SERVICE_CREATE_EMAIL: "Email",
    SERVICE_CREATE_EMAIL_BLANK_ERROR: "Please enter email!",
    SERVICE_CREATE_EMAIL_DUPLICATE_ERROR: "sorry, email has already been used.",
    SERVICE_CREATE_CATEGORY_NAME: "Category Name",
    SERVICE_CREATE_CATEGORY_NAME_BLANK_ERROR: "Please enter Category Name!",
    SERVICE_CREATE_SERVICE_NAME: "Service Name",
    SERVICE_CREATE_SERVICE_NAME_BLANK_ERROR: "Please input Service Name !",
    SERVICE_CREATE_STATUS: "Status",
    SERVICE_CREATE_STATUS_ACTIVE: "Active",
    SERVICE_CREATE_STATUS_INACTIVE: "Inactive",
    SERVICE_CREATE_INSTRUCTION_TEXT: "Instruction Text",
    SERVICE_CREATE_INSTRUCTION_TEXT_BLANK_ERROR: "Please enter Instruction Text!",
    SERVICE_CREATE_LIVE_CHECKBOX: "Live",
    SERVICE_CREATE_SAVE_BUTTON: "SAVE",
    SERVICE_CREATE__CANCEL_BUTTON: "Cancel",
    SERVICE_EDIT_TITLE: "Edit Service Admin",
    SERVICE_DELETE_CONFIRMATION: "Delete service admin?",
    SERVICE_DELETE_CONFIRMATION_DELETE: "Delete",
    SERVICE_DELETE_CONFIRMATION_CANCEL: "Cancel",

    SERVICE_ADMIN_SETTINGS_OPTION: "Settings",
    SERVICE_ADMIN_SETTINGS_MANAGE_USERS_OPTION: "Manage Users",
    SERVICE_ADMIN_CIRCLE_NOCIRCLE_MESSAGE: "No Circles found! Add your first Circle",
    SERVICE_ADMIN_CIRCLE_CREATE_BUTTON: "Create Circles",
    SERVICE_ADMIN_CIRCLE_CREATE_TITLE: "Create Circle",
    SERVICE_ADMIN_CIRCLE_CREATE_ACCESS_CODE: "Free Access Code",
    SERVICE_ADMIN_CIRCLE_CREATE_FIRST_NAME: "Circle firstname (cared for)",
    SERVICE_ADMIN_CIRCLE_CREATE_FIRST_NAME_PLACEHOLDER: "firstname",
    SERVICE_ADMIN_CIRCLE_CREATE_LAST_NAME_CARED: "Circle lastname (cared for)",
    SERVICE_ADMIN_CIRCLE_CREATE_LAST_NAME_PLACEHOLDER: "lastname",
    SERVICE_ADMIN_CIRCLE_CREATE_WELCOME_TEXT: "Welcome text",
    SERVICE_ADMIN_CIRCLE_CREATE_ADMIN: "Jointly administrator (carer)",
    SERVICE_ADMIN_CIRCLE_CREATE_ADMIN_EMAIL: "Email",
    SERVICE_ADMIN_CIRCLE_CREATE_ADMIN_FIRST_NAME: "firstname",
    SERVICE_ADMIN_CIRCLE_CREATE_LAST_NAME: "lastname",
    SERVICE_ADMIN_CIRCLE_CREATE_ADD_MORE_BUTTON: "Add More Members",
    SERVICE_ADMIN_CIRCLE_CREATE_SAVE: "SAVE",
    SERVICE_ADMIN_CIRCLE_CREATE_CANCEL: "Cancel",
    SERVICE_ADMIN_RESEND_ICON: "Resend welcome mail",
    SERVICE_ADMIN_COPY: "Copied to clipboard",
    SERVICE_ADMIN_CIRCLE_CREATE_FIRST_NAME_BLANK_ERROR: "Please input Circle first name!",
    SERVICE_ADMIN_CIRCLE_CREATE_CIRCLE_LAST_NAME_BLANK_ERROR: "Please input Circle last name!",
    SERVICE_ADMIN_CIRCLE_CREATE_ADMIN_EMAIL_BLANK_ERROR: "Please input Email!",
    SERVICE_ADMIN_CIRCLE_CREATE_ADMIN_FIRST_NAME_BLANK_ERROR: "Please input First name!",
    SERVICE_ADMIN_CIRCLE_CREATE_LAST_NAME_BLANK_ERROR: "Please input Last name!",

    GENERAL_JOINTLY_AT_A_GLANCE: "Jointly at a glance",
    GENERAL_FAQS: "FAQs",
    GENERAL_USER_GUIDE: "User guide",
    GENERAL_ABOUT: "About/Contact",
    GENERAL_TERMS: "Terms of service",
    GENERAL_PRIVACY: "Privacy notice",
    GENERAL_COPY: "Carers UK",
    ADMIN_LANDING_TITLE: "Welcome to Jointly",
    ADMIN_LANDING_CIRCLES: "Your circles",
    ADMIN_LANDING_CIRCLES_ADD_BUTTON: "Create new circle",
    ADMIN_HELP_TEXT_ONE: "Jointly works with circles of care.",
    ADMIN_HELP_TEXT_ONE_SPLIT_1: "Jointly",
    ADMIN_HELP_TEXT_ONE_SPLIT_2: "works",
    ADMIN_HELP_TEXT_ONE_SPLIT_3: "with circles of care.",
    ADMIN_HELP_TEXT_TWO: "Create a circle of care around the person you are looking after and invite people to join you and share the caring.",
    ADMIN_HELP_TEXT_THREE: "Alternatively, you can use Jointly on your own to organize the care around someone.",






    LANDING_PAGE_HEAD_1: "Are you looking",
    LANDING_PAGE_HEAD_2: "after someone?",
    LANDING_PAGE_HEAD_3: "Jointly can help make caring a",
    LANDING_PAGE_HEAD_4: "little easier and less stressful",
    LANDING_PAGE_SIGNUP: "Sign up to Jointly today",
    LANDING_PAGE_ABOUT: "About Jointly",
    LANDING_PAGE_GLANCE: "At a glance",
    LANDING_PAGE_WHAT: "What carers say",
    LANDING_PAGE_EMP: "Employers and service providers",
    LANDING_PAGE_FAQ: "FAQs",
    LANDING_PAGE_TODO_1: "Jointly combines group messaging and to-do lists with other useful features, including medication lists, calendar and more.",
    LANDING_PAGE_TODO_2: "Jointly makes communication and coordination between those who share the care as easy as a text message",
    LANDING_PAGE_TOP: "Return to top",
    LANDING_PAGE_HOME: "Home",
    LANDING_PAGE_INVITE: "Invite people, see who is in your circle, view a log of recent activity",
    LANDING_PAGE_PROFILE: "Profile",
    LANDING_PAGE_STORE: "Store useful information about the person you are looking after and access it any time at a click of a button!",
    LANDING_PAGE_MESSAGING: "Messaging",
    LANDING_PAGE_COMMUNCATE: "Communicate with everyone in your Jointly circle at a touch of a button! Simply post a message or upload an image.",
    LANDING_PAGE_NOTES: "Notes",
    LANDING_PAGE_CREATE_NOTES: "Create and organise notes and store images, sort them into categories or just view as a simple list.",
    LANDING_PAGE_TASKS: "Tasks",
    LANDING_PAGE_TASK_DETAILS: "Keep organised and on top of things by using tasks and task lists. Simply create a task and assign it to any member of your Jointly circle, including yourself, and monitor its status.",
    LANDING_PAGE_CALENDER: "Calendar",
    LANDING_PAGE_CALENDER_DETAILS: "Create date/time specific events and invite anyone in your circle.",
    LANDING_PAGE_MEDICATION: "Medications",
    LANDING_PAGE_MEDICATION_DETAILS: "Keep track of current and past medication of the person you are caring for. You can also upload an image to quickly recognise a medicine.",
    LANDING_PAGE_DEVICES: "Devices",
    LANDING_PAGE_DEVICE_DETAILS: "Jointly can now connect to health and care devices to share information with your circle. Please check this section to see which devices can be linked.",
    LANDING_PAGE_CONTACTS: "Contacts",
    LANDING_PAGE_CONTACT_DETAILS: "Store useful contacts and access their details anytime, anywhere!",
    LANDING_PAGE_LOOK: "Looking after someone can be so complicated. Jointly helps me stay on top of things and share information easily with everyone involved in my son’s care",
    LANDING_PAGE_IDEA: "I think it’s a great idea –a great way of sharing information with the people I choose. This app helps me to keep everyone in the loop. Thank you! ",
    LANDING_PAGE_TOOL: "I'm a junior doctor who has just started using your excellent app for the care of my grandfather, and it's been a really useful tool.",
    LANDING_PAGE_PROFESSIONALS: "Jointly for professionals",
    LANDING_PAGE_FREE1: "Forward thinking employers and service providers offer Jointly for free",
    LANDING_PAGE_FREE2: "to carers in their workforce or locality.",
    LANDING_PAGE_FREE3: "To find out more contact",
    LANDING_PAGE_FREE4: " client.services@carersuk.org",
    LANDING_PAGE_MATTERS: "Why supporting carers matters?",
    LANDING_PAGE_DELIVERY1: "Effectively delivering policy change",
    LANDING_PAGE_DELIVERY2: "A new policy landscape means that there are real changes in the way that professionals and organisations will need to support carers. Jointly is a low cost solution that can help you deliver this change, reaching families and carers with a tool that makes sense to their everyday lives.",
    LANDING_PAGE_RETENTION1: "Retention of skilled workers",
    LANDING_PAGE_RETENTION2: "1 in 7 people in any workforce is caring for someone who is ill, frail or has a disability. The majority will be between 45-64, the peak age for caring, when many employees will have gained valuable skills and experience. With fewer young people entering the job market there has never been a more important time to focus on the benefits of retaining skilled workers rather than incurring the costs of recruiting and retraining new staff.",
    LANDING_PAGE_PROF: "What professionals say",
    LANDING_PAGE_PROF2: "We were delighted to offer the Jointly app to our colleagues. Jointly is an innovative service that supports carers to manage care for a loved one, which is very helpful for carers who balance their caring responsibilities with work.",
    LANDING_PAGE_OFFER: "We feel Jointly is an innovative product we can offer carers in order to support them in their caring role. It is especially useful for those who are juggling work with care and those carers who are sharing the care with other family members, friends or neighbours.",
    LANDING_PAGE_QUESTIONS: "Frequently asked questions",
    LANDING_PAGE_Q1: "What is Jointly?",
    LANDING_PAGE_A1: "Jointly is a mobile and online application created by carers for carers. It is designed to make caring a little easier, less stressful and more organised. It combines group messaging with other useful features including to-do and medication lists, calendar and more! Use it with others or on your own.",
    LANDING_PAGE_Q2: "On which devices can I use Jointly?",
    LANDING_PAGE_A2A: "You can access Jointly on the following devices (note that you will need to be online to make changes to Jointly):",
    LANDING_PAGE_A2B: "iPhone and iPad (iOS version 9.0 and above)",
    LANDING_PAGE_A2C: "Android devices (version 5 and above). If you are running an older version of Android we recommend to install the Firefox browser on your device (download it from Google Play) and use",
    LANDING_PAGE_A2D: "jointlyapp.com",
    LANDING_PAGE_A2E: ", which has the same functionality as the Android version.",
    LANDING_PAGE_A2F: "Most modern web browsers (home computers, laptops, smartphones, tablets, etc.) at ",
    LANDING_PAGE_A2G: "jointlyapp.com",
    LANDING_PAGE_A2H: "Once you create a Jointly account you can use it to log in to Jointly on any device you choose. Download the Jointly app on your iPhone, iPad or Android device or visit ",
    LANDING_PAGE_A2I: "jointlyapp.com",
    LANDING_PAGE_A2J: " from any other tablet, mobile phone, laptop or home computer.",
    LANDING_PAGE_Q3: "Is Jointly free?",
    LANDING_PAGE_A3: "You can visit ",
    LANDING_PAGE_A3A: "jointlyapp.com",
    LANDING_PAGE_A3B: "or download the app for free and access the Jointly video, Jointly user guide and Jointly FAQs in order to find out more about Jointly. Jointly works with circles of care. You can purchase a Jointly circle with an one-off payment of £2.99 either through the Apple or Google Play stores or at ",
    LANDING_PAGE_MORE: "More FAQs",
    LANDING_PAGE_BROUGHT: "Jointly is brought to you by Carers UK",
    LANDING_PAGE_: "",

    DEVICE_FILTER: "Filter by services",




    COOKIE_TITLE: "COOKIE POLICY",
    COOKIE_TITLE_ANS1: "Carers UK respects the privacy of visitors to our Jointly website and wants to protect any personal information that you give to us. This statement is made in order to comply with the provisions of the General Data Protection Regulation (GDPR), and together with our",
    COOKIE_TITLE_ANS2: "privacy policy",
    COOKIE_TITLE_ANS3: " will tell you how Carers UK will use any personal information that you give to us.",
    COOKIE_Q1: "How we collect information",
    COOKIE_A1: "We get personal information about you when you sign up for a new Jointly user account, when you enter data into Jointly, contact us to receive technical support, give us feedback or make a complaint or give us personal information in any other way.",
    COOKIE_Q2: "What information we collect",
    COOKIE_A2: "To provide you with a good experience while you browse and to help improve Jointly, we use cookies.",
    COOKIE_A2A: `Cookies are small text markers stored on your computer that enable us to understand how people use our website. No personally identifiable information is stored on the cookies. In common with many similar websites, we use them to help remember preferences and for statistical measurements - for example so we know how many "hits" a page has had. Cookies can't harm your computer.`,
    COOKIE_Q3: "We use cookies to:",
    COOKIE_A3A: "remember certain information about users so they don’t have to repeatedly give the same information.",
    COOKIE_A3B: "recognise if users are already logged in to certain areas of the website.",
    COOKIE_A3C: "measure how people use our website so we can continually improve how information is provided.",
    COOKIE_A3D: "You can find out more about how to control cookies on the Information Commissioner's Office website.",
    COOKIE_Q4: "Your consent",
    COOKIE_A4: "Cookies may be set by us or third parties. By continuing to browse our site you’re agreeing to our use of cookies.",
    COOKIE_Q5: "Why do we use cookies?",
    COOKIE_A5: "Cookies are important to make our website work and help us improve it,",
    COOKIE_A5A: "These are the main reasons we use cookies:",
    COOKIE_Q6: "1. Google Analytics and Google Tag Manager",
    COOKIE_A6A: "This is a service we use from Google that collects information about how people use our website. We use this to make sure we are providing the best service we can to our web visitors.",
    COOKIE_A6B: "Using cookies Google Analytics stores information about",
    COOKIE_A6C: "what pages you visit",
    COOKIE_A6D: "how long you stay on the page",
    COOKIE_A6E: "how you came to the site",
    COOKIE_A6F: "what things you click on",
    COOKIE_A6G: "Although we collect this information we do not collect any personal information - so we don’t know what your name is or where you live. And the information we collect cannot be used to identify you.",
    COOKIE_Q7: "2. First-party cookies",
    COOKIE_A7: "You use cookies to provide continuity and maintain your login from page to page",
    COOKIE_Q8: "3. Third-party cookies",
    COOKIE_A8: "We may use cookies for the site to function correctly when you are referred to our payment provider ",
    COOKIE_A8A: "www.stripe.com ",
    COOKIE_A8B: "if you are purchasing a Jointly circle",
    COOKIE_Q9: "How to control cookies",
    COOKIE_A9: "You can control and/or delete cookies as you wish or delete cookies installed by the site - for more details, see ",
    COOKIE_A9A: "aboutcookies.org. ",
    COOKIE_A9B: "You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed. To do so you should modify your browser settings click on the help section of your Internet browser and follow the instructions. If you do this, however, you may have to manually adjust some preferences every time you visit the site and some services and functionalities may not work.",
    COOKIE_: "",





    PRIVACY_TITLE: "Privacy Notice",
    PRIVACY_DESC1: "Jointly is owned and operated by Carers UK and is made available for sale on the Apple and Google app stores, via https://jointlyapp.com and also delivered in partnership with employers or local service providers.",
    PRIVACY_DESC2: "Carers UK is committed to protecting your personal information and being transparent about what we do with it, no matter how you interact with us. That’s whether you join as a member, create an account in one of the online platforms we own and operate, buy our products and services, subscribe to our newsletter or want to learn more about what we do.",
    PRIVACY_DESC3: "This Privacy Notice tells you what to expect when Carers UK collects personal information on Jointly (",
    PRIVACY_DESC4: "or installed from app stores:",
    PRIVACY_POINT: "We are committed to using your personal information in accordance with our responsibilities. We are required to provide you with the information in this Privacy Notice under applicable law which includes:",
    PRIVACY_P1: "The General Data Protection Regulation (EU) 2016/679",
    PRIVACY_P2: "The Data Protection Act 2018 (‘DPA’) referred to as the ‘data protection legislation’",
    PRIVACY_P3: "The Privacy and Electronic Communications (EC Directive) Regulations 2003.",
    PRIVACY_EXPECT: "We won’t do anything with your information you wouldn’t reasonably expect.",
    PRIVACY_PROCESS: "Processing of your personal information is carried out by or on behalf of Carers UK, registered as a charity in England and Wales (246329), Scotland (SC039307); and Carers UK Trading Ltd registered as a company in England and Wales (06034910) (collectively ‘Carers UK Group’).",
    PRIVACY_PERSONAL: "This notice, together with Jointly’s terms and conditions tells you about how we collect, use and protect your personal information.",
    PRIVACY_QUERIES: "If you have any queries about our Privacy Notice, please get in touch with our Information Governance team:",
    PRIVACY_EMAIL: "Email",
    PRIVACY_PHONE: "Phone",
    PRIVACY_POST: "Post",
    PRIVACY_POST1: "Data Protection Advisor",
    PRIVACY_QUESTION: "How and when we collect information about you",
    PRIVACY_Q1: "When you directly give us information",
    PRIVACY_A1: "As Jointly is an app that you can use to store information about yourself and someone that you are caring for, it is possible for you to store a wide variety of information within the app, some of which may be sensitive and confidential. It is also possible for you to choose to share this with another person by inviting them to join your Jointly circle.",
    PRIVACY_A1A: "All information that you store in Jointly is stored in our secure hosted datacentre and a copy of this information could also be stored in your app’s or web browser’s cache so that you are able to view it offline.",
    PRIVACY_A1B: "We will also collect and store information about you when you interact with us. For example, this could be when you contact us to receive technical support, give us feedback or make a complaint",
    PRIVACY_Q2: "When you indirectly give us information",
    PRIVACY_A2: "We may obtain information about your use of Jointly, for example the pages you visit and how you navigate the site, by using cookies. Please visit our ",
    PRIVACY_A2A: "cookies policy",
    PRIVACY_A2B: "for more information on this.",
    PRIVACY_Q3: "What information we might collect",
    PRIVACY_A3: "If you are signing up for a new Jointly user account we will ask you to provide your email address and this is required to log in to the Jointly service. There are also areas to store your phone number and address but these are optional.",
    PRIVACY_A3A: "If you contact us by phone, mail, in person or online in order to receive more information about the service, access technical support, give feedback or make a complaint information we collect may include your name, email address, telephone number, and other information relating to you personally which you may choose to provide to us.",
    PRIVACY_A3B: "Data protection law recognises that certain types of personal information are more sensitive. This is known as 'sensitive' or 'special category' personal information and covers information revealing racial or ethnic origin, religious or philosophical beliefs and political opinions, trade union membership, genetic or biometric data, information concerning health or data concerning a person's sex life or sexual orientation",
    PRIVACY_A3C: "The only sensitive information we store is the information that you voluntarily enter into the Jointly app or website or send to us via email. In the event that we store any other sensitive information this will only be collected where necessary and only with your explicit consent. Clear notices will be provided at the time we collect this information, stating what information is needed, and why.",
    PRIVACY_Q4: "If you're 16 or under",
    PRIVACY_A4: "If you're aged 16 or under, you must get your parent/guardian’s permission before you provide any personal information to us.",
    PRIVACY_Q5: "How and why we use your information",
    PRIVACY_A5: "We will use your personal information for the following purposes:",
    PRIVACY_A5A: "To provide access to the Jointly app and website:",
    PRIVACY_A5B: "We will use your personal information to allow you to access our app and website, personalise your experience, and improve and develop it further. In particular, we will use your personal information (email) to facilitate the creation of a user account - this will allow you to log in to the platform to store and retrieve additional information yourself and, if you choose to, to share this information with others by inviting them to join your circle. We may use your email address and name to keep you current with important service updates (these are not marketing emails).",
    PRIVACY_A5C: "Responding to a request:",
    PRIVACY_A5D: "If you contact us with a query, we may use your personal information to provide you with a response.",
    PRIVACY_A5E: "Monitoring and Evaluation:",
    PRIVACY_A5F: "We may use your information in order to improve current and future delivery of our service.",
    PRIVACY_A5G: "Administration:",
    PRIVACY_A5H: "We may use your personal information to record and deal with a complaint, record a request not to receive further marketing information, record what our volunteers have done for us, and for other essential internal record keeping purposes.",
    PRIVACY_Q6: "Who do we share your information with?",
    PRIVACY_A6A: "We will only use your information for the purposes for which it was obtained. We will not, under any circumstances, sell or share your personal information with any third party for their own purposes, and you will not receive marketing from any other companies, charities or other organisations as a result of giving your details to us.",
    PRIVACY_A6B: "We will only share your data for the following purposes:",
    PRIVACY_A6C: "Third party suppliers: We may need to share your information with data hosting providers or service providers who help us to deliver our products, services or projects. These providers will only act under our instruction and are subject to pre-contract scrutiny and contractual obligations containing strict data protection clauses.",
    PRIVACY_A6D: "We always aim to ensure that personal information is only used by those third parties for lawful purposes in accordance with this Privacy Notice. Jointly is sometimes delivered in partnership with service providers or employers who make the service available to service users or employees with caring responsibilities. We will never share your personal information with our delivery partners and any reporting will be done based on anonymised data.",
    PRIVACY_Q7: "How we protect your information",
    PRIVACY_A7A: "We use technical and corporate organisational safeguards to ensure that your personal information is secure. We limit access to information on a need-to-know basis and take appropriate measures to ensure that our people are aware that such information is only used in accordance with this Privacy Notice.",
    PRIVACY_A7B: "We undertake regular reviews of who has access to information that we hold to ensure that your information is only accessible by appropriately trained staff, volunteers and contractors.",
    PRIVACY_A7C: "Our online forms are always encrypted and our network is protected and routinely monitored. Jointly’s data is stored in a safe and secure hosting infrastructure with AWS within the UK. The system complies with the following security and assurance certifications:",
    PRIVACY_POI2: "PCI-DSS Level 1",
    PRIVACY_A7D: "Backups to this system are stored with Amazon S3 at their Data Centres in England. Both centres comply fully with GDPR.",
    PRIVACY_Q8: "System backups",
    PRIVACY_A8: "We take maintenance backups of the entire Jointly system which may be retained for a period of up to 3 months. These are only used for repairing or restoring the system in case of a technical problem and after this time your information will be permanently deleted.",
    PRIVACY_Q9: "How we handle your direct debit and credit card information",
    PRIVACY_A9A: "If you use your credit or debit card to donate to us, buy something or make a booking online, we pass your card details securely to our payment processing partners. We do this in accordance with industry standards and do not store the details on our website.",
    PRIVACY_A9B: "Carers UK is PCI compliant and uses external Payment Card Industry (PCI) compliant providers such as Stripe to collect this data on our behalf. We do not store PCI data on our own systems.",
    PRIVACY_A9C: "However, please be aware that there are always inherent risks in sending information by public networks or using public computers and we cannot 100% guarantee the security of data (including personal information) disclosed or transmitted over public networks.",
    PRIVACY_Q10: "How long will we keep your information?",
    PRIVACY_A10A: "We will keep your personal information only as long as it is necessary for the purposes for which it was collected, taking into account guidance issued by the Information Commissioner’s Office.",
    PRIVACY_A10B: "You can delete all of the information that you store within your Jointly ‘circle’ (containing all of the information about the person you are caring for) and your personal account at any time yourself within the app/website. To do this please go to ‘My account > Delete your account and information’ (please note that you must be the only remaining member of the circle and must be a ‘Circle administrator’ before you will see this option).",
    PRIVACY_A10C: "If you request that we stop processing your personal information for the purpose of marketing we may in some instances need to add your details to a suppression file to enable us to comply with your request not to be contacted.",
    
    PRIVACY_Qa11: "Data retention and deletion:",
    PRIVACY_Aa11: "Jointly user account and Jointly circle data is stored for the purposes of providing access to the Service and to provide access to information about the person being cared for to members of a Jointly circle.",
    PRIVACY_Aa12: "You can delete all of the information that you store within your Jointly ‘circle’ (containing all of the information about the person you are caring for) and your personal account at any time yourself within the app/website. To do this please go to ‘My account > Delete your account and information’ (please note that you must be the only remaining member of the circle and must be a ‘Circle administrator’ before you will see this option).",
    PRIVACY_Aa13: "Jointly user account data:",
    PRIVACY_Aa14: "Jointly user account data is retained until you delete your own user account. When a Jointly user account is deleted we follow a deletion policy to make sure that all data related to your user account is safely and completely removed from our servers.",
    PRIVACY_Aa15: "If a Jointly user account is inactive for five (5) consecutive years then the account is considered inactive. We will notify the account holder that the account and all related data will be deleted within two (2) calendar months unless activity is recorded in the account during the specified time period (we send three reminder emails: two months, one month and one week before deletion). If no further account activity is recorded then all data related to the user account is safely and completely removed from our servers",
    PRIVACY_Aa16: "Jointly circle data:",
    PRIVACY_Aa17: "Is retained until a Jointly user who has circle administrator rights deletes the Jointly circle within the Jointly app. When a Jointly user deletes a Jointly circle we follow a deletion policy to make sure that all data related to that circle is safely and completely removed from our servers.",
    PRIVACY_Aa18: "If a Jointly circle is inactive for five (5) consecutive years (this is defined as no activity in that specific circle by any of the Jointly circle members registered with that circle) we will notify the circle administrators that all data related to that Jointly circle will be deleted within two (2) calendar months unless activity is recorded in the circle during the specified time period. If no further circle activity is recorded then all data related to the Jointly circle is safely and completely removed from our servers.",
    PRIVACY_Q11: "Vulnerable circumstances",
    PRIVACY_A11: "We understand that additional care may be needed when we collect and process the personal information of vulnerable members, supporters and volunteers. In recognition of this, we observe good practice guideline in our interactions with vulnerable people.",
    PRIVACY_Q12: "International transfers of information",
    PRIVACY_A12A: "We may, on occasion decide to use the services of a supplier outside the European Economic Area (EEA), which means that your personal information is transferred, processed and stored outside the EEA. You should be aware that, in general, legal protection for personal information in countries outside the EEA may not be equivalent to the level of protection provided in the EEA.",
    PRIVACY_A12B: "However we take steps to put in place suitable safeguards to protect your personal information when processed by the supplier such as entering into the European Commission approved standard contractual clauses. By submitting your personal information to us you agree to this transfer, storing or processing at a location outside the EEA.",
    PRIVACY_Q13: "Your rights to your personal information",
    PRIVACY_A13A: "Data protection legislation gives you the right to request access to personal information about you which is processed by Carers UK and to have any inaccuracies corrected.",
    PRIVACY_A13B: "You also have the right to ask us to erase your personal information, ask us to restrict our processing of your personal information or to object to our processing of your personal information.",
    PRIVACY_A13C: "If you wish to exercise these rights, please write to us and send it along with copies of two separate identification documents which provide photo identification and confirm your address, such as a passport, driving licence, or utility bill.",
    PRIVACY_A13D: "Please also provide any additional information that is relevant to the nature of your contact with us, as this will help us to locate your records.",
    PRIVACY_A13E: "You can send us the documents via post to:",
    PRIVACY_A13F: "Data Protection Advisor",
    PRIVACY_A13G: "Alternatively email a copy of the form along with scans or photos of your two forms of identification to: governance@carersuk.org",
    PRIVACY_A13H: "We will respond within 30 days, on receipt of your written request and copies of your identification documents.",
    PRIVACY_Q14: "How to make a complaint or raise a concern",
    PRIVACY_A14: "If you would like more information, or have any questions about this notice, to make a formal complaint about our approach to data protection or raise privacy concerns please contact by:",
    PRIVACY_A14A: "If you would like to make a complaint in relation to how we have handled your personal information, please follow our complaints procedure. If you are not happy with the response you receive, then you can raise your concern with the relevant statutory body:",
    PRIVACY_A14B: "Information Commissioner’s Office",
    PRIVACY_A14C: "Alternatively, you can visit their website. We are registered with the Information Commissioner’s Office as a Data Controller under number Z7307775.",
    PRIVACY_Q15: "Changes to our Privacy Notice",
    PRIVACY_A15: "Our Privacy Notice may change from time to time, so please check this page occasionally to see if we have included any updates or changes, and that you are happy with them. (Last updated: 11 May 2020)",
    PRIVACY_: "",


    GLANCE_TITLE: "Jointly at a glance",
    GLANCE_NEXT: "Next",
    GLANCE_PREVIOUS: "Previous",
    GLANCE_EXIT: "Exit",
    GLANCE_Q1: "What is Jointly?",
    GLANCE_A1A: "Developed by ",
    GLANCE_A1B: "Carers UK",
    GLANCE_A1C: " Jointly combines group messaging with other useful features including to-do and medication lists, calendar and more.",
    GLANCE_A1D: "Use it to get organised when caring for someone.",
    GLANCE_PROFILE: "Profile",
    GLANCE_PROFILE_DETAILS: "Use this space to store useful information about the person you are looking after and have it available to access any time at a click of a button! Make a note of their date of birth, condition and caring needs, likes and dislikes or anything else you may think is relevant.",
    GLANCE_MESS: "Messages",
    GLANCE_MESS_DETAIL: "Communicate with everyone in your Jointly circle at a touch of a button! Simply post a message or upload an image. Members of your circle will receive a notification and can respond.",
    GLANCE_CALENDAR: "Calendar",
    GLANCE_CALENDAR_1: "Use the calendar to create date/time specific events and invite anyone in your circle. You can also use the field provided to invite people outside your Jointly circle.",
    GLANCE_CALENDAR_2: "You can keep tabs of all planned events in Jointly’s calendar while at the same time sending and accepting invitations in your personal calendar.",
    GLANCE_NOTES: "Notes",
    GLANCE_NOTES_DETAIL: "You can use Notes to store a wide range of information and add images. This can include documents, bills and information about the household or how someone is feeling.",
    GLANCE_TASKS: "Tasks",
    GLANCE_TASKS_DETAIL_1: "Keep organised and on top of things by using tasks and task lists. Simply create a task and assign it to any member of your Jointly circle, including yourself, and monitor its status.",
    GLANCE_TASKS_DETAIL_2: "The Tasks page gives you a summary of all tasks. They are divided into Tasks (for stand alone tasks, such as:",
    GLANCE_TASKS_DETAIL_3: "Pick up Mum’s medication",
    GLANCE_TASKS_DETAIL_4: ") and Task lists (for tasks that belong to a list, such as:",
    GLANCE_TASKS_DETAIL_5: "Mum’s weekly groceries list -",
    GLANCE_TASKS_DETAIL_6: "under which you can store more items such as ",
    GLANCE_TASKS_DETAIL_6B: "Buy milk, dog food, etc.",
    GLANCE_TASKS_DETAIL_7: "Note",
    GLANCE_TASKS_DETAIL_8: "If your task is time specific (such as",
    GLANCE_TASKS_DETAIL_9: "Drive Mum to doctor's appointment on Tuesday 5 Nov at 10 am",
    GLANCE_TASKS_DETAIL_10: ") we recommend that you create a calendar entry instead of a task.",
    GLANCE_MED: "Medications",
    GLANCE_MED_DETAILS: "Use Jointly's Medications feature to keep track of current and past medication of the person you are caring for. You can also upload an image to quickly recognise a medicine.",
    GLANCE_MED_DETAILS_1: "Using the Medications feature you can:",
    GLANCE_MED_DETAILS_2: "Create an entry for a medication",
    GLANCE_MED_DETAILS_3: "See today’s medication with an indication of the time it should be taken as well as the dose (",
    GLANCE_MED_DETAILS_4: "Today",
    GLANCE_MED_DETAILS_5: "view).",
    GLANCE_MED_DETAILS_6: "See all current and past medication in a list (",
    GLANCE_MED_DETAILS_7: "All medications",
    GLANCE_MED_DETAILS_8: "view)",
    GLANCE_CONT: "Contacts",
    GLANCE_CONT_1: "Nothing helps more than having the right contact details at the right time! Jointly stores the contact details of the members of your circle and also allows you to store more useful contacts so that you can access their details anytime, anywhere!",
    GLANCE_CONT_2: "Click on someone’s number to place a call or on their email address to send an email.",
    GLANCE_: "",



    ABOUT_TITLE: "Contact the Jointly team",
    ABOUT_D1: "If you are experiencing a technical problem or have a technical query please contact:",
    ABOUT_D2: "Our",
    ABOUT_D3: "Jointly user guide",
    ABOUT_D4: " and the ",
    ABOUT_D5: " sections cover many topics. Have a look, you may find what you are looking for.",
    ABOUT_D6: "If you have a general query/suggestion please contact",
    ABOUT_D7: "Jointly has been developed by Carers UK.",
    ABOUT_D8: "At Carers UK we work hard to make life better for the millions of people who look after older, ill or disabled family and friends.",
    ABOUT_D9: "We provide expert help and emotional support",
    ABOUT_D10: "We bring people together so no-one has to care alone",
    ABOUT_D11: "We campaign with carers to create better services, better understanding and better lives.",
    ABOUT_D12: "Caring can be tough and we’re here to support you every step of the way.",
    ABOUT_D13: "Visit",
    ABOUT_D14: "to connect with other carers and get the information you need to help you look after someone and live your own life.",
    ABOUT_D15: "If you want to talk to someone who really understands about caring, give us a call on ",
    ABOUT_: "",














    OTHERS_DELETE_CIRCLE_POPUP: "This action will delete all information stored in this circle! This cannot be undone, are you sure you want to delete this circle?",
    OTHERS_DELETE_CIRCLE: "Delete circle",
    OTHERS_DELETE_CIRCLE_ACTION: "This action will delete this circle. Are you sure?",
    OTHERS_REMOVE_PERSON: "Are you sure you want to remove this person from the circle?",
    OTHERS_EDIT_LIST: "Edit list",
    OTHERS_DELETE_LIST: "Delete list",
    OTHERS_EDIT_TASK_LIST: "Edit task list",
    OTHERS_UPDATE: "Update",


    OTHERS_RIGHTS: "All rights reserved",
    OTHERS_INFO: "Export your data",
    OTHERS_DELETE: "Delete your account and data",
    OTHERS_ACTIVITY: "No activities",
    OTHERS_UNASSIGNES_TASK: "Unassigned tasks",
    OTHERS_CREATE: "Created by",
    OTHERS_CRE: "Created",
    OTHERS_INV: "invited",
    OTHERS_EDIT_CATEGORY: "Edit category",
    OTHERS_CREATE_CATEGORY: "Create New Category",
    OTHERS_CON_INFO: "Contact Info",
    OTHERS_ING: "Image",
    OTHERS_LOAD_COMMENTS: "Loading Comments",
    OTHERS_POST_COMMENT: "Post your Comment here",
    OTHERS_INVITE: "Invite",
    OTHERS_ACTIVE: "ACTIVE",
    OTHERS_CIR_PROFILE: "Edit profile",
    OTHERS_CIR_CREATED: "Circle Created by",
    OTHERS_EMAIL: "Please input your email",
    OTHERS_CIR_TRIAL: "Your trial period expired. Upgrade your circle to continue using Jointly.",
    OTHERS_YOU: "You",
    OTHERS_SEARCH_MESG: "Search messages",
    OTHERS_SEARCH_MED: "Search medication",
    OTHERS_SEARCH_TASK: "Search tasks",
    OTHERS_SEARCH_EVT: "Search events",
    OTHERS_SEARCH_NOTES: "Search notes",
    OTHERS_SEARCH_DEVS: "Search devices",
    OTHERS_SEARCH_CONT: "Search contacts",
    OTHERS_SEARCH_CIRCLE: "My circles",
    OTHERS_INVIT: "invited to joined this circle",
    OTHERS_DUE_TODAY: "Due: today",
    OTHERS_DUE: "Due: ",
    OTHERS_CIR_SETTINGS: "Circle settings ",
    OTHERS_ACTIONS: "ACTIONS",
    OTHERS_CONNECT: "Jointly can now connect to the external devices below. Information from devices will be stored in a separate section named ‘Devices’ in your left hand menu.",
    OTHERS_TITLE: "Title ",
    OTHERS_REMINDERS: "Select reminders",
    OTHERS_SET_1: "On this screen circle administrators can:",
    OTHERS_SET_2: "Invite new members and remove members from the circle.",
    OTHERS_SET_3: "Make members of this circle active or inactive. If you make a member inactive it means that they will no longer be part of this circle. You will no longer be able to assign them Tasks and they won't receive any notification of circle activity.",
    OTHERS_SET_4: "Grant permissions to other members of this circle to be Administrators, edit medications or view information in categories that have been marked as private.",
    OTHERS_SET_4A: "Set a member as an Emergency Contact (ICE), this will be displayed in the contacts list and if you export data from Jointly.",
    OTHERS_SET_5: "Delete the circle. All other members must have left or been removed before a circle can be deleted. Deleting a circle is permanent and cannot be undone.",
    OTHERS_DATE: "Date",
    OTHERS_LEAVE: "Leave",
    OTHERS_DETAILS: "Details",
    OTHERS_SERVICEID: "Service Id",
    OTHERS_SERVICE_KEY: "Service key",
    OTHERS_CIRCLEID: "Circle Id",
    OTHERS_JOIN: "Join",
    OTHERS_REASON: "Reason",
    OTHERS_MEM_INFO: "Member Info",
    OTHERS_MEM: "MEMBER",
    OTHERS_NEW: "Newest first",
    OTHERS_OLD: "Oldest first",
    OTHERS_CIR: "CIRCLE",
    OTHERS_ADMIN: "ADMINISTRATOR",
    OTHERS_CAN: "CAN EDIT",
    OTHERS_MED: "MEDICATIONS",
    OTHERS_PRIVATE: "CAN SEE PRIVATE",
    OTHERS_NOTES: "CATEGORY INFORMATION",




    OTHERS_DOWNLOAD_1: "You can export a copy of your Jointly information at any time. You must be an administrator of the circle to export data.",
    OTHERS_DOWNLOAD_2: "Data can be exported in CSV, Word or PDF formats. You can export all or a selection of your information using the options below.",
    OTHERS_DOWNLOAD_2A: "You can manually select what information you'd like to export from Jointly or you can choose to export your dedicated contingency plan, which includes essential information that would be useful to share if you are unable to care due to illness or any other reason.",
    OTHERS_DOWNLOAD_3: "You will be emailed a link to download your Jointly data. For security you will need to enter your Jointly password to download the file. This link will expire after 24 hours.",
    OTHERS_DOWNLOAD_4: "Download",
    OTHERS_DOWNLOAD_5: "We cannot protect data that you export from Jointly. Please ensure that any sensitive information that you download is stored securely.",
    OTHERS_DOWNLOAD_6: "Use this to export your contingency plan, containing a set of essential information from Jointly that you can share with someone in case of emergency. You can choose to include or exclude any information that you have marked as private within Jointly.",
    OTHERS_ABOUT: "About",
    OTHERS_VIDEO: "Jointly Video",
    OTHERS_COOKIES: "Cookies Policy",
    OTHERS_EVERYTHING: "Everything",
    OTHERS_CATEGORY: "Select a Category",
    OTHERS_REPLY: "Reply",
    OTHERS_FULLNAME: "FULL NAME",
    OTHERS_INVITE_SENT: "INVITE SENT",
    OTHERS_INVITED_BY: "INVITED BY",
    OTHERS_ATTACH: "You can only attach one document to a Note, would you like to replace the current attachment?",
    OTHERS_SURE: "Are you sure?",
    OTHERS_NO_EVTS: "No events",
    OTHERS_EDIT_NOTE: "Edit note",
    OTHERS_UNASSIGN: "Unassign",
    OTHERS_MARK_ALL: "Are you sure you want to mark all messages as read? This action can't be undone",
    OTHERS_UNREAD_ALL: "Do you want to read all notifications?",
    OTHERS_DELETE_ALL: "Are you sure you want to delete all messages? This action can't be undone",
    OTHERS_DELETE_ALL_TITLE: "Do you want to delete all notifications?",
    OTHERS_READ_ALL: "Mark all as read",
    OTHERS_ALL_DAY: "All-day",
    OTHERS_: "",



    TOASTERS_NO_DOWNLOAD: "Could not download!",
    TOASTERS_PAYMENT_SUCCESS: "Payment successfull",
    TOASTERS_CREATE_CIRCLE: "Circle created",
    TOASTERS_CIRCLE_UPDATE: "Circle updated successfully",
    TOASTERS_NOTE_UPDATE: "Note updated",
    TOASTERS_NOTE_CREATE: "Note created",
    TOASTERS_NOTE_DELETE: "Note deleted",
    TOASTERS_TASK_CREATE: "Task created",
    TOASTERS_TASK_UPDATE: "Task updated",
    TOASTERS_INVI_SENT: "Invitations sent",
    TOASTERS_EVT_CREATE: "Event created",
    TOASTERS_EVT_UPDATE: "Event updated",
    TOASTERS_EVT_DELETE: "Event deleted",
    TOASTERS_CONTACT_ADD: "Contact added",
    TOASTERS_CATEGORY_UPDATE: "Category updated successfully",
    TOASTERS_CATEGORY_DELETE: "Category deleted successfully",



    TOASTERS_TASK_DELETE: "Task Deleted",
    TOASTERS_PAY_COMPLETED: "Payment successfully completed",
    TOASTERS_UNAVAILABLE: "Unavailability set successfully.",
    TOASTERS_UNAVAILABLE_UPDATE: "Updated unavailability",
    TOASTERS_UNAVAILABLE_DELETE: "Unavailability deleted successfully",
    TOASTERS_TASK_REMOVE: "Removed task list succesfully",
    TOASTERS_INVI_DELETE: "Invitation has been deleted",
    TOASTERS_LEFT: "You left the circle",
    TOASTERS_MEM_DELETE: "Circle member deleted",
    TOASTERS_CIR_DELETE: "Circle deleted",
    TOASTERS_DEVICE_DELETE: "Device deleted successfully",
    TOASTERS_MED_ADDED: "Medication added",
    TOASTERS_NO_EDIT: "You do not have permission to edit medications. Please contact an administrator of your circle.",
    TOASTERS_NO_DELETE: "You do not have permission to delete medications. Please contact an administrator of your circle.",
    TOASTERS_NO_ACCESS: "You do not have access to this item.",
    TOASTERS_SER_CONN: "Service connected successfully",
    TOASTERS_SER_REMOVED: "Service removed successfully",
    TOASTERS_PROMO_CREATE: "Promo code created successfully",
    TOASTERS_PROMO_UPDATE: "Promo code updated successfully",
    TOASTERS_PROMO_DELETE: "Promo code removed successfully",
    TOASTERS_INVITATION_SEND: "Invitation code  send sucessfully",
    TOASTERS_SER_ADMIN_CREATE: "Service Admin created sucessfully",
    TOASTERS_RESEND_CODE: "Resend invitation code sucessfully",
    TOASTERS_CODE_DELETE: "Invitation code deleted",
    TOASTERS_SER_ADMIN_DELETE: "Service admin removed Successfully",
    TOASTERS_LINK_SUCESS: "Magic link sent Successfully",
    TOASTERS_INVI_RESEND: "Invitation resent",
    TOASTERS_NOTI_CHANGED: "Notification settings changed",
    TOASTERS_SITE_UPDATE: "Site Settings updated",
    TOASTERS_PASSWORD: "Password reset sucessfully",
    TOASTERS_INVI_ACCEPT: "Invitation accepted successfully",
    TOASTERS_INVI_REJECT: "Invitation rejected",
    TOASTERS_ACC_DELETE: "Account has been deleted",
    TOASTERS_NOT_MATCH: "Your New Password and Confirm Password do not match",
    TOASTERS_: "",











    TERMS_HEADING: "Terms of Use for Jointly Service",
    TERMS_DATE: "These Terms of Use are effective on 29 November 2013",
    TERMS_DESC_1: "These terms of use (“Terms of Use”, “Agreement”) constitute a legal agreement between you (“you”, “your”) and Carers UK Trading Ltd (“Carers UK Trading Ltd”, “we”, “us”, “our”) (registered company 6034910, registered office 20 Great Dover Street, London SE1 4LX) for the following, which are referred to collectively in these Terms of Use as the “Jointly Service”:",
    TERMS_DESC_2: "Terms of Use",
    TERMS_DESC_3: "Agreement",
    TERMS_DESC_4: "”) constitute a legal agreement between you (“you”, “your”) and Carers UK Trading Ltd (“",
    TERMS_DESC_5: "Carers UK Trading Ltd”",
    TERMS_DESC_6: "we”",
    TERMS_DESC_7: "us”",
    TERMS_DESC_8: "our”",
    TERMS_DESC_9: ") (registered company 6034910, registered office 20 Great Dover Street, London SE1 4LX) for the following, which are referred to collectively in these Terms of Use as the “",
    TERMS_DESC_10: "Jointly Service",
    TERMS_P1: "the Jointly website (",
    TERMS_P2: "Website",
    TERMS_P3: "the Jointly mobile application software (“App”)",
    TERMS_P4: "any services provided by us through the Website or the App",
    TERMS_P5: "The App includes any updates or supplements to the App (unless they come with separate terms, in which case those terms shall prevail over these Terms of Use in the event of conflict).",
    TERMS_P6: "References to the “Jointly Service” in these Terms of Use refer to the whole or any part of the Jointly Service.",
    TERMS_P7: "By accessing or using the Jointly Service, however accessed or used, you agree to be bound by these Terms of Use. The Jointly Service is owned and controlled by Carers UK Trading Ltd. These Terms of Use affect your legal rights and obligations. If you choose not to agree with any of these Terms of Use, you may not use the Jointly Service.",
    TERMS_P8: "Subject to your compliance with these Terms of Use, and any rules or policies applied by any provider or operator from whose site you downloaded the App (“",
    TERMS_P9: "Appstore",
    TERMS_P10: "”), Carers UK Trading Ltd licenses use of the Jointly Service to you, provided that your use of the Jointly Service is solely for your personal use.",
    TERMS_PARA_2: "Operating system requirements",
    TERMS_PARA_2_1: "iPhone and iPad (iOS version 9.0 and above)",
    TERMS_PARA_2A: "Android devices (version 5 and above)",
    TERMS_PARA_2B: "Most modern web browsers (home computers, laptops, smartphones, tablets, etc.) at ",
    TERMS_PARA_3: "Important notice:",
    TERMS_PARA_3_P1: "By downloading the App from the Website or an Appstore or clicking on the 'Accept' button below you agree to these Terms of Use, which will bind you. These terms include, in particular:",
    TERMS_PARA_3_P2: "the licence and acceptable use restrictions (conditions 3 and 4).",
    TERMS_PARA_3_P3: "the User Content conditions (condition 5).",
    TERMS_PARA_3_P4: "disclaimers of warranties (condition 12).",
    TERMS_PARA_3_P5: "limitations on liability (condition 13).",
    TERMS_PARA_3_P6: "the privacy policy (at the end of these Terms of Use).",
    TERMS_PARA_3_P7: "If you do not agree to these Terms of Use, you are not permitted to download or use the App or any other part of the Jointly Service.",
    TERMS_PARA_3_P8: "As a consumer, you have the right to withdraw from your transaction without charge and without any reason before performing the download. However, you will lose the right to cancel the transaction once you begin the download. This does not affect your consumer rights for any downloaded product which are defective.",
    TERMS_PARA_3_P9: "The App is downloadable at no charge. However, you may be able to purchase products via the App or the Jointly Service, such as Jointly circles. Use of such products shall be governed by and subject to compliance with these Terms of Use but they may also be subject to additional terms of sale at the point of purchase, which you will need to agree to prior to purchase. In the event of any conflict between these Terms of Use and any such terms of sale, these Terms of Use shall prevail.",
    TERMS_PARA_3_P10: "You should print a copy of these Terms of Use for future reference.",
    TERMS_ACK_1: "1. Acknowledgements",
    TERMS_ACK_2: "1.1 From time to time updates to the App may be issued through the Jointly Service or an Appstore. Depending on the update, you may not be able to use the App until you have downloaded the latest version of the App and accepted any new terms.",
    TERMS_ACK_3: "1.2 You will be assumed to have obtained permission from the owners of the mobile telephone or handheld devices that are controlled, but not owned, by you and described in condition 2.2.1 (“Devices”) and to download a copy of the App onto the Devices. You and they may be charged by your and their service providers for internet access on the Devices. You accept responsibility in accordance with these Terms of Use for the use of the App or any other part of the Jointly Service on or in relation to any Device, whether or not it is owned by you.",
    TERMS_ACK_4: "1.3 The terms of our privacy policy from time to time, available below (“Privacy Policy”) apply to the Jointly Service. Additionally, by using the Jointly Service, you acknowledge and agree that internet transmissions are never completely private or secure. You understand that any message or information you send using the Jointly Service may be read or intercepted by others, even if there is a special notice that a particular transmission is encrypted.",
    TERMS_ACK_5: "1.4 By using the Jointly Service, you consent to us collecting and using technical information about the Devices and related software, hardware and peripherals for the Jointly Service that are internet-based or wireless to improve the Jointly Service.",
    TERMS_GRAND_1: "2. Grant and scope of licence",
    TERMS_GRAND_2: "2.1 In consideration of you agreeing to abide by these Terms of Use, we grant you a non-transferable, non-exclusive licence to use the Jointly Service and the App on the Devices, subject to these Terms of Use, the Privacy Policy and rules of any Appstore, which are incorporated into these Terms of Use by reference. We reserve all other rights.",
    TERMS_GRAND_3: "2.2 You may, for your personal purposes only:",
    TERMS_GRAND_4: "2.2.1 download a copy of the App and view, use and display the App on any of the following devices:",
    TERMS_GRAND_5: "(a) iPhone",
    TERMS_GRAND_6: "(b) Android devices",
    TERMS_GRAND_7: "(c) iPad",
    TERMS_GRAND_8: "2.2.2 use the Jointly Service through the Website (subject to your web browser permitting access); and",
    TERMS_GRAND_9: "2.3 You warrant that you have the legal right and capacity to enter into and comply with these Terms of Use in your jurisdiction.",
    TERMS_LICENCE_1: "3. Licence restrictions for the App",
    TERMS_LICENCE_2: "3.1 Except as expressly set out in these Terms of Use or as permitted by any local law, you agree:",
    TERMS_LICENCE_3: "3.1.1 not to rent, lease, sub-license, loan, translate, merge, adapt, vary or modify the App or Documents;",
    TERMS_LICENCE_4: "3.1.2 not to make alterations to, or modifications of, the whole or any part of the App, or permit the App or any part of it to be combined with, or become incorporated in, any other programs;",
    TERMS_LICENCE_5: "3.1.3 not to copy the App, except where such copying is incidental to normal use of the App, or where it is necessary for the purpose of back-up or operational security;",
    TERMS_LICENCE_6: "3.1.4 not to disassemble, decompile, reverse-engineer or create derivative works based on the whole or any part of the App or attempt to do any such thing except to the extent that (by virtue of section 296A of the Copyright, Designs and Patents Act 1988) such actions cannot be prohibited because they are essential for the purpose of achieving inter-operability of the App with another software program, and provided that the information obtained by you during such activities:",
    TERMS_LICENCE_7: "(a) is used only for the purpose of achieving inter-operability of the App with another software program;",
    TERMS_LICENCE_8: "(b) is not unnecessarily disclosed or communicated without our prior written consent to any third party; and",
    TERMS_LICENCE_9: "(c) is not used to create any software that is substantially similar to the App;",
    TERMS_LICENCE_10: "3.1.5 to include our copyright notice on all entire and partial copies you make of the App on any medium;",
    TERMS_LICENCE_11: "3.1.6 not to provide or otherwise make available the App in whole or in part (including object and source code), in any form to any person without prior written consent from us; and",
    TERMS_LICENCE_12: "3.1.7 to comply with all technology control or export laws and regulations that apply to the technology used or supported by the App or any service provided through the App (“Technology”).",
    TERMS_ACCEPT_1: "4. Acceptable use restrictions",
    TERMS_ACCEPT_2: "4.1 You must:",
    TERMS_ACCEPT_3: "4.1.1 not use the Jointly Service in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with these Terms of Use, or act fraudulently or maliciously, for example, by hacking into or inserting malicious code, including viruses, or harmful data, into the Jointly Service or any operating system, or by interfering with or disrupting the Jointly Service or servers or networks connected to the Jointly Service, including by transmitting any worms, viruses, spyware, malware or any other code of a destructive or disruptive nature;",
    TERMS_ACCEPT_4: "4.1.2 not create accounts with the Jointly Service by using an automated device, script, bot, spider, crawler or scraper;",
    TERMS_ACCEPT_5: "4.1.3 not change, modify, adapt or alter the Jointly Service or change, modify or alter another website so as to falsely imply that it is associated with the Jointly Service, Carers UK or Carers UK Trading Ltd;",
    TERMS_ACCEPT_6: "4.1.4 not infringe our intellectual property rights or those of any third party in relation to your use of the Jointly Service;",
    TERMS_ACCEPT_7: "4.1.5 not transmit, or allow to be transmitted, any material that is defamatory, offensive or otherwise objectionable in relation to your use of the Jointly Service;",
    TERMS_ACCEPT_8: "4.1.6 not transmit, or allow to be transmitted, any material that is violent, discriminatory, unlawful, infringing, hateful, pornographic or sexually suggestive photos or other content via the Jointly Service;",
    TERMS_ACCEPT_9: "4.1.7 not bully, abuse, harass, threaten or intimidate people or entities via the Jointly Service;",
    TERMS_ACCEPT_10: "4.1.8 not use the Jointly Service in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users; and",
    TERMS_ACCEPT_11: "4.1.9 not collect or harvest any information or data from the Jointly Service or our systems or attempt to decipher any transmissions to or from the servers running the Jointly Service.",
    TERMS_ACCEPT_12: "4.2 You acknowledge that the Jointly Service has not been developed to meet your individual requirements, and that it is therefore your responsibility to ensure that the facilities and functions of the Jointly Service (in particular the App) meets your requirements.",
    TERMS_ACCEPT_13: "4.3 We only supply the App and other parts of the Jointly Service for domestic and private use. You agree not to use the Jointly Service for any commercial, business or resale purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.",
    TERMS_ACCEPT_14: "4.4 By accessing or using the Jointly Service you represent and warrant that your activities are lawful in every jurisdiction where you access or use it.",
    TERMS_CONTENT_1: "5. User Content conditions",
    TERMS_CONTENT_2: "5.1 You are solely responsible for any data, text, files, information, usernames, images, graphics, photos, profiles, applications, links and other content or materials (collectively, '",
    TERMS_CONTENT_3: "User Content",
    TERMS_CONTENT_4: "') that you submit, post or display on or via the Jointly Service. You must comply with all laws, rules and regulations applicable to your use of the Jointly Service and your User Content.",
    TERMS_CONTENT_5: "5.2 Carers UK Trading Ltd cannot and will not be responsible for any User Content posted on or via the Jointly Service by you or other Jointly Service users.",
    TERMS_CONTENT_6: "5.3 You represent and warrant that: (i) you own the User Content posted by you on or through the Jointly Service or otherwise have the right to post such content; (ii) the posting and use of your User Content on or through the Jointly Service does not violate, misappropriate or infringe on the rights of any third party, including, without limitation, privacy rights, publicity rights, copyrights, trademark and/or other intellectual property rights; (iii) you agree to pay for all royalties, fees, and any other monies owed by reason of User Content you post on or through the Jointly Service.",
    TERMS_CONTENT_7: "5.4 You agree that Carers UK Trading Ltd is not responsible for, and does not endorse, User Content posted within the Jointly Service. Carers UK does not pre-screen, monitor, edit, or remove any User Content. If your User Content violates these Terms of Use, you may bear legal responsibility for that User Content.",
    TERMS_CONTENT_8: "5.5 We may, but have no obligation to, remove, edit, block, and/or monitor User Content or accounts containing User Content that comes to our attention and we determine, at our sole discretion, violates these Terms of Use.",
    TERMS_CONTENT_9: "If you become aware of any User Content which contains materials which breach these Terms of Use or are objectionable in any way please let us know at",
    TERMS_CONTENT_10: "5.6 The Jointly Service does not include a backup service and you agree that you will not rely on the Jointly Service for the purposes of User Content backup or storage.",
    TERMS_CONTENT_11: "Carers UK Trading Ltd will not be liable to you for the loss of any User Content, whether as a result of termination of access to your account or otherwise.",
    TERMS_ACCOUNTS_1: "6. Accounts",
    TERMS_ACCOUNTS_2: "6.1 You must not create accounts with the Jointly Service through unauthorised means, including by redeeming a voucher or any other code not personally assigned to you by Carers UK Trading Ltd or an organisation authorised by Carers UK Trading Ltd.",
    TERMS_ACCOUNTS_3: "6.2 You are responsible for any activity that occurs through your account and you agree you will not sell, transfer, license or assign your account username, or any account rights. You agree that you will not create an account for anyone other than yourself. You also represent that all information you provide or provided to Carers UK Trading Ltd upon registration and at all other times will be true, accurate, current and complete and you agree to update your information as necessary to maintain its truth and accuracy.",
    TERMS_ACCOUNTS_4: "6.3 You agree that you will not solicit, collect or use the login credentials of other Jointly Service users.",
    TERMS_ACCOUNTS_5: "6.4 You are responsible for keeping your password secret and secure.",
    TERMS_ACCOUNTS_6: "6.5 We reserve the right to impose forfeiture of any username for any reason.",
    TERMS_TERM_1: "7. Termination of Jointly Service",
    TERMS_TERM_2: "7.1 We may terminate this Agreement immediately by written notice to you:",
    TERMS_TERM_3: "7.1.1 if you commit a material or persistent breach of this Agreement which you fail to remedy (if remediable) within 14 days after the service of written notice requiring you to do so;",
    TERMS_TERM_4: "7.1.2 if you breach any of the Licence restrictions for the App (condition 3), the Acceptable use restrictions (condition 4) or the User Content conditions (condition 5); or",
    TERMS_TERM_5: "7.1.3 if we discontinue the App or other part of the Jointly Service, or lose any relevant rights required to provide the Jointly Service, or provision of the App or other part of the Jointly Service becomes unlawful.",
    TERMS_TERM_6: "7.2 We reserve the right to suspend or stop providing all or part of the Jointly Service to you at any time if, in our opinion, you violate the letter or spirit of these Terms of Use, or otherwise create a risk or possible legal exposure for Carers UK Trading Ltd or Carers UK or their respective names or reputations.",
    TERMS_TERM_7: "7.3 On termination of this Agreement:",
    TERMS_TERM_8: "7.3.1 all licences and rights granted to you under these Terms of Use shall cease (including your right to use the App);",
    TERMS_TERM_9: "7.3.2 you must immediately cease all activities authorised by these Terms of Use, including your use of the Jointly Service; and",
    TERMS_TERM_10: "7.3.3 you must immediately delete or remove the App from all Devices, and immediately destroy all copies of the App and Documents then in your possession, custody or control and certify to us that you have done so.",
    TERMS_TERM_11: "7.4 Carers UK Trading Ltd will not be liable to you for any modification, suspension, or discontinuation of the Jointly Service.",
    TERMS_LINK_1: "8. Linked sites",
    TERMS_LINK_2: "There may be links from the Jointly Service, or from communications you receive from the Jointly Service, to third-party web sites or features. Functionality on the Jointly Service may also permit interactions between the Jointly Service and a third-party web site or feature. Carers UK Trading Ltd does not control any of these third-party website, any services or products offered through them or any of their content. You expressly acknowledge and agree that Carers UK Trading Ltd is in no way responsible or liable for any such third-party websites, services or features.",
    TERMS_INT_1: "9. Intellectual property rights",
    TERMS_INT_2: "9.1 You acknowledge that all intellectual property rights in the Jointly Service (including the App, the Documents and the Technology) anywhere in the world belong to us or our licensors, that rights in the App are licensed (not sold) to you, and that you have no rights in, or to, the App, the Documents, the Technology or any other part of the Jointly Service other than the right to use the Jointly Service in accordance with these Terms of Use.",
    TERMS_INT_3: "9.2 You acknowledge that you have no right to have access to the App in source-code form.",
    TERMS_INT_4: "9.3 The Jointly name and logo are trademarks of Carers UK, licensed to Carers UK Trading Ltd, and may not be copied, imitated or used, in whole or in part, without the prior written permission of Carers UK. In addition, all page headers, custom graphics, button icons and scripts are service marks, trademarks and/or trade dress of Carers UK, and may not be copied, imitated or used, in whole or in part, without prior written permission from Carers UK.",
    TERMS_SER_1: "10. Availability of Jointly Service",
    TERMS_SER_2: "Although it is Carers UK Trading Ltd’s intention for the Jointly Service to be available as much as possible, there may be occasions when the Jointly Service may be interrupted, including, without limitation, for scheduled maintenance or upgrades, for emergency repairs, or due to failure of telecommunications links and/or equipment. We cannot guarantee that the Jointly Service will operate continuously or without interruptions or be error free and we can accept no liability for its unavailability.",
    TERMS_USERS_1: "11. Other users",
    TERMS_USERS_2: "You are solely responsible for your interaction with other users of the Jointly Service. You agree that Carers UK Trading Ltd is not responsible or liable for the conduct of any user. Exercise common sense and your best judgment when interacting with others, including when you submit or post User Content or any personal or other information.",
    TERMS_WARR_1: "12. Disclaimers of Warranties",
    TERMS_WARR_1A: "YOUR PARTICULAR ATTENTION IS DRAWN TO THIS CLAUSE.",
    TERMS_WARR_2: '12.1 The Jointly Service is provided on an "as is", "as available" and "with all faults" basis. To the fullest extent permissible by law, neither Carers UK Trading Ltd nor any of its employees, managers, officers or agents (collectively, the "',
    TERMS_WARR_3: "Carers UK Trading Ltd Parties",
    TERMS_WARR_4: '") make any representations or warranties or endorsements of any kind whatsoever, express or implied, as to: (a) the Jointly Service (including the Website, the App and the Documents); (b) the User Content; or (c) security associated with the transmission of information to the Jointly Service or via the Jointly Service.',
    TERMS_WARR_5: "12.2 To the fullest extent permissible by law, the Carers UK Trading Ltd Parties hereby disclaim all warranties, express or implied, including, but not limited to, the warranties of merchantability, fitness for a particular purpose, non-infringement, title, custom, trade, quiet enjoyment, system integration and freedom from computer virus, in relation to the Jointly Service.",
    TERMS_WARR_6: "12.3 The Carers UK Trading Ltd Parties do not represent or warrant that the Jointly Service will be error-free or uninterrupted; that defects will be corrected; or that the Jointly Service or the server that makes the Jointly Service available is free from any harmful components, including, without limitation, viruses. The carers uk parties do not make any representations or warranties that the information (including any instructions) on the Jointly Service is accurate, complete, or useful.",
    TERMS_WARR_7: "12.4 You acknowledge that your use of the Jointly Service is at your sole risk. The Carers UK Trading Ltd Parties do not warrant that your use of the Jointly Service is lawful in any particular jurisdiction, and specifically disclaim such warranties. Some jurisdictions limit or do not allow the disclaimer of implied or other warranties so the above disclaimer may not apply to you to the extent such jurisdiction’s law is applicable to you and these Terms of Use.",
    TERMS_WARR_8: "12.5 The Carers UK Trading Ltd Parties do not endorse User Content and, to the fullest extent permissible by law, specifically disclaims any responsibility or liability to any person or entity for any loss, damage (whether actual, consequential, punitive or otherwise), injury, claim, liability or other cause of any kind or character based upon or resulting from any User Content.",
    TERMS_LIMIT_1: "13. Limitation of Liability",
    TERMS_LIMIT_2: "13.1 Nothing in these Terms of Use shall limit or exclude our liability for:",
    TERMS_LIMIT_3: "13.1.1 death or personal injury resulting from our negligence;",
    TERMS_LIMIT_4: "13.1.2 fraud or fraudulent misrepresentation; and",
    TERMS_LIMIT_5: "13.1.3 any other liability that cannot be excluded or limited by English law.",
    TERMS_LIMIT_6: "To the fullest extent permissible by law, we accept no liability for:",
    TERMS_LIMIT_7: "13.2.1 any indirect or consequential loss or damage arising in connection with your use of the Jointly Service; or",
    TERMS_LIMIT_8: "13.2.2 any loss of or damage to data (including Patient Data), loss of profit, loss of revenue or loss of business, loss of opportunity, whether direct or indirect in each case, however caused, even if foreseeable.",
    TERMS_LIMIT_9: "The loss or damage referred to above include any related to the Jointly Service",
    TERMS_LIMIT_10: "(a) the User Content",
    TERMS_LIMIT_11: "(b) your use of, inability to use, or the performance of the Jointly Service",
    TERMS_LIMIT_12: "(c) the Patient Data",
    TERMS_LIMIT_13: "(d) any action taken in connection with an investigation by the parties or law enforcement authorities regarding your or any other party’s use of the Jointly Service",
    TERMS_LIMIT_14: "(e) any action taken in connection with copyright or other intellectual property owners",
    TERMS_LIMIT_15: "(f) any errors or omissions in the Jointly Service’s operation",
    TERMS_LIMIT_16: "(g) any action by an Appstore to remove or refuse to process certain information or content",
    TERMS_LIMIT_17: "(h) any damage to any user’s computer, device, or other equipment or technology including, without limitation, damage from any security breach or from any virus, bugs, tampering, fraud, error, omission, interruption, defect, delay in operation or transmission, computer line or network failure or any other technical or other malfunction.",
    TERMS_LIMIT_18: "13.3 If we are liable to you for any reason, our liability will be limited to £100. You are responsible for ensuring that your Device meets all relevant technical specifications necessary to use the App. You also understand that we cannot and do not guarantee or warrant that any material available for downloading from the Website or Appstore websites will be free from infection, viruses and/or other code that has contaminating or destructive properties. You are responsible for implementing sufficient procedures and virus checks (including anti-virus and other security checks) to satisfy your particular requirements for the accuracy of data input and output.",
    TERMS_LIMIT_19: "13.4 We are not responsible for the actions, content, information, or data of third parties, and you release us, our directors, officers, employees, and agents from any claims and damages, known and unknown, arising out of or in any way connected with any claim you have against any such third parties.",
    TERMS_LIMIT_20: "13.5 The limitations and exclusions in this clause do not affect your non-excludable statutory rights and only apply to the extent permitted by applicable law.",
    TERMS_CHANGES_1: "14. Changes to these Terms of Use",
    TERMS_CHANGES_2: "14.1 We reserve the right, at our sole discretion, to change these Terms of Use from time to time. Unless we make a change for legal or administrative reasons, we will endeavour to provide reasonable advance notice before the updated Terms of Use become effective. You agree that we may notify you of the Updated Terms by posting them on the Jointly Service, and that your use of the Jointly Service after the effective date of the updated Terms of Use (or engaging in such other conduct as we may reasonably specify) constitutes your agreement to the updated Terms of Use.",
    TERMS_CHANGES_3: "14.2 The updated Terms of Use may be displayed on-screen and you may be required to read and accept them to continue your use of the Jointly Service. The updated Terms of Use will be effective as of the time of posting, or such later date as may be specified in them, and will apply to your use of the Jointly Service from that point forward. If you do not agree to the updated Terms of Use, you are not permitted to continue to use the App or any other part of the Jointly Service.",
    TERMS_CHANGES_4: "14.3 You should review these Terms of Use regularly (as may be updated from time to time) to ensure that your continued use of the Jointly Service is compliant.",

    TERMS_GEN_1: '15. General',
    TERMS_GEN_2: '15.1 These Terms of Use, its subject matter and its formation, are governed by English law. The courts of England and Wales will have non-exclusive jurisdiction.',
    TERMS_GEN_3: '15.2 If any provision of these Terms of Use is held to be unlawful, void, or for any reason unenforceable, then that provision will be deemed severable from these Terms of Use and will not affect the validity and enforceability of any remaining provisions.',
    TERMS_GEN_4: '15.3 If we fail to insist that you perform any of your obligations under these Terms of Use, or if we do not enforce our rights against you, or if we delay in doing so, that will not mean that we have waived our rights against you and will not mean that you do not have to comply with those obligations. If we do waive a default by you, we will only do so in writing, and that will not mean that we will automatically waive any later default by you.',
    TERMS_GEN_5: '15.4 These Terms of Use were written in English (UK). To the extent any translated version of these Terms of Use conflicts with the English version, the English version controls.',
    TERMS_GEN_6: '15.5 We may transfer our rights and obligations under these Terms of Use to another organisation, but this will not affect your rights or our obligations under these Terms of Use. You may only transfer your rights or obligations under these Terms of Use to another person if we agree in writing.',

    TERMS_COMM_1: '16. Communication between us',
    TERMS_COMM_2: '16.1 If you wish to contact us in writing, or if any condition in these Terms of Use requires you to give us notice in writing, you can send this to us by e-mail or by prepaid post to Carers UK Trading Limited at 20 Great Dover Street, London SE1 4LX and jointlyappsupport@carersuk.org. We will confirm receipt of this by contacting you in writing, normally by email.',
    TERMS_COMM_3: '16.2 If we have to contact you or give you notice in writing, we will do so by email or by pre-paid post to the address you provide to us.',
    TERMS_PRIVACY_1: 'Privacy Policy',
    TERMS_PRIVACY_2: 'Carers UK Trading Ltd recognises that its customers, visitors and users, want to know what’s up with privacy. This Privacy Policy is incorporated into and is subject to the Jointly Service Terms of Use. Your use of the Jointly Service (including the App and Website) and any personal information you provide on the Jointly Service remains subject to the terms of this Privacy Policy and our Terms of Use.',
    TERMS_PRIVACY_3: 'User Provided Information:',
    TERMS_PRIVACY_4: 'Personally Identifiable Information and other information (including Patient Data) provided or uploaded by you, such as email addresses, mobile phone numbers, push notification names (if applicable), billing information (if applicable) and mobile device information, in connection with your use of the Jointly Service.',
    TERMS_PRIVACY_5: 'Patient Data:',
    TERMS_PRIVACY_6: 'Personally Identifiable Information about patients under your care provided or uploaded by you to use with the Jointly Service and App.',
    TERMS_PRIVACY_7: 'Personally Identifiable Information:',
    TERMS_PRIVACY_8: ' any information which relates to and identifies a person, including but not limited to name and address, email address, age, gender, personal interests, date of birth and occupation.',
    TERMS_PRIVACY_9: 'Cookies Information:',
    TERMS_PRIVACY_10: ' When you visit the Website, we may send one or more cookies - a small text file containing a string of alphanumeric characters - to your computer that uniquely identifies your browser.',
    TERMS_PRIVACY_11: 'Log File Information:',
    TERMS_PRIVACY_12: ' When you use the Website, our servers automatically record certain information that your web browser sends whenever you visit any website. These server logs may include information such as your web request, Internet Protocol ("',
    TERMS_PRIVACY_13: 'IP',
    TERMS_PRIVACY_14: '") address, browser type, browser language, referring / exit pages and URLs, platform type, number of clicks, domain names, landing pages, pages viewed and the order of those pages or the amount of time spent on particular pages.',
    TERMS_PRIVACY_15: 'Security',
    TERMS_PRIVACY_16: 'We endeavour to ensure that all User Provided Information is kept confidential and take reasonable steps to protect such information.',
    TERMS_PRIVACY_17: 'We cannot, however, guarantee the security of the User Provided Information as it may be compromised by unauthorised access, hardware or software failure or other relevant factors.',
    TERMS_PRIVACY_18: 'We cannot guarantee the confidentiality of any User Content or User Provided Information transmitted between you, us and other Jointly Service users or members of the general public, as the case may be. We will not be liable to you or anyone else for any loss in connection with any User Content or User Provided Information transmitted made through use of or in connection with the Jointly Service.',
    TERMS_PRIVACY_19: 'Use of information',
    TERMS_PRIVACY_20: 'If you submit Personally Identifiable Information to us through the Jointly Service, then we use your personal information to operate, maintain, and provide to you the features and functionality of the Jointly Service. In particular, your email address is essential to your use of the Jointly Service and will be retained. We may use your email address (and/or mobile number if provided) without further consent for non-marketing or administrative purposes (such as notifying you of major Jointly Service changes or for customer Jointly Service purposes.',
    TERMS_PRIVACY_21: 'We may use cookies and log file information to: (a) remember information so that you will not have to re-enter it during your visit or the next time you use the Jointly Service and (b) monitor individual and aggregate metrics such as total number of visitors, pages viewed, etc.',
    TERMS_PRIVACY_22: 'We do not sell or share your Personally Identifiable Information (such as email address or mobile phone number) with other third-party companies for their commercial or marketing use. We may share your Personally Identifiable Information with third party service providers to the extent that it is reasonably necessary to perform, improve or maintain the Jointly Service.',
    TERMS_PRIVACY_23: 'If you do not agree with our Privacy Policy or Terms of Use, please discontinue use of the Jointly Service; your continued usage of the Jointly Service will signify your assent to and acceptance of our Privacy Policy and Terms of Use.',
    TERMS_PRIVACY_24: 'This Privacy Policy may be revised periodically and this will be reflected by the effective date stated at the top of these Terms of Use. Please revisit this page to stay aware of any changes. Your continued use of the Jointly Site and Jointly Service constitutes your agreement to this Privacy Policy and any amendments.',
    TERMS_PRIVACY_25: 'Carers UK Trading Limited',
    TERMS_PRIVACY_26: 'Registered company in England under number 6034910',
    TERMS_PRIVACY_27: 'Registered office: 20 Great Dover Street, London SE1 4LX',










    USER_GUIDE_H1: 'Welcome to Jointly!',
    USER_GUIDE_Q1: 'How does Jointly work?',
    USER_GUIDE_1A1: 'With Jointly you can create a circle of care for the person you are looking after. Once you have set up your Jointly circle you can start using Jointly to organize care. You can use it on your own or you can invite other people to join you and share the care.',
    USER_GUIDE_1A2: 'You can create a profile with useful information about the person you are looking after; you can post messages, store notes, create task and medication lists and store useful contacts.',
    USER_GUIDE_1A3: 'All members of your Jointly circle can view the content posted on Jointly so make sure to take this into account when you post. If you have some information that you want to only be viewable by certain members of the circle you can put it in a ',
    USER_GUIDE_1A4: 'private category.',
    USER_GUIDE_1A5: "Although most content on Jointly can be edited by all members please note that by default new members can't edit the ",
    USER_GUIDE_1A6: 'Medications',
    USER_GUIDE_1A7: ' page or view private category items unless they have been given the appropriate permissions by a ',
    USER_GUIDE_1A8: 'circle administrator',
    USER_GUIDE_1A9: '. The circle administrator can give (or remove) permission to other members to edit Medications or view private category items. See more about ',
    USER_GUIDE_1A10: 'Permissions.',
    USER_GUIDE_1A11: 'Members of the circle are people who have accepted an invitation to join your Jointly circle (sent by you or another member of your circle).',
    USER_GUIDE_Q2: 'How do I sign up to Jointly?',
    USER_GUIDE_2A1: 'Visit ',
    USER_GUIDE_2A2: ' or download the Jointly app on your iPhone, iPad, or Android device. Click the ',
    USER_GUIDE_2A3: 'Sign up',
    USER_GUIDE_2A4: ' link on the home screen.',
    USER_GUIDE_2A5: 'Complete the form by adding your details and choosing a password and click the ',
    USER_GUIDE_2A6: 'button.',
    USER_GUIDE_2A7: 'The details you provide (excluding your password) will be stored on your circle’s ',
    USER_GUIDE_2A8: 'Contacts',
    USER_GUIDE_2A9: ' page and will be visible to other members of your circle. You can edit your contact details anytime ( ',
    USER_GUIDE_2A10: 'About you',
    USER_GUIDE_2A11: 'Edit Account',
    USER_GUIDE_Q3: 'Log in and Create your Jointly circle',
    USER_GUIDE_3A1: 'Once you create an account you can log in to Jointly. Now you can access a limited menu from where you can learn more about Jointly or change your account details.',
    USER_GUIDE_3A2: 'Jointly works with circles of care. When you purchase and set up your Jointly circle you can invite as many people as you want to share the care with you. Alternatively, you can use Jointly on your own to organise the care around someone. In this case you will still need to buy a Jointly circle.',
    USER_GUIDE_3A3: 'Click ',
    USER_GUIDE_3A4: '+ Create a new circle',
    USER_GUIDE_3A5: ' on the menu to purchase a Jointly circle (one-off payment of £2.99). Complete your details in the form that follows and click ',
    USER_GUIDE_3A6: 'Purchase',
    USER_GUIDE_3A7: '. Once you purchase your Jointly circle you can start using Jointly by filling in some helpful information about the person you are looking after. You can always edit this information later from the ',
    USER_GUIDE_3A8: 'Profile',
    USER_GUIDE_3A9: 'Alternatively you can click ',
    USER_GUIDE_3A10: 'Create a demo circle',
    USER_GUIDE_3A11: ', this will allow you to try Jointly for 14 days and purchase it later for £2.99. Your information will be accessible but you will need to purchase a license in order to enter any new information after the demo period.',
    USER_GUIDE_Q4: 'Redeem Free Access Code',
    USER_GUIDE_4A1: 'If you have received a Free Access Code from your employer or a service provider you can use it to create your Jointly circle for free. You will need to redeem it online , you can do this use the Jointly app on your iPhone, iPad, or Android device or at ',
    USER_GUIDE_4A2: 'Log in to Jointly (if you don’t have an account you will need to Sign up first). After logging in click ‘Create a new circle’ in the menu.',
    USER_GUIDE_4A3: 'Select ‘Use Free Access Code’ from the purchase options',
    USER_GUIDE_4A4: 'Enter some useful information about the person you are caring for',
    USER_GUIDE_4A5: 'On the next page enter your code in the box that appears.',
    USER_GUIDE_Q5: 'Home',
    USER_GUIDE_5A1: 'This is your Home page. Here you can:',
    USER_GUIDE_5A2: 'View upcoming tasks and events',
    USER_GUIDE_5A3: 'View a log of recent activity',
    USER_GUIDE_5A4: 'Invite people into your circle',
    USER_GUIDE_5A5: 'See who else is in your circle',
    USER_GUIDE_5A6: 'View your pending invitations',
    USER_GUIDE_5A7: '(and delete any unwanted pending invitations if you are an administrator)',
    USER_GUIDE_Q6: 'Upcoming tasks and events',
    USER_GUIDE_6A1: 'Here you can view the next 5 upcoming events or tasks which have been assigned a due date. Click on an item to view/edit it or go to the ',
    USER_GUIDE_6A2: 'Calendar',
    USER_GUIDE_6A3: ' or ',
    USER_GUIDE_6A4: 'Tasks',
    USER_GUIDE_6A5: ' sections to view more items.',
    USER_GUIDE_Q7: 'View a log of recent activity',
    USER_GUIDE_7A1: 'Navigate to ',
    USER_GUIDE_7A2: 'Home',
    USER_GUIDE_7A3: ' from the menu. At the bottom of the page there’s a list of recent activities in your Jointly circle.',
    USER_GUIDE_Q8: 'Invite people to join your circle',
    // USER_GUIDE_8A1: 'If you are a circle administrator you can invite others to join your circle. This means that they will be able to view, add to and edit any of the information stored within your Jointly circle.',
    // USER_GUIDE_8A2: 'Because Jointly can be used to store sensitive information about the person you care for we recommend that you protect private and sensitive information and consider carefully whether you give someone access to your circle. All Jointly users are responsible for monitoring the information stored within the circle, whether it is entered or edited by yourself or by other third parties.',
    USER_GUIDE_8A3: 'To invite someone to join the circle:',
    USER_GUIDE_8A3A: 'Web -',
    USER_GUIDE_8A4: ' navigate to Home from the menu. Click the Invite people in this circle button at the top of the page.',
    USER_GUIDE_8A4A: 'Mobile –',
    USER_GUIDE_8A5: ' navigate to Profile from the menu and select ‘Invite members’ at the bottom of this screen.',
    USER_GUIDE_8A6: 'Complete the details of the person you want to invite on the page that follows (you will need to know the email address of that person) and choose what permissions you would like them to have. You can view more information on permissions in ',
    // USER_GUIDE_8A7: 'Invite',
    USER_GUIDE_8A8: 'They will receive an email inviting them to join you on Jointly. Once you are happy with the details you provided click the Invite button (double check that you have typed the email address correctly). Until the person accepts your invitation you will be able to see its status in your ',
    USER_GUIDE_8A9: 'Pending invitations',
    USER_GUIDE_8A10: 'The person that you invite will not be able to invite additional members unless you select them as a ',
    USER_GUIDE_8A11: 'circle administrator',
    USER_GUIDE_8A12: 'either when inviting them or in ',
    USER_GUIDE_8A13: 'circle settings',
    USER_GUIDE_Q9: 'Delete a pending invitation',
    USER_GUIDE_9A1: 'You will need to be a ',
    USER_GUIDE_9A2: 'circle administrator',
    USER_GUIDE_9A3: 'to delete a pending invitation. If you are not a circle administrator please ask your administrator(s) to delete the invitation.',
    USER_GUIDE_Q10: 'I have invited someone but they haven’t received my invitation',
    USER_GUIDE_10A1: 'Make sure you have entered the correct information in the invitation form. You can see this in your ',
    USER_GUIDE_10A2: 'Pending invitations',
    USER_GUIDE_10A3: 'If they do not receive this invitation then please ask them to check their spam or bulk mail folder or settings. You can resend or delete invitations by selecting the icons to the right of each invitation.',
    USER_GUIDE_10A4: 'If the person registers a new account at ',
    USER_GUIDE_10A5: " using the email address you sent the invitation to then they will be able to accept the invitation from their notifications window in the top right of the screen when they first log in (they don't need to do it via the invitation message).",
    USER_GUIDE_10A6: 'If you have any further problems please contact ',
    USER_GUIDE_Q11: 'See who is in your circle',
    USER_GUIDE_11A1: 'You can view the members of your circle in ',
    USER_GUIDE_11A2: 'Contacts',
    USER_GUIDE_11A3: ' or by going to ',
    USER_GUIDE_11A4: 'Circle settings',
    USER_GUIDE_11A5: 'If you are administrator you can add or remove members and grant permission for members to view private items in Jointly (items assigned to ', 
    USER_GUIDE_11A5A: 'categories that have been marked private)',
    USER_GUIDE_11A6: 'Grant permission to other members of the circle to view private items (items assigned to categories that have been marked private)',
    USER_GUIDE_Q12: 'Profile',
    USER_GUIDE_Q12A: 'Please note that all members of your circle can view this information (except for fields marked private) but only circle administrators can update it. Use your common sense about what data you can share with the members of your circle.',
    USER_GUIDE_Q12B: "Additional profile information can be stored within the 'Additional info' tab.",
    USER_GUIDE_Q12C: "We have provided some examples of the type of information that may be useful for you to store in Jointly. You can move (web only) or delete any of these fields, or create your own fields to customise Jointly so that it's better suited to your caring situation.",
    USER_GUIDE_Q12D: "If you want to restrict access to a field then you can mark it 'Private' using the lock icon and choose which members can access",
    USER_GUIDE_Q12DA:"private category information ",
    USER_GUIDE_Q12DB:"in",
    USER_GUIDE_Q13: 'Messages',
    USER_GUIDE_13A1: 'Navigate to ',
    USER_GUIDE_13A2: 'Messages',
    USER_GUIDE_13A3: ' from the menu. Much like text messaging write your message in the box at the bottom of the page and press ',
    USER_GUIDE_13A4: 'Send',
    USER_GUIDE_13A5: ' to post it. All other members of your Jointly circle will be able to view the messages you post. You can also post images by clicking ',
    USER_GUIDE_13A6: 'Upload a picture',
    USER_GUIDE_13A7: '. Take a photo or attach one of your existing images. Once the desired image is selected press ',
    USER_GUIDE_13A8: 'Send',
    USER_GUIDE_13A9: 'View all messages posted by members of your Jointly circle by scrolling up (newest) or down (oldest). You can see who posted what and when by the name tag on top of the messages.',
    USER_GUIDE_13A10: 'Messages cannot be edited after they’ve been sent but can be deleted from here by clicking the bin icon. When messages are deleted they show as deleted for all members of the circle. A placeholder remains in the message feed to show that something has been deleted.',
    USER_GUIDE_Q14: "Notes",
    USER_GUIDE_14A1: "Navigate to Notes from the menu. Use this section to create and organize notes or store images, the Notes section is handy for longer text notes or information that you would like to categorise so that it’s easy to find later (e.g. to create a health log or store bills to be paid).",
    USER_GUIDE_14A2: "When you create a new note you can click the Category dropdown to assign it to an existing category or create a new category. You can also create new categories in advance by selecting Edit Categories",
    USER_GUIDE_14A3: "In the Notes section you can click the filter button at the top right (funnel icon) to view only the notes that have been assigned a particular category, or you can select multiple category icons and this can be useful to compare different types of information. To view the full list of notes again click on the remove category icon (funnel with an x on it)",
    USER_GUIDE_14A4: "Private notes categories",
    USER_GUIDE_14A5: "To hide these notes from certain members of the circle, go to Circle Settings and uncheck the box Can see private notes.",
    USER_GUIDE_14A6: "Please remember that circle administrators can view all private notes and choose which other members of the circle can view them.",
    USER_GUIDE_Q15: "Tasks and task lists",
    USER_GUIDE_15A1: "You can create tasks and task lists and monitor their status.",
    USER_GUIDE_15A2: "Navigate to ",
    USER_GUIDE_15A3: "Tasks",
    USER_GUIDE_15A4: " from the menu. On this page you can view a summary of all tasks. They are divided into Tasks (for stand alone tasks, for example: ",
    USER_GUIDE_15A5: "Pick up Mum’s medication",
    USER_GUIDE_15A6: ") and Task lists (for tasks that belong to a list, for example: ",
    USER_GUIDE_15A7: "Mum’s weekly groceries list -",
    USER_GUIDE_15A8: "under which you can store more items such as ",
    USER_GUIDE_15A9: "Buy milk, dog food, etc.",
    USER_GUIDE_15A10: "Create a task or task list",
    USER_GUIDE_15A11: "Navigate to ",
    USER_GUIDE_15A12: "Tasks",
    USER_GUIDE_15A13: " from the menu. Click the ",
    USER_GUIDE_15A14: "Create a new task",
    USER_GUIDE_15A15: " button. Give your task a title (such as ",
    USER_GUIDE_15A16: "Pick up Mum’s medication",
    USER_GUIDE_15A17: "You can assign the task to a member of your Jointly circle including yourself, or leave it as ",
    USER_GUIDE_15A18: "Unassigned",
    USER_GUIDE_15A19: " for someone else to pick up.",
    USER_GUIDE_15A20: "Set a due date if required, tasks with due dates will show up on that day in the calendar.*",
    USER_GUIDE_15A21: "If your task is part of a list (for example Mum’s weekly groceries list) you can:",
    USER_GUIDE_15A22: "Add it to an existing list by selecting the relevant one.",
    USER_GUIDE_15A23: "Add it to a new list by typing your list’s title in the box provided.",
    USER_GUIDE_15A24: "Click ",
    USER_GUIDE_15A25: "Save",
    USER_GUIDE_15A26: "*Note",
    USER_GUIDE_15A27: " If your task is time specific (such as ",
    USER_GUIDE_15A28: "Drive mum to doctor on Tuesday 5 Nov at 10 am",
    USER_GUIDE_15A29: ") we recommend that you create a ",
    USER_GUIDE_15A30: "calendar entry",
    USER_GUIDE_15A31: " instead of a task.",
    USER_GUIDE_15A32: "Edit tasks",
    USER_GUIDE_15A33: "Navigate to ",
    USER_GUIDE_15A34: "Tasks",
    USER_GUIDE_15A35: " from the menu.",
    USER_GUIDE_15A36: "Each task has a tick icon (left side) to indicate whether it has been completed or not.",
    USER_GUIDE_15A37: "To the right side of the task title the name indicates which circle member the task is assigned to.",
    USER_GUIDE_15A38: "Click on a task to view more options.",
    USER_GUIDE_15A39: "Click ",
    USER_GUIDE_15A40: "Complete this task",
    USER_GUIDE_15A41: " to mark a task as completed. The task will remain visible with a ",
    USER_GUIDE_15A42: "strikethrough",
    USER_GUIDE_15A43: " in the Tasks page to indicate that it has been completed. You can ",
    USER_GUIDE_15A44: "Reopen",
    USER_GUIDE_15A45: " the task if you wish.",
    USER_GUIDE_15A46: "If you want to completely remove the task click ",
    USER_GUIDE_15A47: "Delete",
    USER_GUIDE_15A48: "Click",
    USER_GUIDE_15A49: "Edit",
    USER_GUIDE_15A50: " to make changes to the task (you can change the task title, who is responsible for it or add it to a list). Click",
    USER_GUIDE_15A51: "Save",
    USER_GUIDE_15A52: " when you make changes.",
    USER_GUIDE_15A53: "You can also post a comment related to a specific task by typing your message in the box provided. Click ",
    USER_GUIDE_15A54: "Post",
    USER_GUIDE_15A55: " to send your comment.",
    USER_GUIDE_15A56: "Edit Task Lists",
    USER_GUIDE_15A57: "Each task list has two numbers in a circle (right side). The ",
    USER_GUIDE_15A58: "grey",
    USER_GUIDE_15A59: " circle indicates the number of completed items in the list and the ",
    USER_GUIDE_15A60: "black",
    USER_GUIDE_15A61: " circle indicates the number of open/pending/to-do items in the list.",
    USER_GUIDE_15A62: "Click on a task list title for more options. You can:",
    USER_GUIDE_15A63: "Add a new task",
    USER_GUIDE_15A64: "Click on an existing task to edit it (edit task information, mark task as completed, change who is the task assigned to, delete a task or post a comment).",
    USER_GUIDE_15A65: "Reorder tasks to indicate priority",
    USER_GUIDE_15A66: "Remember to click ",
    USER_GUIDE_15A67: "Save",
    USER_GUIDE_15A68: " when you add or edit entries.",

    USER_GUIDE_Q16: "Calendar",
    USER_GUIDE_16A1: "View events by calendar month (Monthview). On the mobile app the items are colour coded.",
    USER_GUIDE_16A2: "A blue number indicates today’s date",
    USER_GUIDE_16A3: "A large blue circle indicates the currently selected date. The list below will show all events occuring after this date",
    USER_GUIDE_16A4: "A blue dot indicates that there is an event occurring on that date",
    USER_GUIDE_16A5: "A black dot indicates that someone is marked as unavailable on that date",
    USER_GUIDE_16A6: "The ",
    USER_GUIDE_16A7: "blue line",
    USER_GUIDE_16A8: " underneath the horizontal week view expands this calendar to fill the whole screen",
    USER_GUIDE_16A9: "How to create an event",
    USER_GUIDE_16A10: "Click the ",
    USER_GUIDE_16A11: "Create a new event",
    USER_GUIDE_16A12: " button on the Calendar page.",
    USER_GUIDE_16A13: "Give your event a title (e.g. Doctor’s appointment)",
    USER_GUIDE_16A14: "Enter the event location (e.g. Long Lane Surgery, 20 Long Lane, London)",
    USER_GUIDE_16A15: "Choose your event’s date",
    USER_GUIDE_16A16: "Choose your event’s start and end time",
    USER_GUIDE_16A17: "Choose which member of your circle you want to invite to attend (tick all people that you would like to receive an email invitation to this event)",
    USER_GUIDE_16A18: "You can also invite people that don’t belong to your Jointly circle by adding their emails in the box provided.",
    USER_GUIDE_16A19: "Click",
    USER_GUIDE_16A20: "Save",
    USER_GUIDE_16A21: " to create your event.",
    USER_GUIDE_16A22: "Creating repeat events",
    USER_GUIDE_16A23: "If you have an event that repeats every day, week or month you can create a series of events in one go by clicking on the ",
    USER_GUIDE_16A24: "Repeat",
    USER_GUIDE_16A25: " checkbox when creating the event. Repeat events will be created up to the date you enter in ",
    USER_GUIDE_16A26: "Repeat Until",
    USER_GUIDE_16A27: "Please note that if you set a monthly event to repeat on a day that isn’t in a particular month (e.g. on the 31st to repeat in June) then the event will be set to occur on the last day of that month instead.",
    USER_GUIDE_16A28: "Duplicating events",
    USER_GUIDE_16A29: "You can duplicate existing calendar events to create additional events containing similar information.",
    USER_GUIDE_16A30: "Click on an event and then click ",
    USER_GUIDE_16A31: "Duplicate",
    USER_GUIDE_16A32: ". Simply change the date, time, etc. and click ",
    USER_GUIDE_16A33: "Save",
    USER_GUIDE_16A34: " to create a new event. The original event will remain unchanged.",
    USER_GUIDE_16A35: "How to edit/delete an event",
    USER_GUIDE_16A36: "On the ",
    USER_GUIDE_16A37: "Calendar",
    USER_GUIDE_16A38: " page (List view or Month view) click on the event you wish to edit",
    USER_GUIDE_16A39: "Click",
    USER_GUIDE_16A40: "Edit",
    USER_GUIDE_16A41: " if you want to change the event information (such as the event’s location, date and time or people invited to the event). Remember to ",
    USER_GUIDE_16A42: "Save",
    USER_GUIDE_16A43: " your changes.",
    USER_GUIDE_16A44: "Click",
    USER_GUIDE_16A45: "Delete",
    USER_GUIDE_16A46: " if you want to remove the event from your calendar.",
    USER_GUIDE_16A47: "You can post a comment related to this event by using the box provided.",
    USER_GUIDE_16A48: "Editing, duplicating ",
    USER_GUIDE_16A49: " deleting repeat events",
    USER_GUIDE_16A50: "When you edit or delete a Repeat event you will be given the option of deleting just that individual event or every future event in the series.",
    USER_GUIDE_16A51: "Alternatively you can delete the whole series and create another to replace it.",
    USER_GUIDE_Q17: "Calendar sync",
    USER_GUIDE_17A1: "On some mobile devices you can synchronise your Google or iCloud calendar with your Jointly calendar. This feature is only supported for devices that only have a single Google or iCloud calendar set up on them.",
    USER_GUIDE_17A2: "Changes that you make to either calendar will be updated on the other, although there may be a short delay while the calendars synchronise.",
    USER_GUIDE_17A3: "You can enable this in Circle settings (tap on the image of the circle at the top of the menu), go to the Members and Settings tab and switch on ‘Sync circle calendar’. It may take a while for your calendar items to synchronise.",
    USER_GUIDE_17A4: "You can disable calendar synchronisation by switching this off, but items from your Jointly calendar will still remain in your cloud calendar and will need to be manually deleted afterwards.",
    USER_GUIDE_Q18: "Medications",
    USER_GUIDE_18A1: "Navigate to ",
    USER_GUIDE_18A2: "Medications",
    USER_GUIDE_18A3: " from the menu. On this page you can:",
    USER_GUIDE_18A4: "Create an entry for a medication ( ",
    USER_GUIDE_18A5: "if you are circle administrator or have relevant permission",
    USER_GUIDE_18A6: "See today’s medication with an indication of the time it should be taken as well as the dose ( ",
    USER_GUIDE_18A7: "Today",
    USER_GUIDE_18A8: "view).",
    USER_GUIDE_18A9: "See all current and past medication in a list ( ",
    USER_GUIDE_18A10: "All medications",
    USER_GUIDE_18A11: " view)",
    USER_GUIDE_18A12: "How to create a new medication entry",
    USER_GUIDE_18A13: "You need to be an administrator or have permission to create a medication entry",
    USER_GUIDE_18A14: "Click on the ",
    USER_GUIDE_18A15: "Create a new medication",
    USER_GUIDE_18A16: " button",
    USER_GUIDE_18A17: "Enter the medication name",
    USER_GUIDE_18A18: "You can add an image of the medication if you wish by clicking ",
    USER_GUIDE_18A19: "Choose a picture",
    USER_GUIDE_18A20: "Enter the condition for which the medication is taken",
    USER_GUIDE_18A21: "Choose from the list how often the medication should be taken (daily, weekly, etc.)",
    USER_GUIDE_18A22: "Choose the time and the dose (add another time and dose if the medication should be taken more than one time a day)",
    USER_GUIDE_18A23: "Select Current or Past to indicate if this is a current or past medication",
    USER_GUIDE_18A24: "Select the start date (and optionally the end date)",
    USER_GUIDE_18A24A: "Add a location to indicate where the medication is stored. New locations can be entered or you can select from previously created locations.",
    USER_GUIDE_18A24B: "Add notes in the boxes provided (optional)",
    USER_GUIDE_18A25: "Add a note in the box provided (optional)",
    USER_GUIDE_18A26: "Click ",
    USER_GUIDE_18A27: "Save",
    USER_GUIDE_18A28: " to create your entry",
    USER_GUIDE_18A29: "How to edit a medication",
    USER_GUIDE_18A30: "(You need to be an administrator or have permission to edit a medication)",
    USER_GUIDE_18A31: "On the ",
    USER_GUIDE_18A32: "Medications",
    USER_GUIDE_18A33: " page (Today view or All medications view) click on the medication you wish to edit.",
    USER_GUIDE_18A34: "Click ",
    USER_GUIDE_18A35: "Edit",
    USER_GUIDE_18A36: " if you want to change the medication information (such as the medication’s name, frequency, time, dose, etc.). Remember to ",
    USER_GUIDE_18A37: "Save",
    USER_GUIDE_18A38: " your changes.",
    USER_GUIDE_18A39: "Click ",
    USER_GUIDE_18A40: "Delete",
    USER_GUIDE_18A41: " if you want to remove the medication.",
    USER_GUIDE_18A42: "You can also post a comment related to this medication by using the box provided.",





    USER_GUIDE_Q50: "Categories and filters",
    USER_GUIDE_50A1: "When you create or edit a note, event, task or contact you can click the Category dropdown to assign it to an existing category or create a new category. You can also create new categories in advance by selecting Edit Categories.",
    USER_GUIDE_50A2: "In these sections you can click the filter button at the top right (funnel icon) to view only the items that have been assigned a particular category, or you can view multiple categories in the same list - this can be useful to compare different types of information. To view the full list of items again, click on the remove category icon (funnel icon with an x on it).",

    USER_GUIDE_Q51: "Private categories",
    USER_GUIDE_51A1: "You can restrict access to data stored in notes, events, tasks and contacts so that they can only be viewed by selected members of your circle by assigning them to a private category.",
    USER_GUIDE_51A2: "Mark a category as private when creating or editing it by checking the 'Is this private' box. Any items assigned to a private category can then only be viewed by circle administrators or members who have been granted access to private categories in ",
    USER_GUIDE_51A3: "Private category items will be hidden from unauthorised members. For events a placeholder marked 'private item' will be visible to these members so that they know something is booked at that date/time, but they will not be able to view any further details of these items.",
    USER_GUIDE_51A4: "Unauthorised members will not see updates to private category items in their activity feed or notifications.",
    USER_GUIDE_51A5: "Please remember that circle administrators can view all private category items and choose which other members of the circle can view them.",





    USER_GUIDE_Q19: "Devices",
    USER_GUIDE_19A1: "Jointly can connect with certain health and monitoring devices so that information from them can be stored and shared with members of the circle. We will display information about the devices that can connect to Jointly within the Circle settings > Devices section",
    USER_GUIDE_19A2: "You can choose whether or not to receive app or email notifications for a device in ",
    USER_GUIDE_19A3: "Notification settings.",
    USER_GUIDE_Q20: "Contacts",
    USER_GUIDE_20A1: "Navigate to ",
    USER_GUIDE_20A2: "Contacts",
    USER_GUIDE_20A3: " from the menu. On this page you can:",
    USER_GUIDE_20A4: "Access your list of contacts (this includes both members of your circle and other useful contacts stored).",
    USER_GUIDE_20A5: "Create a new contact",
    USER_GUIDE_20A6: "On the Contacts page click on the ",
    USER_GUIDE_20A7: " button",
    USER_GUIDE_20A8: "Add the contact’s details and if you wish enter a note in the box provided. Remember that for some contacts adding address details may be useful. For example if you are entering the details of a GP it may save time and effort the next time you or another member of your circle needs to go there to make a note of the address.",
    USER_GUIDE_20A8A: "You can also set a contact as an 'Emergency contact (ICE)' and they will appear with a red phone icon next to them on the contacts list.",
    USER_GUIDE_20A9: "Click ",
    USER_GUIDE_20A10: "Save",
    USER_GUIDE_20A11: " to create the contact",
    USER_GUIDE_20A12: "View/Edit a contact",
    USER_GUIDE_20A13: "On the Contacts page you have a full list of your contacts. Scroll up or down to locate a contact.",
    USER_GUIDE_20A14: "Click on the contact to edit it. In the screen that follows:",
    USER_GUIDE_20A15: "Click ",
    USER_GUIDE_20A16: "Edit",
    USER_GUIDE_20A17: " to make changes to the contacts details. Remember to ",
    USER_GUIDE_20A18: "Save",
    USER_GUIDE_20A19: " your changes.",
    USER_GUIDE_20A20: "Click ",
    USER_GUIDE_20A21: "Delete",
    USER_GUIDE_20A22: " if you want to remove the contact from your Jointly circle’s contacts list. ",
    USER_GUIDE_20A23: "Remember",
    USER_GUIDE_20A24: " deleting a contact removes the contact completely from your Jointly circle’s contact list. Neither you nor other members of your circle will be able to access this information again.",
    USER_GUIDE_20A25: "Note",
    USER_GUIDE_20A26: "that you cannot change the contact details of another member of your Jointly circle. If you notice any incorrect or incomplete information contact the person so they can edit their personal information.",

    USER_GUIDE_Q21: "Circle settings",
    USER_GUIDE_21A1A: "To access circle settings:",
    USER_GUIDE_21A1B: "Web – click the cog icon next to the circle name at the top of your left-hand menu",
    USER_GUIDE_21A1C: "Mobile – click Profile and select the Members tab",
    USER_GUIDE_21A1: "You need to be a circle administrator to be able to manage a Jointly circle’s settings. An administrator can:",
    USER_GUIDE_21A2: "Invite new members and remove members from the circle",
    USER_GUIDE_21A3: "Make members of a circle ",
    USER_GUIDE_21A4: "active",
    USER_GUIDE_21A5: " or ",
    USER_GUIDE_21A6: "inactive",
    USER_GUIDE_21A7: ". If you make a member inactive it means that they will no longer be part of your circle. You will no longer be able to assign them Tasks or invite them to Events and they won’t receive any notification of circle activity.",
    USER_GUIDE_21A8: "Grant permission to other members of this circle to be Administrators",
    USER_GUIDE_21A9: "Grant permission to other members of the circle to add/edit Medications",
    USER_GUIDE_21A10: "Grant permission to other members of the circle to view private category ",
    USER_GUIDE_21A10A:"private category",
    USER_GUIDE_21A10B:"items (Note, Event, Task categories or Profile fields that have been set private)",
    USER_GUIDE_21A11: "Leave the circle yourself",
    USER_GUIDE_21A12: "Delete the circle",
    USER_GUIDE_21A13: "If you wish to prevent someone from editing medications, viewing private items or removing people from the circle then please also make sure that ‘is circle administrator’ is also unchecked.",

    USER_GUIDE_Q211: "Deleting your circle",
    USER_GUIDE_211A: "Most information within Jointly is stored within circles. All other members must have left or been removed before a circle can be deleted. To delete the circle, go to ",
    USER_GUIDE_211B: "Web – remove yourself from the circle.",
    USER_GUIDE_211BA: "Mobile – click the ‘Delete circle’ button – this button only appears when you are the only remaining member of the circle.",
    USER_GUIDE_211C: "If you wish to delete your account after deleting your circle(s) go to ‘Edit/delete account’ in Account settings.",


    USER_GUIDE_Q22: "Switch between Jointly circles",
    USER_GUIDE_22A1: "If you have more than one Jointly circle switching between your circles is easy!",
    USER_GUIDE_22A2: "From the menu scroll down to ",
    USER_GUIDE_22A3: "Your Jointly circles",
    USER_GUIDE_22A4: ". Below it are listed in alphabetical order the names of the people you are looking after.",
    USER_GUIDE_22A5: "Click on the name of the person whose Jointly circle you want to open (the name of the circle you are currently in is indicated by red font).",
    USER_GUIDE_22A6: "Repeat the above steps to switch back to another circle.",

    USER_GUIDE_Q23: "Account settings / delete your account",
    USER_GUIDE_23A1: "In this section you can edit your contact information (this is viewable by other members of your circle in ",
    USER_GUIDE_23A1A:"), change your password,  ",
    USER_GUIDE_23A1B:"export data ",
    USER_GUIDE_23A1C:"or delete your account.",
    USER_GUIDE_23A2: "You must delete your circle if you wish to remove all of the information stored in Jointly (see ",
    USER_GUIDE_23A3: "Deleting your circle",
    USER_GUIDE_23A4: "). You must leave or delete any active circles before you are able to delete your user account.",


    USER_GUIDE_Q52: "Export your data or contingency plan",
    USER_GUIDE_52A1: "Jointly data can be exported at any time in Word, PDF, Text or CSV formats. You must be a circle administrator to export data from a circle.",
    USER_GUIDE_52A2: "It is possible to export all data within Jointly or export a contingency plan containing a set of essential information that you can share with someone in case of emergency.",
    // USER_GUIDE_52A3: "You can also choose to include images or attachments in your export. The filenames of attachments in Jointly will appear next to each item in your text export document(s).",
    USER_GUIDE_52A4: "To export your data, go to Account settings > Export your data and select the options to choose what want to export. Clicking the Export button will send a web link to the email address that your Jointly account is registered to. After clicking on the link, you must enter your Jointly password.",
    USER_GUIDE_52A5: "Select circle – single or multiple circles can be selected in Full/custom export.",
    USER_GUIDE_52A6: "Include private category data – if checked this will include any information that you have set as private within Jointly (items allocated to ",
    USER_GUIDE_52A6A:"fields that have been set as private).",
    USER_GUIDE_52A6B:"or",
    USER_GUIDE_52A7: "Include image/document attachments – this will include any images or documents relating to the items you have exported. These are stored in separate folders within your export file and the filenames are shown in your export document.",
    USER_GUIDE_52A8: "You can also choose to only download items assigned to specific categories from the sections where this applies or specify a date range to export only some of your data.",
    USER_GUIDE_52A9: "The exported data will download as a zip file. If you selected Word, PDF or Text export options then all requested data in your circle will be exported as a single document. If you selected the CSV export option then each section (e.g. Notes, Tasks, etc.) will be exported as a separate CSV file.",
    USER_GUIDE_52A10: "We cannot protect data that you export from Jointly. Please ensure that any sensitive information that you download is stored securely.",
    


    USER_GUIDE_Q24: "Log out of Jointly",
    USER_GUIDE_24A1: "From the menu scroll down to ",
    USER_GUIDE_24A2: "About you",
    USER_GUIDE_24A3: "Click ",
    USER_GUIDE_24A4: "Log out",
    USER_GUIDE_24A5: " to leave Jointly. You need to remember your log in information to log back to Jointly. If you are using Jointly on a personal smartphone or personal home, work or portable device you can remain logged in and always access Jointly at a click.",

    USER_GUIDE_Q25: "Configuring notifications",
    USER_GUIDE_25A1: "Jointly can send you emails or app notifications (also known as ",
    USER_GUIDE_25A2: "push notifications",
    USER_GUIDE_25A3: ") when certain information is updated or when events are due. You can use this section to choose which notifications you receive by checking or unchecking the boxes.",
    USER_GUIDE_25A3A: "Notifications will also be shown in the notifications window at the top right of the app. Notifications in this area follow the same settings that you use for app/push notifications.",
    USER_GUIDE_25A4: "Jointly can also email you an ",
    USER_GUIDE_25A5: "email digest",
    USER_GUIDE_25A6: " containing a list of all of the information that has been updated in your circle the previous day or week. Please check the boxes if you would like to receive these. Digests are sent at 9am each day and the weekly digest is sent at 9am each Monday morning.",

    USER_GUIDE_Q26: "Report something",
    USER_GUIDE_26A1: "If you are experiencing a technical problem or have a technical query please contact: ",
    USER_GUIDE_26A2: "This ",
    USER_GUIDE_26A3: "Jointly user guide",
    USER_GUIDE_26A4: " and the ",
    USER_GUIDE_26A5: " sections cover many topics. Have a look, you may find what you are looking for.",
    USER_GUIDE_26A6: "If you have a general query/suggestion please contact ",
    USER_GUIDE_26A7: "",





    FAQ_HEADING: "Jointly FAQs",
    FAQ_Q1: "What is Jointly?",
    FAQ_1A1: "Jointly is a mobile and online application created by carers for carers.",
    FAQ_1A2: "It is designed to make caring a little easier, less stressful and a lot more organised. It combines group messaging with other useful features including to-do and medication lists, calendar and more! Use it with others or on your own.",
    FAQ_Q2: "On which devices can I use Jointly?",
    FAQ_2A1: "You can access Jointly on the following devices:",
    FAQ_2A2: "iPhone and iPad, iOS version 9.0 and above",
    FAQ_2A3: "Android devices, version 5.0 and above.",
    FAQ_2A4: "Most modern web browsers (home computers, laptops, smartphones, tablets, etc.) at ",
    FAQ_2A5: "Once you create a Jointly account you can use it to log in to Jointly on any device you choose. Download the Jointly app on your iPhone, iPad, Android phone or tablet or visit ",
    FAQ_2A6: " from any other tablet, mobile phone, laptop or home computer.",
    FAQ_Q3: "How do I use Jointly?",
    FAQ_3A1: "Please see the ",
    FAQ_3A2: "Jointly user guide",
    FAQ_3A3: " for more details on how to use Jointly, including how to sign up, invite other users in your circle and how to manage, edit and delete information.",
    FAQ_3A4: "Please note that all members of your circle can view the information shared on Jointly. Use your common sense about what information you can share with the members of your circle.",
    FAQ_Q4: "Is Jointly free?",
    FAQ_4A1: "You can visit ",
    FAQ_4A2: " or download the app for free and access the Jointly video, Jointly user guide and Jointly FAQs in order to find out more about Jointly.",
    FAQ_4A3: "Jointly works with circles of care. You can purchase a Jointly circle with an one-off payment of £2.99 either through the Apple or Google Play stores or at ",
    FAQ_4A4: "Once you have purchased a Jointly circle for the person you are looking after you can invite as many people as you want to share the care with you. There is no additional cost for you or the people you invite to join you. Alternatively, you can use Jointly on your own to the care around someone. In this case you will still need to buy a Jointly circle.",
    FAQ_4A5: "Note: Some employers and service providers are offering Jointly. In this case you can ask for a Jointly voucher code which will allow you to use Jointly for free.",
    FAQ_Q5: "Why am I being asked to pay for Jointly?",
    FAQ_5A1: "Jointly is developed by	",
    FAQ_5A2: "Carers UK",
    FAQ_5A3: ", a charity set up to help the millions of people who look after an older, disabled or ill family member or friend. We do so by campaigning and by providing advice, information and support to carers. Our goal is to make carers’ lives easier, which is why we developed Jointly. We need to attach a small fee to Jointly to meet operational and maintenance costs.",
    FAQ_Q6: "I have received a free access code for Jointly from an organisation such as a local authority, health or care provider or my employer. Do I still need to buy Jointly?",
    FAQ_6A1: "No, if you have received a code for Jointly that means that an organisation has purchased a Jointly circle for you. You can redeem the code at ",
    FAQ_6A2: " and start using Jointly online or on your mobile. An access code can only be redeemed once per Jointly account, if you are caring for more than one person and would like to create more than one Jointly circle you can either ask your employer or service provider to allocate you with a new code or you can purchase a Jointly circle yourself (one off cost of £2.99).",
    FAQ_Q7: "How can I redeem a Jointly access code for a new circle?",
    FAQ_7A1: "If you have received a Free Access Code from your employer or service provider then this can be used to redeem a free Jointly circle.",
    FAQ_7A2: "If you don’t have an account with Jointly use the sign up form to create an account. If you already have a Jointly account, log in to your account.",
    FAQ_7A3: "You will be prompted to create a new circle when you first log in to Jointly. You can start creating your circle by filling in some helpful information about the person you are looking after. You can always edit this information later from the Profile.",
    FAQ_7A4: "When you create your circle please use the 'Free Access Code' option and enter the code that your employer or service provider has given you.",
    FAQ_Q8: "How can I redeem a Jointly access code for a demo circle?",
    FAQ_8A1: "If you have already created a demo circle and would like to redeem a Free Access Code to unlock it then please log in to Jointly, open the circle settings by clicking the cog icon at the top of your menu and click ",
    FAQ_8A2: "'Unlock this circle'",
    FAQ_8A3: " at the bottom of the screen.",
    FAQ_8A4: "Enter your code in the box that appears and click Redeem.",
    FAQ_Q9: "I have invited someone to join my circle but they haven’t received the invitation email.",
    FAQ_9A1: "Make sure you have entered the correct information in the invitation form. You can see this in your Pending invitations and you can also resend invitations from here. Please ask them to check that your invitation email hasn't been incorrectly blocked as spam.",
    FAQ_9A2: "If the person registers a new account at ",
    FAQ_9A4: " or the mobile app using the email address you sent the invitation to then they will be able to accept the invitation when they first log in.",
    FAQ_9A5: "If you have any further problems please contact",
    FAQ_Q10: "I can’t download the app to my Android/iOS device",
    FAQ_10A1: "The Jointly mobile app requires Android version 5.0 or later or iOS version 9.0 or later. If you are running an older version and the app store doesn’t let you download the app then please either update your phone software or use the web version at ",
    FAQ_10A2: "The web version of Jointly has the same functionality as the app version.",
    FAQ_Q11: "I am looking after more than one person. Can I have two or more Jointly circles of care?",
    FAQ_11A1: "Yes, you can create two or more circles of care. You can purchase a new circle (at £2.99) by clicking ",
    FAQ_11A2: "+ Create a circle",
    FAQ_11A3: " on the menu.",
    FAQ_Q12: "I forgot my user name or password. What do I do?",
    FAQ_12A1: "If you forgot your password visit ",
    FAQ_12A2: " or open the Jointly app on your iPhone, iPad, Android phone or tablet. On the Log in screen click Forgot your password? In the screen that follows enter your email address. We will send you an email; follow the link provided in the email to reset your password. Now you can use your new password to log in.",
    FAQ_12A3: "If you forgot your user name:",
    FAQ_12A4: "Your user name is your email address. If you recently amended your account settings including changing your email address you will need to use the new email address to log in.",
    FAQ_12A5: "If you have troubles logging in contact:	",
    FAQ_Q13: "Does Jointly work offline?",
    FAQ_13A1: "You can open the Jointly app and view its content when you are offline but you will not be able to make changes (add, edit or delete content) while you are offline.",
    FAQ_13A2: "Offline data may not be available if you have been offline for a while or if your device has cleared Jointly's temporary storage. Messages are not available offline. Please note that the data on Jointly may be out of date if your device hasn’t synced with the server recently.",
    FAQ_Q14: "Data protection/storage",
    FAQ_14A1: "All your data is transmitted via an encrypted connection to a secure server. Should the service cease, your data will be made available for download in .cvs format.",
    FAQ_Q15: "I am experiencing a technical problem. Who can I contact?",
    FAQ_15A1: "If you are experiencing a technical problem please contact	",
    FAQ_Q16: "How do I delete my Jointly circle and account?",
    FAQ_16A1: "Please see the user guide sections ‘Circle settings and deleting your circle’ and ‘Edit/delete Account’. Please note that you must delete/leave any circles before you can delete your Jointly user account.",
    FAQ_Q17: "I have a suggestion/feedback. Who can I contact?",
    FAQ_17A1: "If you would like to make a suggestion or send feedback, please contact	",
    FAQ_17A2: "",



    UNKNOWN_USER: "Unknown user",
    UNKNOWN_TASK: "Unknown task",
    UNKNOWN_EVENT: "Unknown event",
    UNKNOWN: "Unknown",
    UNKNOWN_CIRCLE: "Unknown circle",
    UNKNOWN_MED: "Unknown medication",
    UNKNOWN_SERVICE: "Unknown service",
    UNKNOWN_NOTE: "Unknown note",



    DAYS_MON: "Mon",
    DAYS_TUE: "Tue",
    DAYS_WED: "Wed",
    DAYS_THUR: "Thu",
    DAYS_FRI: "Fri",
    DAYS_SAT: "Sat",
    DAYS_SUN: "Sun",


    MONTH_JANUARY: "January",
    MONTH_FEBRYARY: "February",
    MONTH_MARCH: "March",
    MONTH_APRIL: "April",
    MONTH_MAY: "May",
    MONTH_JUNE: "June",
    MONTH_JULY: "July",
    MONTH_AUGUEST: "August",
    MONTH_SEPTEMBER: "September",
    MONTH_OCTOBER: "October",
    MONTH_NOVEMBER: "November",
    MONTH_DECEMBER: "December",


    MONTH_JAN: "Jan",
    MONTH_FEB: "Feb",
    MONTH_MAR: "Mar",
    MONTH_APR: "Apr",
    MONTH_M: "May",
    MONTH_JUN: "Jun",
    MONTH_JUL: "Jul",
    MONTH_AUG: "Aug",
    MONTH_SEP: "Sep",
    MONTH_OCT: "Oct",
    MONTH_NOV: "Nov",
    MONTH_DEC: "Dec",


    NEW_TEXT_1: "Are you sure you want to delete this note?",
    NEW_TEXT_2: "Are you sure you want to delete this task?",
    NEW_TEXT_3: "Any tasks assigned to this list will not be deleted but will be unassigned from the list. Are you sure delete this task list?",
    NEW_TEXT_4: "Are you sure you want to delete this medication?",
    NEW_TEXT_5: "Are you sure you want to delete all occurrences of this event?",
    NEW_TEXT_6: "This is a recurring event",
    NEW_TEXT_7: "Edit all future events",
    NEW_TEXT_8: "Edit this event only",
    NEW_TEXT_9: "Submit",
    NEW_TEXT_10: "If you have connected your Jointly circle to an external device then messages from that device will appear in this section.",
    NEW_TEXT_11: "View more information about Jointly’s device connections",
    NEW_TEXT_12: "Delete Account",
    NEW_TEXT_13: "Search Results",






    BASIC_INFO: "Basic info",
    BASIC_INFO_TEXT1: "Please enter information about the person being cared for. You can view additional fields and create your own custom fields on the 'Additional information' tab.",
    LIKESCALLED: "Likes to be called",
    PREFER_NAME: "preferred name",
    DETILS_CIRCLE: "Details of any particular difficulties",
    DETAILS_TOOLTIP:"Details of communication, mobility, dexterity or memory, sight or hearing difficulties, concentration problems, any challenging behaviour or other difficulties someone might meet in trying to help.",
    DETAILS_PLACEHOLDER: "e.g. Has problems with short term memory and can't understand speech without hearing aid",
    ASSISTANCE: "What assistance does the person you care for need?",
    ASSISTANCE_TOOLTIP: "It may help to start from first thing in the morning to last thing at night to include all of the specific assistance that the person needs. Also include if assistance is required during the night. Include any emotional/psychological issues and support needs.",
    POPUP_SAVE: 'Please save your basic information below before adding additional information',
    ADDITIONAL_INFO: "Additional info",
    ADDITIONAL_TEXT1: "You can use this page to keep additional information about the person you care for.",
    ADDITIONAL_TEXT2: "We have provided some examples of the type of information that may be useful for you to store in Jointly. You can move or delete any of these fields, or create your own to customise Jointly so that it's better suited to your caring situation. If you want to restrict access to a field then you can mark it 'Private' using the lock icon and choose which members can access private category information.",
    ADDITIONAL_TEXT3: "Please click here for more information on using Jointly for contingency planning.",
    ADDITIONAL_TEXT4: "Contingency planning",
    ADDITIONAL_TEXT5: "Having a contingency plan in case of emergencies when you may not be able to provide support is vital. If you keep these sections up to date you can share with the circle or export the information as a contingency plan to be shared with a professional or someone else providing support.",

    CUSTOM_FIELD_NAME: "Field name",
    CUSTOM_FIELD_TYPE: "Field type",
    SHORT_TEXT: "Short text",
    LONG_TEXT: "Long text",
    ADD_FIELD: "Add field",
    DATE: "Date",



    CUST_FIELD1: "Edit custom fields",
    CUST_FIELD2: "Add custom field",
    CUSTOM_FIELD: "Add custom fields",
    CUST_FIELD3: "(Optional) enter a description of what this field is used for",
    CUST_FIELD4: "Is this private?",
    CUST_FIELD5: "Are you sure you want to delete this field?",
    CUST_FIELD6: "Edit profile ",
    CUST_FIELD7: "More Info",
    CUST_FIELD8: "Less Info",
    CUST_FIELD9: "Contingency planning",
    CUST_FIELD10: "Invite someone to join your Jointly circle",
    CUST_FIELD11: "EMERGENCY CONTACT",
    CUST_FIELD12: "You",
    CUST_FIELD13: "More options",
    CUST_FIELD14: "Please click ‘Join’ to link your Jointly circle to this device. This will authorise the device to send information to your Jointly circle. As soon as you receive information from a device it will be stored in a ‘Devices’ section that will appear in the Jointly left hand menu. If you wish to unlink your circle from a device device then please select ‘Leave’",
    CUST_FIELD15: "Delete all",
    CUST_FIELD16: "Location",
    CUST_FIELD17: "Not specified",
    CUST_FIELD18: "Add new location",
    CUST_FIELD19: "Please include any information that helps locate and access medication",
    CUST_FIELD20: "Where is the medication kept?",
    CUST_FIELD21: "Additional notes (info on refills, help required, etc.)",
    CUST_FIELD22: "Additional notes",
    CUST_FIELD23: "Emergency contact (ICE)",
    CATS1: "No category",
    CATS2: "Categories",
    CATS4: "No list found",
    EXPORT1: "Full/Custom export",
    EXPORT2: "Download options",
    EXPORT3: "Select Circle",
    EXPORT4: "Please select a circle",
    EXPORT5: "Include private category data",
    EXPORT6: "Select format",
    EXPORT7: "More options",
    EXPORT8: "Include image/document attachments",
    EXPORT9: "Export all sections",
    EXPORT10: "Include completed tasks and past events",
    EXPORT11: "Only include data from specific categories",
    EXPORT12: "Select categories",
    EXPORT13: "This setting only applies to data in Notes, Events, Tasks and Contacts. All other data from requested sections will be included.",
    EXPORT14: "For events use date",
    EXPORT15: "Start date",
    EXPORT16: "End date",
    EXPORT17: "created date",
    EXPORT18: "Export",
    EXPORT19: "Only export data created within date range",
    EXPORT20: "Contingency plan",
    EXPORT21: "Use this to export a set of essential information from Jointly that you can share with someone if you are unable to care due to illness or any other reason. You can choose to include or exclude any information that you have marked as private within Jointly.",
    EXPORT22: "What information will this export contain?",
    EXPORT23: "All information stored in the circle profile of the person you are caring for",
    EXPORT24: "All contacts and members of your circle, including all of their contact information stored in Jointly",
    EXPORT25: "All medication, dosage and location information. ",
    EXPORT26: "(optional) Notes - you can choose to export all Notes or only those that are assigned to specific categories.",
    EXPORT27: "If you want to export any additional information then please use the 'Custom' export option, either in place of or in addition to your contingency plan export.",
    EXPORT28: "Select Circle",
    EXPORT29: "Include private category data",
    EXPORT30: "Include Notes",
    EXPORT31: "Select format",
    EXPORT32: "Include image/document attachments",
    EXPORT33: "Type your search term above.",
    EXPORT34: "All Note categories",
    EXPORTTOCSV: "Export to CSV",

    WELCOME1: "Welcome to Jointly!",
    WELCOME2: "Jointly is a mobile and online application created by carers for carers. It is designed to make caring a little easier, less stressful and more organised.",
    WELCOME3: "If you have any queries about Jointly or any suggestions about how we can  improve it to better suit your caring situation then please contact us at  jointlyappsupport@carersuk.org",
    WELCOME4: "What’s new",
    WELCOME5: "Jointly has been updated! New features include:",
    WELCOME6: "You can now export your data from Jointly in PDF, Word or CSV formats. You can choose to export all information or specific information from any circles, sections, date ranges or categories. You can also use this feature to download a pre-set contingency plan (essential information that would be useful to share in case of an illness or emergency).",
    WELCOME7: "Custom fields can now be created in your circle profile. You can choose to set any of these custom fields as ‘Private’ so that they can only be viewed by authorised members of your circle.",
    WELCOME8: "Categories can now be applied to tasks, calendar events and contacts (as well as notes).Categories can be used help to organise your information, filter and compare items in lists and mark any information in these sections as ‘Private’.",
    WELCOME9: "Additional fields have been added to store medication storage locations, additional information on how a medicine is taken and indicate who in your circle or list of contacts is an emergency contact (ICE).",
    WELCOME10: "Please see the User Guide for more information about these and other Jointly features.",

    TABLEFULLNAME: "Full Name",
    TABLEFIRSTNAME: "First Name",
    TABLELASTNAME: "Last Name",
    TABLEEMAIL: "Email",
    TABLEJOINEDDATE: "Joined Date",
    TABLEUSERTYPE: "User Type",
    TABLESTATUS: "Status",

    ANALYTICS_START_DATE: "Start date",
    ANALYTICS_END_DATE: "End date",
    ANALYTICS_PROMOCODE: "Promo code",
    ANALYTICS_TOTAL_USERS: "Total Users",
    ANALYTICS_ENGLISH_USERS: "English Users",
    ANALYTICS_WELSH_USERS: "Welsh Users",
    ANALYTICS_ANDROID_USERS: "Android Users",
    ANALYTICS_IOS_USERS: "iOS Users",

    COMMENTS_SORT_BY_LABEL: "Sort comments",
    COMMENTS_SORT_BY_NEWEST_FIRST: "Newest first",
    COMMENTS_SORT_BY_OLDEST_FIRST: "Oldest first",
    COMMENTS_DELETE: "Delete",
    COMMENTS_EDIT: "Edit",
}