import { api, catchHandler } from "../../helpers/axios";
import config from "../../../config";
import { objToUrlParams,generateRandomKey } from "../../helpers";

export function passwordResetLink(data) {
  return api()
    .post(config.routes.generateMagicLogin, data)
    .then(res => res.data)  
    .catch(catchHandler);
}

export function listUsers(data) {
  return api()
    .get(`${config.routes.users}?${objToUrlParams(data)}`)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function totalUsersLang(data) {
  return api()
    .get(`${config.routes.searchUsers}?${objToUrlParams(data)}`)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function getSettings(data) {
  return api()
    .get(config.routes.setting)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function login(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(config.routes.login, data)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function alexalink(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(config.routes.alexalink, data)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function signup(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(config.routes.signup, data)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function magicLogin(data) {
  return api()
    .post(config.routes.magicLogin, data)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function resendMagicLogin(data) {
  return api()
    .post(config.routes.resendMagicLogin, data)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function getProfile() {
  return api()
    .get(config.routes.user)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function me() {
  return api()
    .get(config.routes.me)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function getCirclePermissions(data) {
  return api()
    .get(`${config.routes.circlePermissions}?${objToUrlParams(data)}`)
    .then(res => res.data)  
    .catch(catchHandler);
}

export function updateProfile(data) {
  return api()
    .put(config.routes.updateProfile, data)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function forgotPassword(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(config.routes.forgotPassword, data)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function resetPassword(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(config.routes.resetPassword, data)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function changePassword(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(config.routes.changePassword, data)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function search(data) {
  return api()
    .get(`${config.routes.search}?${objToUrlParams(data)}`)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function getNotification(data) {
  return api()
    .get(`${config.routes.notification}?${objToUrlParams(data)}`)
    .then(res => res.data)  
    .catch(catchHandler);
}

export function readNotification(data) {
  return api()
    .put(config.routes.readNotification,data)
    .then(res => res.data)  
    .catch(catchHandler);
}

export function readAllNotification(data) {
  return api()
    .put(config.routes.readAllNotification,data)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function deleteAllNotification() {
  return api()
    .delete(config.routes.deleteAllNotification)
    .then(res => res.data)  
    .catch(catchHandler);
}

export function deleteNotification(data) {
  return api()
    .delete(config.routes.readNotification, {data})
    .then(res => res.data)  
    .catch(catchHandler);
}
export function getNotificationCount(data) {
  return api()
    .get(`${config.routes.notificationCount}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler)
}

export function acceptInvitation(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(config.routes.createMembers, data)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function createInvitationNote(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(config.routes.createInvitationNote, data)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function rejectInvitation(data) {
  return api()
    .delete(config.routes.deleteinvitee, {data:data})
    .then(res => res.data)  
    .catch(catchHandler);
}
export function getInvities(data) {
  return api()
    .get(`${config.routes.invities}?${objToUrlParams(data)}`)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function resendInvities(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(config.routes.inviteRemainder,data)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function deleteInvities(data) {
  return api()
    .delete(config.routes.deleteinvitee,{data:data})
    .then(res => res.data)  
    .catch(catchHandler);
}
export function deleteAllinvitee(data) {
  return api()
    .delete(config.routes.deleteAllinvitee,{data:data})
    .then(res => res.data)  
    .catch(catchHandler);
}
export function exportAll(payload) {
  payload.csrf = generateRandomKey();
  return api()
    .post(config.routes.exportAll, payload)
    .then(res => res.data)
    .catch(catchHandler);
}
export function deleteAccount(data) {
  return api()
    .delete(config.routes.user,{data:data})
    .then(res => res.data)  
    .catch(catchHandler);
}
export function getUserById(data) {
  return api()
    .get(`${config.routes.userById}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function checkPassword(data) {
  return api()
    .post(config.routes.checkUser, data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function circleByUserId(data) {
  return api()
    .get(`${config.routes.circleUsersId}?${objToUrlParams(data)}`)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function whatsNew(data) {
  return api()
    .post(config.routes.whatsNew, data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function logout(data) {
  return api()
    .post(config.routes.logout,data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function usersListAnalytics(data){
  return api()
    .get(`${config.routes.listUser}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function exportUserAnalytics(data) {
  return api()
    .post(`${config.routes.exportReport}`, data)
    .then(res => res.data)
    .catch(catchHandler)
}





