import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";
import { getCookie } from "../../helpers/utility";
import { generateURL, aclCheck, changeLanguage, generateLabels } from "../../helpers";
import config from "../../../config";
import { history } from "../../store";
import Loader from '../../components/loader';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from "moment";
import { Menu, Dropdown, Input, Tooltip, Avatar, Badge, Modal, Select } from 'antd';
import { getCircleCookie } from "../../helpers/utility";
import { getNote } from '../circles/service'
import notification from '../../components/notification';
import io from 'socket.io-client'
import { getCookieByValue } from '../../helpers/utility'
import localization from 'moment/locale/cy'
const socket = io(config.webSocketAPI)

const MenuItem = Menu.Item;
const { Option } = Select;

const mapDispatchToProps = ({ user, circles, dashboard }) => {
	return {
		...user,
		...circles,
		...dashboard
	};
};

const mapStateToProps = ({ user, circles, dashboard }) => {
	return {
		...user,
		...circles,
		...dashboard
	};
};

class TopBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchToggle: false,
			active: false,
			search: '',
			quickAccessMenuVisible: false,
			circleMenuVisible: false,
			notificationMenuVisible: false,
			siteSettingsVisible: false,
			limit: 10,
			hasMore: true
		};
		this.selectSearchTag.bind(this)
		this.searchBar = React.createRef()
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.acceptInvitation = this.acceptInvitation.bind(this);
		this.rejectInvitation = this.rejectInvitation.bind(this);
	}
	async logout() {
		await this.props.logout({
			logout_all: 0
		});
	}
	toggleSearch() {
		this.setState({ searchToggle: !this.state.searchToggle })
	}
	onFocus = e => {
		this.setState({ active: true })
	}
	onBlur = e => {
		this.setState({ active: false })
	}
	navClose() {
		this.setState({ quickAccessMenuVisible: false })
	}
	async circleNavClose(id) {
		await this.props.getCircle({ circle_slug: id });

		if (this.props.circle && this.props.circle.id) {
			this.props.listTodo({ circle_id: this.props.circle.id })
			this.props.getCirclePermissions({ circle_id: this.props.circle.id });
			this.props.listMessages({ circle_id: this.props.circle.id, limit: 10 });
			this.props.listMembers({ circle_id: this.props.circle.id, nopaging: 1 })
			this.props.getInvities({ circle_id: this.props.circle.id, status: 'pending', invitation_type: 'circle' })
			this.props.getCircleDetails({ id: this.props.circle.id })
			this.props.dashboardActivities({ limit: this.state.limit, circle_id: this.props.circle.id })
		}
		this.setState({ circleMenuVisible: false })
		history.push(`/circle/${id}/overview`);
	}

	sendMessage(e) {
		if (e.key === 'Enter' && e.target.value.trim().length) {
			// if(e.target.value.includes(':') && e.target.value.split(':')[1].trim().length === 0) return
			// this.setState({ active: false })
			// let searchString = e.target.value.split(':')
			// if(searchString.length === 1){ 
			// 	this.props.search({ search_by: searchString[0].trim(), circles: this.props.circlesId.toString() })
			// 	history.push(`/search?search_by=${searchString[0].trim()}`)
			// }
			// else {
			// 	this.props.search({ search_by: searchString[1].trim(), module: searchString[0].trim(), circles: this.props.circlesId.toString() })
			// 	history.push(`/search?search_by=${searchString[1].trim()}&module=${searchString[0].trim()}`)
			// }
			this.search(e.target.value)
		}
	}
	search(message) {
		// if (message.includes(':') && message.split(':')[1].trim().length === 0) return
		// else if (message === '') return
		this.setState({ active: false })
		// let searchString = message.split(':')
		// if (searchString.length === 1) {
			this.props.search({ search_by: message.trim(), circles: this.state.circleVal ? this.state.circleVal : this.props.circlesId && this.props.circlesId.toString(), module: this.state.moduleVal ? this.state.moduleVal : "" })
			history.push(`/search?search_by=${message.trim()}`)
		// }
		// else {
		// 	this.props.search({ search_by: searchString[1].trim(), module: searchString[0].trim(), circles: this.state.circleVal ? this.state.circleVal : this.props.circlesId.toString(), module: this.state.moduleVal ? this.state.moduleVal : "" })
		// 	history.push(`/search?search_by=${searchString[1].trim()}&module=${searchString[0].trim()}`)
		// }
	}

	async moduleChange(e) {
		this.setState({ moduleVal: e })
	}
	async circleChange(e) {
		this.setState({ circleVal: e })
	}
	
	async selectSearchTag(searchTag) {
		if (this.searchField.state.value === '')
			await this.setState({ search: searchTag !== "" ? searchTag + ': ' : "" })
		else if (this.searchField.state.value.includes(':')) {
			let x = this.state.search.split(':')
			x[0] = searchTag
			if (x[0] === '') this.setState({ search: x[1].trim() })
			else await this.setState({ search: `${x[0]}: ${x[1].trim()}` })
		}
		else {
			let x = this.searchField.state.value
			await this.setState({ search: searchTag !== '' ? `${searchTag}: ${x}` : x })
		}
		this.searchField.focus()
		this.setState({ active: false })
		this.search(this.state.search)
	}
	UNSAFE_componentWillMount() {
		this.props.getNotificationCount();
		document.addEventListener('mousedown', this.handleClickOutside);
		socket.on('notifications', (data) => {
			// console.log(data);
			if (data.count) {
				this.props.getNotificationCount();
			}
			socket.emit('notifi_count', { id: this.props.user && this.props.user.id ? this.props.user.id : 0 });
		});
		this.init()
		this.initLang()
	}
	async init() {
		if (getCircleCookie() && getCircleCookie().circle_slug) {
			await this.props.getCircle({ circle_slug: getCircleCookie().circle_slug });
			await this.props.getCirclePermissions({ circle_id: getCircleCookie().id });
		}
	}
	initLang() {
		let lang = getCookieByValue("lang")
		this.setState({ value: lang })
	}

	changeLang = async (e) => {
		await this.props.updateProfileLang({ email: this.props.user.email, language: e });
		await changeLanguage(e)
		// await this.forceUpdate()
		// if (this.props.landing) {
		// 	await this.props.updateForcely()
		// }
		window.location.reload()
	}
	async getItemInfo(i, item) {
		return (<MenuItem key={`menu-${i}`}>
			<Link to="" className={`notification-drop-menu ${item.is_read === '0' ? 'unread' : ''} `}>
				<p className="notification-text"><span className="text-bold">{item.cuk_activity_type.body}</span></p>
				<div className="date-block">{moment(item.created_at).locale(`${getCookieByValue("lang") === "wel" ? "cy" : "en"}`, localization).format(config.dateFormat)}</div>
				{item.is_read === '0' ? (<Tooltip title="Mark as read" placement="bottom">
					<button className="btn read-this-notification-btn" onClick={this.readNotification.bind(this, item.id)} ></button>
				</Tooltip>) : null}
			</Link>
		</MenuItem>)
	}

	iconValue(item) {
		let icon = "icon-circle"
		if (item.module === 'tasks') {
			icon = "icon-task"
		} else if (item.module === 'note') {
			icon = "icon-notes"
		} else if (item.module === 'events') {
			icon = "icon-calendar-minimal"
		} else if (item.module === 'medication') {
			icon = "icon-medications"
		} else if (item.module === 'comment' && item.comment_module === 'task') {
			icon = "icon-task"
		} else if (item.module === 'comment' && item.comment_module === 'medication') {
			icon = "icon-medications"
		} else if (item.module === 'comment' && item.comment_module === 'event') {
			icon = "icon-calendar-minimal"
		} else if (item.module === 'comment' && item.comment_module === 'note') {
			icon = "icon-notes"
		} else if (item.module === 'contacts') {
			icon = "icon-user-fill"
		} else if (item.module === 'messages') {
			icon = "icon-create"
		} else if (item.module === 'circle') {
			icon = "icon-circle"
		} else if (item.module === 'device') {
			icon = "icon-connect"
			// return (
			//   item.circle_media_image && item.circle_media_module ?
			//     <Avatar className="wall-user-avatar" size={33} src={generateURL(item.circle_media_module, item.circle_media_image, 'profile')} />
			//     : 
			//     <Avatar className="wall-user-avatar">{item.circle_name && item.circle_name.length ? item.circle_name.charAt(0).toUpperCase().trim() : 'J'}</Avatar>
			// )
		} else if (item.module === 'userUnavailability') {
			icon = "icon-no-date"
		}
		return (<span style={{ marginRight: "6px" }} className={`wall-user-avatar1 ${icon} icon-block`}></span>)
	}


	getRedirectionLink(data, moduleName) {
		if (!(this.props.circles && this.props.circles.rows)) return ''
		let circle = this.props.circles.rows.find((item) => item.id === data.circle_id)
		if (!circle) return ''
		let slug = circle.circle_slug
		if (moduleName === 'circle') {
			if (!aclCheck("CircleView")) return ''
			return `/circle/${slug}/settings`
		}
		else if (moduleName === 'tasks') {
			if (!aclCheck("TaskView")) return ''
			return `/circle/${slug}/task/${data.task_id ? data.task_id : ''}`
		}
		else if (moduleName === 'tasklist') {
			return ''
		}
		else if (moduleName === 'medication') {
			if (!aclCheck("MedicationView")) return ''
			return `/circle/${slug}/medication/${data.medication_id ? data.medication_id : ''}`
		}
		else if (moduleName === 'events') {
			if (!aclCheck("EventView")) return ''
			return `/circle/${slug}/${data.event_id ? 'event' : 'calendar'}/${data.event_id ? data.event_id : ''}`
		}
		else if (moduleName === 'messages') {
			if (!aclCheck("MessageView")) return ''
			return `/circle/${slug}`
		}
		else if (moduleName === 'note') {
			if (!aclCheck("NoteView")) return ''
			return `/circle/${slug}/note/${data.note_id ? data.note_id : ''}`
		}
		else if (moduleName === 'contacts') {
			if (!aclCheck("ContactView")) return ''
			return `/circle/${slug}/contact`
		}
		else if (moduleName === 'device') {
			// if (!aclCheck("MedicationView")) return ''
			return `/circle/${slug}/device/${data.device_id ? data.device_id : ''}`
		}
		else if (moduleName === 'comment') {
			if (data.comment_module && data.comment_module === 'task') {
				if (!aclCheck('TaskView')) return ''
				return `/circle/${slug}/task/${data.comment_task_id ? data.comment_task_id : ''}`
			}
			else if (data.comment_module && data.comment_module === 'event') {
				if (!aclCheck("EventView")) return ''
				return `/circle/${slug}/event/${data.comment_event_id ? data.comment_event_id : ''}`
			}
			else if (data.comment_module && data.comment_module === 'medication') {
				if (!aclCheck("MedicationView")) return ''
				return `/circle/${slug}/medication/${data.comment_medication_id ? data.comment_medication_id : ''}`
			}
			else return ''
		}
		else return ''
	}
	readNotification(id) {
		let data = { is_read: 1 }
		data.id = id
		this.props.readNotification(data)
	}
	deleteNotification(id) {
		let data = { id }
		this.props.deleteNotification(data)
	}
	handleClickOutside(event) {
		if (this.searchBar && this.searchBar.current && !this.searchBar.current.contains(event.target)) {
			this.setState({ active: false })
		}
	}
	async acceptInvitation(item) {
		item.reciepient_email = this.props.user.email;
		item.notification_id = item.id
		await this.props.acceptInvitation(item)
		this.getNotification()
		this.props.listCircles()
	}
	async rejectInvitation(item) {
		item.reciepient_email = this.props.user.email
		item.notification_id = item.id
		await this.props.rejectInvitation(item)
		this.getNotification()
		this.props.listCircles()
	}
	readAllNotification() {
		let data = { is_read: 1 }
		this.props.readAllNotification(data)
	}
	deleteAllNotification() {
		this.props.deleteAllNotification()
	}
	//header dropdown active states checking
	quickAccessMenuButtonClick(flag) {
		this.setState({ quickAccessMenuVisible: flag });
	}
	circleMenuButtonClick(flag) {
		this.setState({ circleMenuVisible: flag });
	}
	siteSettingsButtonClick(flag) {
		console.log("siteSettingsButtonClick-------------", flag)
		this.setState({ siteSettingsVisible: flag })
	}
	notificationMenuButtonClick(flag) {
		this.setState({ notificationMenuVisible: flag });
	}
	handleMenuClick(flag) {
		if (flag === true) {
			this.setState({ notificationMenuVisible: true });
		}
	}
	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}
	async getNotification() {
		await this.props.getNotification({ limit: this.state.limit });
		if (this.props.notifications && this.props.notifications.rows && this.props.notifications.rows.length < this.props.notifications.count) {
			this.setState({ hasMore: true })
		}
		else this.setState({ hasMore: false })
		this.setState({ notificationLoading: false })
	}

	fetchMoreData = () => {
		if (!this.state.hasMore || this.props.loading) return
		if (this.props.notifications.rows.length >= this.props.notifications.count) {
			this.setState({ hasMore: false });
			return;
		}
		this.setState((prevState, props) => ({
			limit: prevState.limit + 10
		}));
		this.props.getNotification({ limit: this.state.limit });
	};
	parseNotificationBody(body, item) {
		if(body) {
			if (body.includes('{{subject}}'))
				body = body.replace('{{subject}}', item.subject_name ? (item.subject_name === `${this.props.user.first_name} ${this.props.user.last_name}`) ? generateLabels("OTHERS_YOU") : item.subject_name : 'Unknown user')
			if (body.includes('{{task_id}}'))
				body = body.replace('{{task_id}}', item.task_name ? `<b>${item.task_name}</b>` : '<b>Unknown task</b>')
			if (body.includes('{{event_id}}'))
				body = body.replace('{{event_id}}', item.events_title ? `<b>${item.events_title}</b>` : 'Unknown event')
			if (body.includes('{{member}}'))
				body = body.replace('{{member}}', item.member_name ? `<b>${item.member_name}</b>` : item.recipient_name ? `<b>${item.recipient_name}</b>` : item.invitee_name ? `<b>${item.invitee_name}</b>` : 'Unknown')
			if (body.includes('{{invitee}}'))
				body = body.replace('{{invitee}}', item.invitee_name ? `<b>${item.invitee_name}</b>` : 'Unknown')
			if (body.includes('{{comment_id}}'))
				body = body.replace('{{comment_id}}', item.comments ? `<b>${item.comments}</b>` : '')
			if (body.includes('{{object}}'))
				body = body.replace('{{object}}', item.member_name ? `<b>${item.member_name}</b>` : 'Unknown')
			if (body.includes('{{circle_id}}'))
				body = body.replace('{{circle_id}}', item.circle_name ? `<b>${item.circle_name}</b>` : 'Unknown circle')
			if (body.includes('{{medication_id}}'))
				body = body.replace('{{medication_id}}', item.medication_name ? `<b>${item.medication_name}</b>` : 'Unknown medication')
			if (body.includes('{{start_date}}'))
				body = body.replace('{{start_date}}', item.start_date ? `<b>${moment(item.start_date, 'YYYY-MM-DD').format("DD/MM/YY")}</b>` : '')
			if (body.includes('{{end_date}}'))
				body = body.replace('{{end_date}}', item.end_date ? `<b>${moment(item.end_date, 'YYYY-MM-DD').format("DD/MM/YY")}</b>` : '')
			if (body.includes('{{service_id}}'))
				body = body.replace('{{service_id}}', item.service_name ? `<b>${item.service_name}</b>` : 'Unknown service')
			if (body.includes('{{object_id}}')) {
				if (item.comment_module === 'event')
					body = body.replace('{{object_id}}', item.comment_event_name ? `<b>${item.comment_event_name}</b>` : 'Unknown event')
				else if (item.comment_module === 'task')
					body = body.replace('{{object_id}}', item.comment_task_name ? `<b>${item.comment_task_name}</b>` : 'Unknown task')
				else if (item.comment_module === 'note')
					body = body.replace('{{object_id}}', item.comment_note_title ? `<b>${item.comment_note_title}</b>` : 'Unknown note')
				else if (item.comment_module === 'medication')
					body = body.replace('{{object_id}}', item.comment_medication_title ? `<b>${item.comment_medication_title}</b>` : 'Unknown medication')
				else body = body.replace('{{object_id}}', '')
			}
			if (body.includes('{{note_id}}'))
				body = body.replace('{{note_id}}', item.note_title ? `<b>${item.note_title}</b>` : 'Unknown note')
			if (body.includes('{{message_id}}'))
				body = body.replace('{{message_id}}', item.messages ? `<b>${item.messages}</b>` : 'Unknown message')
			if (body.includes('{{attachment}}'))
				body = body.replace('{{attachment}}', item.attatchment ? `<b>${item.attachment}</b>` : '')
			if (body.includes('{{contact}}'))
				body = body.replace('{{contact}}', item.contact_name ? `<b>${item.contact_name}</b>` : 'Unknown')
			if (body.includes('{{assignedfirstname}}'))
				body = body.replace('{{assignedfirstname}}', item.recipient_name ? `<b>${item.recipient_name}</b>` : 'Unknown user')
		}
		return body
	}

	async onWallItemClick(item) {
		try {
			await this.readNotification(item.id)
			let redirectionLink = this.getRedirectionLink(item, item.module)
			let slug = ''
			if (this.props.circles && this.props.circles.rows && this.props.circles.rows.length) {
				let circle = this.props.circles.rows.find((data) => data.id === item.circle_id)
				slug = circle.circle_slug
			}
			if (redirectionLink !== '') {
				await this.props.getCircle({ circle_slug: slug })
				this.props.getCirclePermissions({ circle_id: this.props.circle.id });
				this.props.listMessages({ circle_id: item.circle_id, limit: this.state.limit });
				this.props.listMembers({ circle_id: item.circle_id, nopaging: 1 })
				if (item.module === 'note') {
					try {
						await getNote({ id: item.note_id, circle_id: item.circle_id })
						history.push(redirectionLink)
					}
					catch (e) {
						notification('error', e.data && e.data.message ? e.data.message : 'Unknown error')
					}
				}
				if (item.module === 'events') {
					try {
						let evets = await this.props.getEvents({ id: item.event_id, circle_id: item.circle_id });
						if (evets && !evets.id)
							history.push(redirectionLink)
					}
					catch (e) {
						notification('error', e.data && e.data.message ? e.data.message : 'Unknown error')
					}
				}
				if (item.module === 'tasks') {
					try {
					    let tas = await this.props.getTask({ circle_id: item.circle_id, id: item.task_id });
						if (tas && !tas.id)
							history.push(redirectionLink)
					}
					catch (e) {
						notification('error', e.data && e.data.message ? e.data.message : 'Unknown error')
					}
				}
				else
					history.push(redirectionLink)
			}
		}
		catch (err) {
			notification("error", generateLabels("TOASTERS_NO_ACCESS"))
		}
	}



	render() {
		const user = this.props.user || getCookie();
		const searchTagList = config.searchTagList
		const circle = getCircleCookie() || this.props.circle
		const quickAccessMenu = (
			<div className="header-quick-access-dropdown">
				<header className="header-block">{generateLabels("QUICK_ADD")}</header>
				<div className="quick-access-item-wrap">
					<Link to={circle && circle.circle_slug ? `/circle/${circle.circle_slug}` : '/'} className="quick-access-item" onClick={this.navClose.bind(this)}>
						<span className="icon-create icon-block"></span>
						<span className="text-block">{generateLabels("MESSAGE_QUICKADD")}</span>
					</Link>
					<Link to={circle && circle.circle_slug ? `/circle/${circle.circle_slug}/create-note` : '/'} className="quick-access-item" onClick={this.navClose.bind(this)}>
						<span className="icon-notes icon-block"></span>
						<span className="text-block">{generateLabels("NOTE_QUICKADD")}</span>
					</Link>
					<Link to={circle && circle.circle_slug ? `/circle/${circle.circle_slug}/create-task` : '/'} className="quick-access-item" onClick={this.navClose.bind(this)}>
						<span className="icon-task icon-block"></span>
						<span className="text-block">{generateLabels("TASK_QUICKADD")}</span>
					</Link>
					<Link to={circle && circle.circle_slug ? `/circle/${circle.circle_slug}/create-event` : '/'} className="quick-access-item" onClick={this.navClose.bind(this)}>
						<span className="icon-calendar-minimal icon-block"></span>
						<span className="text-block">{generateLabels("EVENT_QUICKADD")}</span>
					</Link>
					<Link to={circle && circle.circle_slug ? `/circle/${circle.circle_slug}/medication/create` : '/'} className="quick-access-item" onClick={this.navClose.bind(this)}>
						<span className="icon-medications icon-size-xl icon-block"></span>
						<span className="text-block">{generateLabels("MEDICATION_QUICKADD")}</span>
					</Link>
					{/* <Link to={circle && circle.circle_slug ?  `/circle/${circle.circle_slug}/device` : '/' } className="quick-access-item" onClick={this.navClose.bind(this)}>
						<span className="icon-connect icon-block"></span>
						<span className="text-block">View Devices</span>
					</Link> */}
					<Link to={circle && circle.circle_slug ? `/circle/${circle.circle_slug}/contact/create` : '/'} className="quick-access-item">
						<span className="icon-user-fill icon-block"></span>
						<span className="text-block">{generateLabels("CONTACT_QUICKADD")}</span>
					</Link>
				</div>
				{/* <Link to={circle && circle.circle_slug ?  `/circle/${circle.circle_slug}/contact/create` : '/' } className="footer-block"> 
					<span className="icon-user-fill icon-block"></span> 
					<span className="text-block">Add New Contacts</span>
				</Link> */}
			</div>
		);

		const circleMenu = (
			<div className="header-circle-dropdown">
				<header className="header-block">{generateLabels("MY_CIRCLE")} <span className="highlight">{`(${this.props.circles && this.props.circles.rows ? this.props.circles.rows.length : null})`}</span></header>
				<Scrollbars autoHide={true} autoHeight autoHeightMax={350}>
					{this.props && this.props.circles && this.props.circles.rows && this.props.circles.rows.length ?
						(
							<Fragment>
								{this.props.circles.rows.map((item, i) => (
									<div style={{ cursor: "pointer" }} key={`menu-${item.id}`} className={`header-circle-item ${item.id === this.props.circle.id ? 'active' : ''} `} onClick={this.circleNavClose.bind(this, item.circle_slug)}>
										{item && item.cuk_media && item.cuk_media.image_name ?
											(
												<Avatar className="circle-avatar" src={generateURL(item.cuk_media.module, item.cuk_media.image_name, 'profile')} />
											) : (
												<Avatar className="circle-avatar">{item.full_name && item.full_name.length ? item.full_name.charAt(0).toUpperCase() : 'J'}</Avatar>
											)}
										<span className="circle-name">{item.full_name}</span>
									</div>
								))}
							</Fragment>
						) : null}
				</Scrollbars>
			</div>
		);

		const siteSettingsMenu = role => (
			<Menu className="header-site-settings-dropdown">
				{user && user.role_id === 1 ?
					<MenuItem style={{ cursor: 'pointer' }}>
						<Link to='/site-settings' className="site-settings-drop-menu">
							<span className="icon-settings icon-block"></span>
							<span className="text-block">{generateLabels("ADMIN_MENU_SETTINGS_ONE")}</span>
						</Link>
						<Link to='/services' className="site-settings-drop-menu">
							<span className="icon-services icon-block"></span>
							<span className="text-block">{generateLabels("ADMIN_MENU_SETTINGS_TWO")}</span>
						</Link>
						<Link to='/promo-code/list' className="site-settings-drop-menu">
							<span className="icon-promo icon-block"></span>
							<span className="text-block">{generateLabels("ADMIN_MENU_SETTINGS_THREE")}</span>
						</Link>
						<Link to='/password-link' className="site-settings-drop-menu">
							<span className="icon-recover-account icon-block"></span>
							<span className="text-block">{generateLabels("ADMIN_MENU_SETTINGS_FOUR")}</span>
						</Link>
						<Link to='/analytics' className="site-settings-drop-menu">
							<span className="icon-analytics icon-block"></span>
							<span className="text-block">{generateLabels("ADMIN_MENU_SETTINGS_FIVE")}</span>
						</Link>
						<Link to='/circle-devices' className="site-settings-drop-menu">
							<span className="icon-circles-connected icon-block"></span>
							<span className="text-block">{generateLabels("ADMIN_MENU_SETTINGS_SIX")}</span>
						</Link>
						<Link to='/find-circle-by-user' className="site-settings-drop-menu">
							<span className="icon-find-circle icon-block"></span>
							<span className="text-block">Find Circle by User</span>
						</Link>
					</MenuItem>
					: null}
				{user && user.role_id === 6 &&
					<MenuItem style={{ cursor: 'pointer' }}>
						<Link to='/partners' className="site-settings-drop-menu">
							<span className=".icon-admin icon-block"></span>
							<span className="text-block">{generateLabels("SERVICE_ADMIN_SETTINGS_MANAGE_USERS_OPTION")}</span>
						</Link>
					</MenuItem>}
			</Menu>
		)

		const myProfileMenu = (
			<Menu className="header-my-profile-dropdown">
				<MenuItem key="0">
					<Link to="/my-account" className="user-profile-drop-menu user-details-menu">
						{user && user.cuk_media && user.cuk_media.image_name ?
							(
								<Avatar className="dp-block" src={generateURL(user.cuk_media.module, user.cuk_media.image_name, 'profile')} />
							) : (
								<Avatar className="dp-block">{user.first_name && user.first_name.length ? user.first_name.charAt(0).toUpperCase() : 'J'}</Avatar>
							)}
						<div className="profile-right-block">
							<h4 className="user-name">{user.first_name ? user.first_name : 'Hi'}</h4>
							<span className="user-mail">{user.email}</span>
						</div>
					</Link>
				</MenuItem>
				<MenuItem key="1">
					<Link to="/my-account" className="user-profile-drop-menu">
						<span className="icon-user dropdown-menu-icon"></span>
						{generateLabels("SIDE_MENU_MY_ACCOUNT")}
					</Link>
				</MenuItem>
				<MenuItem key="3">
					<Link to="/notification-settings" className="user-profile-drop-menu">
						<span className="icon-settings dropdown-menu-icon"></span>
						{generateLabels("SIDE_MENU_NOTIFICATION_SETTINGS")}
					</Link>
				</MenuItem>
				{/* <div className="user-profile-drop-menu" id="headerLanguageSelect" style={{ paddingLeft: 20, color: "#ccc" }}>
					{generateLabels("MY_ACCOUNT_LANG")}
					<Select
						onChange={this.changeLang}
						defaultValue={value}
						className="lang-select-dd"
						suffixIcon={<span className="icon-caret-down"></span>}
						dropdownClassName="header-language-dropdown"
						getPopupContainer={() => document.getElementById('headerLanguageSelect')}
					>
						<Option value="en">English</Option>
						<Option value="wel">Welsh</Option>
					</Select>
				</div> */}

				<MenuItem key="5">
					<div onClick={this.logout.bind(this)} className="user-profile-drop-menu">
						<span className="icon-logout dropdown-menu-icon"></span>
						{generateLabels("SIDE_MENU_LOGOUT")}
					</div>
				</MenuItem>
			</Menu>
		);

		const notificationMenu = (acceptInvitation, rejectInvitation) => {
			let readAllNotification = this.readAllNotification.bind(this)
			let deleteAllNotification = this.deleteAllNotification.bind(this)
			return (
				<Menu onClick={() => this.handleMenuClick(true)}>
				<div className="header-notification-dropdown">
					<div key="0">
						<div className="notification-menu-header">
							<div className="total-notification">
								<b>{this.props.notifications && this.props.notifications.count}</b>
								<span> {generateLabels("NOTIFICATIONS_MESSAGE")}</span>
							</div>
							<div className="notification-right-options">
								{/* <Tooltip title="Notification settings"  placement="bottom">
								<button className="btn icon-settings notification-option-btn notification-settings-btn"></button>
							</Tooltip> */}
								{this.props.notifications && this.props.notifications.unreadcount && this.props.notifications.unreadcount !== 0 ? (
									<Tooltip title={generateLabels("OTHERS_READ_ALL")} placement="top">
										<button onClick={() => Modal.confirm({ title: generateLabels("OTHERS_UNREAD_ALL"), content: generateLabels("OTHERS_MARK_ALL"), okText: generateLabels("EVENT_DELETE_CONFIRM_YES"), cancelText: generateLabels("NOTE_CREATE_CANCEL_BUTTON"), className: 'simple-modal', onOk() { readAllNotification() } })} className="btn uppercase c-outline-btn">{generateLabels("NOTIFICATIONS_READ_ALL")}</button>
										{/* </Popconfirm> */}
									</Tooltip>
								) : null}
									{this.props.notifications && this.props.notifications.count && this.props.notifications.count !== 0 ? (
									<Tooltip title={generateLabels("CUST_FIELD15")} placement="top">
										<button onClick={() => Modal.confirm({ title: generateLabels("OTHERS_DELETE_ALL_TITLE"), content: generateLabels("OTHERS_DELETE_ALL"), okText: generateLabels("EVENT_DELETE_CONFIRM_YES"), cancelText: generateLabels("NOTE_CREATE_CANCEL_BUTTON"), className: 'simple-modal', onOk() { deleteAllNotification() } })} className="btn uppercase c-outline-btn">{generateLabels("CUST_FIELD15")}</button>
									</Tooltip>
								) : null}

							</div>
						</div>
					</div>
					<Scrollbars autoHide={true} onScrollFrame={(event) => { if (event && event.top >= 1) this.fetchMoreData() }} autoHeight autoHeightMax={350}>
						{this.props.notifications && this.props.notifications.rows && this.props.notifications.rows.length ? (
							<React.Fragment>{this.props.notifications.rows.map((item, i) => (
								<div key={`menu-${i}`} className={`notification-drop-menu ${item.is_read === '0' ? 'unread' : ''} `}>
									<div className="notification-drop-menu-content">

										<span onClick={() => this.onWallItemClick(item)} className="notification-text" to={this.getRedirectionLink(item, item.module)}>
											{this.iconValue(item)}
											<span
												className="text-bold"
												dangerouslySetInnerHTML={{ __html: this.parseNotificationBody(getCookieByValue("lang") === "en" ? item.body : item.body_welsh, item) }}
											></span></span>
										<div className="date-block">{moment(item.created_at).locale(`${getCookieByValue("lang") === "wel" ? "cy" : "en"}`, localization).format(`ddd ${config.dateFormat} ${config.timeFormat}`)}</div>
										{item.module === 'event' || item.module === 'circle' ? (
											<div className="item-option-wrap">
												<button className="btn c-outline-btn item-option-btn" onClick={() => { acceptInvitation(item) }} >
													<span className="icon-tick icon-block"></span>
													<span className="uppercase text-block">{generateLabels("NOTIFICATIONS_ACCEPT_BUTTON")}</span>
												</button>
												<button className="btn c-outline-btn item-option-btn" onClick={() => { rejectInvitation(item) }} >
													<span className="icon-close icon-block"></span>
													<span className="uppercase text-block">{generateLabels("NOTIFICATIONS_REJECT_BUTTON")}</span>
												</button>
											</div>
										) : null}
										{item.is_read === '0' ? (<Tooltip title="Mark as read" placement="bottom">
											<button className="btn read-this-notification-btn" onClick={this.readNotification.bind(this, item.id)} ></button>
										</Tooltip>) : null}
										<Tooltip title="Delete" placement="bottom">
											<button className="btn icon-delete icon-delete-red" onClick={this.deleteNotification.bind(this, item.id)} ></button>
										</Tooltip>
									</div>
								</div>
							))
							}
								{this.state.hasMore ?
									<div className="listing-loading">
										<Loader />
									</div>
									: null}
							</React.Fragment>
						)
							:
							this.props.notifications && this.props.notifications.rows && this.props.notifications.rows.length === 0 ? null : <div className="notification-loader"><Loader /></div>}
					</Scrollbars>
				</div>
				</Menu>
			)
		}
		return (
			<div className="header-has-sticky">
				<header id="headerLanguageSelect" className="main-header">
					<div className="container-fluid">
						{this.props.page !== "Search" && this.props.page !== 'landing' ?
							<button onClick={() => { if (this.props && this.props.menuToggle) this.props.menuToggle() }} className={`btn icon-menu menu-btn ${this.props && this.props.menuToggler ? 'active' : ""}`}></button>
							: null}
						<div className="logo-block">
							<Link to={this.props && this.props.circles && this.props.circles.rows &&  this.props.circles.rows.length === 1 ? `/circle/${this.props.circles.rows[0].circle_slug}/overview` : this.props.circles.rows && this.props.circles.rows.length === 0 ? `/circles` : '/'}>
								<img src={`${config.cloudUrl}logo-white.png`} alt="" />
							</Link>
						</div>
						{/* <Select onChange={this.changeLang} defaultValue={value} className="lang-select" suffixIcon={<span className="icon-caret-down"></span>} dropdownClassName="header-language-dropdown" getPopupContainer={() => document.getElementById('headerLanguageSelect')}>
							<Option value="en">English</Option>
							<Option value="wel">Welsh</Option>
						</Select> */}
						{!this.props.hideSearch ?
							<div ref={this.searchBar} className={'simple-form search-block ' + (this.state.searchToggle ? 'has-open' : '')}>

								<div className="wrap-search-box">
									<Input ref={(ref) => this.searchField = ref} className="search-field" name="search" value={this.state.search} onChange={event => this.setState({ search: event.target.value })} autoComplete="off" onKeyUp={this.sendMessage.bind(this)} onFocus={this.onFocus} placeholder={generateLabels("SEARCH_PLACEHOLDER")} />
									<button className="btn icon-search icon-block" onClick={() => this.search(this.state.search)}></button>
									{this.state.search !== '' ? <button onClick={() => this.setState({ search: '' })} className="btn icon-close clear-search-btn"></button> : null}
								</div> 
								<div id="searchCategoryWrap" className={`search-category-wrap ${this.state.active ? 'open' : ''}`}>
									<Select 
										onChange={this.moduleChange.bind(this)} 
										className="search-selectbox" 
										placeholder={generateLabels("ADMIN_HEADER_SEARCH_CATEGORY_PLACEHOLDER")} 
										suffixIcon={<span className="icon-caret-down"></span>} 
										dropdownClassName="header-search-category-dropdown"
										getPopupContainer={() => document.getElementById('searchCategoryWrap')}
									>
										{searchTagList.map((tag, index) => { 
											return (
												<Option value={tag.key} key={index}>
													<span className={`${tag.icon} icon-block`}></span>
													<span className="text-block">{tag.label}</span>
												</Option>
											)
										})}
									</Select>
									<Select 
										className="search-selectbox" 
										onChange={this.circleChange.bind(this)}  
										placeholder={generateLabels("ADMIN_HEADER_SEARCH_CIRCLE_PLACEHOLDER")} 
										suffixIcon={<span className="icon-caret-down"></span>} 
										dropdownClassName="header-search-circle-dropdown"
										getPopupContainer={() => document.getElementById('searchCategoryWrap')}
									>
										{this.props.circles && this.props.circles.rows && this.props.circles.rows.length ?
											this.props.circles.rows.map((item, index) => (
												<Option value={item.id} key={index}>
													{item && item.cuk_media && item.cuk_media.image_name ?
														<Avatar className="circle-avatar" src={generateURL(item.cuk_media.module, item.cuk_media.image_name, 'profile')} />
													:
														<Avatar className="circle-avatar">{item.full_name && item.full_name.length ? item.full_name.charAt(0).toUpperCase() : 'J'}</Avatar>
													}
													<div className="text-block">{item.full_name}</div>
												</Option>
											))
										: null}
									</Select>
									{/* <Select className="search-selectbox" placeholder={generateLabels("ADMIN_HEADER_SEARCH_CIRCLE_PLACEHOLDER")} suffixIcon={<span className="icon-caret-down"></span>}>
										{this.props && this.props.circles && this.props.circles.rows && this.props.circles.rows.length ?
											(
												<Fragment>
													{this.props.circles.rows.map((item, i) => (
														<Option value={item.full_name} key={i}>
															{item && item.cuk_media && item.cuk_media.image_name ?
																(
																	<Avatar className="circle-avatar" src={generateURL(item.cuk_media.module, item.cuk_media.image_name, 'profile')} />
																) : (
																	<Avatar className="circle-avatar">{item.full_name && item.full_name.length ? item.full_name.charAt(0).toUpperCase() : 'J'}</Avatar>
																)}
															<span className="circle-name">{item.full_name}</span>
														</Option>
													))}
												</Fragment>
											) : null}
									</Select> */}
								</div> 

								{/* <ul className={`search-dropdown ${this.state.active ? 'open' : ''}`} >
									<Scrollbars autoHide={true} autoHeight autoHeightMax={280}>
										{
											searchTagList.map((tag, index) => {
												return (
													<li key={index} onClick={() => this.selectSearchTag(tag.name)}>
														<span className={`${tag.icon} list-icon-block`}></span>
														{tag.label}
													</li>
												)
											}, this)
										}
									</Scrollbars>
								</ul> */}
							</div> 
						: null}
						<div className="header-right-menu">
							<button className={`btn icon-search mobile-search-btn ${this.state.searchToggle ? 'active' : ''}`} onClick={this.toggleSearch.bind(this)}></button>
							{/* <Link to="/" className="header-menu-item home-menu-item">
								<span className="icon-home icon-block"></span>
								<span className="text-block">{generateLabels("HOME_HEADER")}</span>
							</Link> */}

							{user && (user.role_id === 1 || user.role_id === 6) ?
								<Dropdown overlay={siteSettingsMenu} trigger={['click']} onVisibleChange={this.siteSettingsButtonClick.bind(this)} placement="bottomCenter" getPopupContainer={() => document.getElementById('siteSettingsMenu')}>
									<div id="siteSettingsMenu" className={`header-menu-item ${this.state.siteSettingsVisible ? 'active' : ''} `}>
										<span className="icon-user icon-block"></span>
										<span className="text-block">{generateLabels("ADMIN_MENU_SETTINGS")}</span>
									</div>
								</Dropdown>
								: null}

							{this.props.page !== "Dashboard" && ((this.props.circle && this.props.circle.id) || (getCircleCookie() && getCircleCookie().id)) ? (
								<Dropdown overlay={quickAccessMenu} trigger={['click']} onVisibleChange={this.quickAccessMenuButtonClick.bind(this)} placement="bottomCenter" getPopupContainer={() => document.getElementById('quickAccessMenu')}>
									<div id="quickAccessMenu" className={`header-menu-item quick-access-menu-item ${this.state.quickAccessMenuVisible ? 'active' : ''} `}>
										<span className="icon-plus-round icon-block"></span>
										<span className="text-block">{generateLabels("QUICK_ADD")}</span>
									</div>
								</Dropdown>
							) : null}

							{this.props && this.props.circles && this.props.circles.rows && this.props.circles.rows.length > 1 ?
								(<Dropdown overlay={circleMenu} trigger={['click']} onVisibleChange={this.circleMenuButtonClick.bind(this)} placement="bottomCenter" getPopupContainer={() => document.getElementById('circleMenu')}>
									<div id="circleMenu" className={`header-menu-item circle-menu-item ${this.state.circleMenuVisible ? 'active' : ''} `}>
										<span className="icon-circle icon-block"></span>
										<span className="text-block">{generateLabels("CIRCLES_HEADER")}</span>
									</div>
								</Dropdown>) : null}
							<Dropdown overlay={notificationMenu(this.acceptInvitation, this.rejectInvitation)} onClick={this.getNotification.bind(this)} trigger={['click']} onVisibleChange={this.notificationMenuButtonClick.bind(this)} placement="bottomCenter" getPopupContainer={() => document.getElementById('notificationMenu')}>
								<div id="notificationMenu" className={`header-menu-item notification-menu-item ${this.state.notificationMenuVisible ? 'active' : ''} `}>
									<div className="notification-icon-wrap">
										<span className="icon-bell icon-block"></span>
										<span className="text-block">{generateLabels("NOTIFICATIONS_HEADER")}</span>
										<Badge count={this.props.notifications && this.props.notifications.unreadcount ? (this.props.notifications.unreadcount > 9 ? '9+' : this.props.notifications.unreadcount) : null} className="badge-block" />
									</div>
								</div>
							</Dropdown>
							<Dropdown overlay={myProfileMenu} trigger={['click']} placement="bottomCenter" getPopupContainer={() => document.getElementById('myProfileMenu')}>
								<div id="myProfileMenu" className="my-profile-menu">
									{user && user.cuk_media && user.cuk_media.image_name ?
										(
											<Avatar className="dp-block" src={generateURL(user.cuk_media.module, user.cuk_media.image_name, 'profile')} icon="user" />
										) : (
											<Avatar className="dp-block">{user.first_name && user.first_name.length ? user.first_name.charAt(0).toUpperCase() : 'J'}</Avatar>
										)}
									<span className="drop-arrow-block icon-arrow-down"></span>
								</div>
							</Dropdown>
						</div>
					</div>
				</header>
			</div>
		);
	}
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(TopBar);

